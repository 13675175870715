import gql from 'graphql-tag';

const GET_PRODUCT_KIT = gql`
query getProductKit($id: ID, $type: String){
  general{
    products(id:$id, first:1){
      edges{
        node{
          id
          name
          toProduct(orderBy:"order", type: $type){
            totalCount
            edges{
              node{
                id
                fromProduct{
                  id
                  name
                  catalogueNumber
                }
                quantity
                type
              }
            }
          }
        }
      }
    }
  }
}
`;

export default GET_PRODUCT_KIT;