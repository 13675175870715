import EMPLOYEES_TABLE_QUERY from "../queries/EMPLOYEES_TABLE_QUERY";


const removeUserList = async (client, id, variables) => {

    const cacheData = client.cache.readQuery(
        {
            query: EMPLOYEES_TABLE_QUERY,
            variables
        }
    );

    if (cacheData) {
        const  users  = Object.assign(cacheData.management.users);
        const index = users.edges.findIndex(edge => {
            return id === edge.node.id
        });
        if (index !== -1){
            users.totalCount -= 1;
            users.edges.splice(index, 1)
        }
        cacheData.management.users = users;
        client.writeQuery({
            query: EMPLOYEES_TABLE_QUERY,
            variables,
            data: cacheData
        });

        if (users.totalCount > users.edges.length && users.edges.length < variables.pageSize) {
            client.query({
                query: EMPLOYEES_TABLE_QUERY,
                variables,
                data: cacheData,
                fetchPolicy: 'network-only'
            })
        }


    }
};

export default removeUserList