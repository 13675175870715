import React from 'react';
import UploadDocument from '../UploadDocument';
import {Query} from "react-apollo";
import GET_ATTACHMENT_TYPES from '../../../../../gql/queries/GET_ATTACHMENT_TYPES';
import {Card, Spin} from "antd";

const DocumentsGroups = ({id}) => {
    return (
        <Query query={GET_ATTACHMENT_TYPES}>
            {({data, loading}) => {
                if (loading) return <div style={{height: '500px'}}><Spin/></div>
                if (!data.general) return null;
                const groupName = data.general.attachmentTypeValues.map((type, index) => {
                    return (
                        <Card key={index} title={type[1]}>
                         <UploadDocument id={id} attachmentType={type[0]}/>
                        </Card>
                    )
                });

                return <>{groupName}</>
            }}
        </Query>
    )
};

export default DocumentsGroups;