import React, {useEffect, useState} from 'react';
import {Row, message, Spin} from 'antd';
import {Query} from "react-apollo";
import FileCardComponent from "../FileCardComponent/FileCardComponent";
import PRICE_IMPORT_HISTORY from "../../gql/queries/PRICE_IMPORT_QUERY";
import PRODUCT_IMPORT_HISTORY from "../../gql/queries/PRODUCT_IMPORT_QUERY";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {withRouter} from 'react-router-dom';

const PricesImportCards = ({t, location}) => {
    const [currentImportType, setCurrentImportType] = useState('');


    useEffect(() => {
        setCurrentImportType(null)
        const currentHistory = location.pathname.substring(1);
        setCurrentImportType(currentHistory)
    }, [location])

    const sendFileRequest = (priceImport) => {
        fetch(process.env.REACT_APP_URL_IMPORT + priceImport.file).then((response) => {
            if (!response.ok) throw response;
            return response.blob();
        }).then(blob => {
            const href = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.download = priceImport.file.split('/')[3];
            a.href = href;
            a.click();
            a.href = '';
            document.body.removeChild(a);
        }).catch(err => {
            return console.error(err)
        }).then(err_json => {
            if (err_json) message.error(err_json.message);
        })
    };

    const returnImportCard = (edge, index) => {
        const importNode = edge.node;
        const name = importNode.file.split('/')[3];
        const date = moment(importNode.createdAt);
        const formattedDate = date.format('DD.MM.YYYY');
        return (
            <FileCardComponent
                key={index}
                name={name}
                linkAction={() => sendFileRequest(importNode)}
                text={
                    t(
                        'fileCardComponentText',
                        {
                            formattedDate,
                            uploaderText: importNode.uploadedBy ?
                                t(
                                    'fileCardComponentTextBy',
                                    {
                                        name: `${importNode.uploadedBy.firstName} ${importNode.uploadedBy.lastName}`
                                    }
                                )
                                :
                                ''
                        }
                    )}
            />
        );

    }

    if (currentImportType === 'price-import') {
        return (
            <Query query={PRICE_IMPORT_HISTORY} fetchPolicy={'network-only'}>
                {({loading, data, error}) => {
                    if (loading) return <Row type='flex' align='middle'><Spin/></Row>

                    if (data && data.general.priceImports ) {
                        const importsEdges = (data.general.priceImports && data.general.priceImports.edges) || [];
                        return importsEdges.map((edge, index) => {
                            return returnImportCard(edge, index)
                        });
                    } else {
                        return <>Brak danych</>
                    }
                }}
            </Query>
        )
    } else {
        return (
            <Query query={PRODUCT_IMPORT_HISTORY} fetchPolicy={'network-only'}>
                {({loading, data, error}) => {
                    if (loading) return <Row type='flex' align='middle'><Spin/></Row>
                    if (data && data.general) {
                        const importsEdges = (data.general.productImports && data.general.productImports.edges) || [];
                        return importsEdges.map((edge, index) => {
                            return returnImportCard(edge, index)
                        });
                    } else {
                        return <>Brak danych</>
                    }
                }}
            </Query>
        )
    }

}

PricesImportCards.propTypes = {};

export default withRouter(withTranslation('priceImport')(PricesImportCards));