export default {
  "addProductHeader": "Wybierz markę i kategorię produktu aby przejść dalej",
  "brand" : "Marka:",
  "category" :  "Kategoria:",
  "chooseCategory": "Wybierz kategorię",
  "name" : "Identyfikator / Nazwa",
  "catalogueNumber": "Numer katalogowy",
  "ean": "Kod EAN",
  "model": "Model",
  "productGroup": "Grupa produktowa",
  "placeholder": "Wybierz...",
  "nextBtn" : "Dalej",
  "abortBtn" : "Anuluj",
  "validationErr-name": "Proszę wprowadzić nazwę produktu!",
  "validationErr-name-length": "Nazwa zbyt długa!",
  "validationErr-ean": "Proszę wprowadzić kod EAN!",
  "validationErr-unique-ean": "Proszę wprowadzić unikalny kod EAN!",
  "validationErr-catNum": "Proszę wprowadzić numer katalogowy!",
  "validationErr-unique-catNum": "Proszę wprowadzić unikalny numer katalogowy!",
    "validationErr-category" : "Proszę wybrać kategorię!",
  "unique": "Proszę wprowadzić unikalną wartość!",
  "general-err": "To pole nie może być puste!",
  "copy" : "Powiel produkt",
  "save": "Zapisz",
  "cancel": "Anuluj",
}