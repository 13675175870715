import styled from "styled-components";
import {Menu} from "antd";


const StyledMenu = styled(Menu)`
    overflow: hidden !important;
    position: fixed !important;
    left: 0 !important;
    top: ${({theme}) => theme.layout.headerHeight};
    background-color: ${({theme}) => theme.color.white};
    height: calc(100vh - ${({theme}) => theme.layout.headerHeight});
    z-index: 99;
`;

export default StyledMenu;