import React, {useEffect, useState} from 'react';
import DataExportComponent from '../../../components/DataExportComponent';

const DataExport = ({location}) => {
    const [action, setAction] = useState('');

    useEffect(() => {
        if(location.action && location.action === 'export') setAction('instant');
        else if (location.action && location.action === 'create') setAction('create');
    }, [location]);

    return (
        <DataExportComponent
            action={action}
            setAction={setAction}
        />
    )
};

DataExport.defaultProps = {};
DataExport.propTypes = {};

export default DataExport;