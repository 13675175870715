import gql from "graphql-tag";

const DELETE_DEFAULT_CATEGORY_IMAGE_MUTATION = gql`
  mutation deleteDefaultCategoryImageMutation(
    $input: DeleteDefaultCategoryImageMutationInput!
  ) {
    deleteDefaultCategoryImageMutation(input: $input) {
      ok
    }
  }
`;

export default DELETE_DEFAULT_CATEGORY_IMAGE_MUTATION;
