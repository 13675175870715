import gql from 'graphql-tag';

const IMPORT_PRODUCTS_MUTATION = gql`
    mutation importProductsMutation($input: ImportProductsMutationInput!) {
      importProductsMutation(input: $input) {
        ok
        errors {
          field
          messages
        }
      }
    }
`;

export default IMPORT_PRODUCTS_MUTATION;