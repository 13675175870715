import gql from 'graphql-tag';

const GET_PRODUCT_STATUSES = gql`
    query productStatuses{
      general{
        productStatuses
      }
    }
`;

export default GET_PRODUCT_STATUSES;