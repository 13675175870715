import React from 'react';
import {Input, Form} from "antd";
import {withTranslation} from "react-i18next";

const InputTypeText = ({getFieldDecorator, id, initialValue = '', textLength, t, formItemVariables}) => {
    const rules = [];
    if (textLength) {
        rules.push(
            {
                max: textLength, message: t('validationErr-length')
            }
        )
    }
    return (
        <Form.Item {...formItemVariables} >
            {
            getFieldDecorator(id, {
                initialValue,
                rules
            })(
                <Input type='text'/>
            )
        }
        </Form.Item>
    );

};

export default withTranslation('products')(InputTypeText);