import React, {useEffect, useState} from 'react';
import {
  Form, Input, Button, message
} from 'antd';
import {withApollo} from "react-apollo";
import {withTranslation} from "react-i18next";
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import GET_TOKEN from '../../gql/mutations/GET_TOKEN';
import {ErrorMsg, Icon, Link} from '../_styledComponents';
import GET_ME_INFO from "../../gql/queries/GET_ME_INFO";

const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[ field ]);
};

const LoginForm = ({form, client, redirect, t}) => {
  const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, validateFields, setFieldsValue} = form;
  const cookies = new Cookies();

  const [ generalError, setGeneralErrorMsg ] = useState('');

  useEffect(() => {
    validateFields();
  }, []);

  const handleError = () => {
    setGeneralErrorMsg(t('log-in-err'));
  };

  const resetError = () => {
    setGeneralErrorMsg('')
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, value) => {
      if (!err) {
        client.mutate({
          mutation: GET_TOKEN,
          variables: {
            email: value.email,
            password: value.password
          }
        }).then(res => {
          cookies.set('jwttoken', res.data.tokenAuth.token, {path: '/'});
          client.query({
            query: GET_ME_INFO,
            fetchPolicy: 'network-only',
          }).then(meInfo => {
            if (meInfo.data.me.user.role === 'SV' || meInfo.data.me.user.role === 'EM') {
              cookies.set('role', 'admin', {path: '/'});
              redirect(true);
            }
            else {
              message.error(t('no-permissions'));
              cookies.remove('jwttoken', {path: '/'});
            }
          });
        }).catch(err => handleError());
      }
    });

  };

  const handleChange = e => {
    setFieldsValue({'email': e.target.value});
    resetError();
  };

  const userNameError = isFieldTouched('email') && getFieldError('email');
  const passwordError = isFieldTouched('password') && getFieldError('password');

  return (
    <Form onSubmit={handleSubmit} style={{height: '150px'}}>
      <Form.Item
        validateStatus={userNameError ? 'error' : ''}
        help={userNameError || ''}
      >
        {getFieldDecorator('email', {
          trigger: 'onBlur',
          rules: [ {required: true, type: 'email', message: t('mail-err')} ],
        })(
          <Input prefix={<Icon type="user"/>}
                 placeholder={t('mail')}
                 onChange={e => handleChange(e)}
          />
        )}
      </Form.Item>
      <Form.Item
        validateStatus={passwordError ? 'error' : ''}
        help={passwordError || ''}
      >
        {getFieldDecorator('password', {
          rules: [ {required: true, message: t('pass-err')} ],
        })(
          <Input.Password
            onChange={resetError}
            prefix={<Icon type="lock"/>} placeholder={t('password')}/>
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError()) || generalError}
          style={{width: '100%'}}
        >
          {t('log-in')}
        </Button>
      </Form.Item>
      <Link to="/forgot-password">
        {t('forgot')}
      </Link>
      <ErrorMsg
        minHeight={25}>
        {generalError}
      </ErrorMsg>
    </Form>
  )
};

LoginForm.propTypes = {
  form: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const AntLoginForm = Form.create({name: 'horizontal_login'})(LoginForm);
const TranslatedComponent = withTranslation('auth')(AntLoginForm);

export default withApollo(TranslatedComponent);