import React from 'react';
import Layout from "../../Layout/Layout";
import {StyledTitle} from "../../../components/_styledComponents";
import {withApollo} from "react-apollo";
import AddPromotedProductsForm from "../../../components/AddPromotedProductsForm";
import {withTranslation} from "react-i18next";

const PromotedProducts = ({client, t}) => {
    return (
       <Layout>
        <StyledTitle>{t('addPromoted')}</StyledTitle>
        <AddPromotedProductsForm client={client} />
    </Layout>
    )
}

const PromotedTranslated = withTranslation('promotions')(PromotedProducts);

export default  withApollo(PromotedTranslated);