export default {
  "log-in": "Log in",
  "mail": "Email",
  "password": "Password",
  "pass-err": "Please input your password!",
  "mail-err": "Please input your email!",
  "log-in-err": "Please enter a valid credentials!",
  "forgot": "Forgot password",
  "smth": "Something get wrong...",
  'no-permissions': 'Insufficient permissions.',
}