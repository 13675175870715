import gql from 'graphql-tag'

const PARAMETER_MUTATION = gql`
    mutation productParametersMutation($input: ProductParametersMutationInput!) {
    productParametersMutation(input: $input) {
   	clientMutationId
      ok
      errors {
        messages
        field
      }
    }
  }
`;

export default PARAMETER_MUTATION;