export default {
    "categories": "categories",
    "image": "image",
    "categoryName": "category name",
    "order": "order",
    "forward": "go forward",
    "dragAndDrop": "drag and drop",
    "uploadImage": "upload image",
    "msg-success": "success",
    "msg-error": "error",
    "go-back": "go back",
    "default-image-download-error": 'something went wrong while fetching the default image',
    "something-went-wrong-while-fetching-categories": "something went wrong while fetching categories",
    "something-went-wrong-while-reordering-categories": "something went wrong while reordering categories",
    "default-image": "default image",
    "add-default-image": "upload default image",
    "is-required": "is required",
    "maximum-number-of-characters-is-48": "the maximum number of characters is 48"
}