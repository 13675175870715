import React from 'react';
import {Query} from "react-apollo";
import GET_PARAMETERS from "../../../gql/queries/GET_PARAMETERS";
import {Form, Card} from "antd";
import ParameterType from "../ParameterType/ParameterType";
import GET_PARAMETER_GROUPS from "../../../gql/queries/GET_PARAMETER_GROUPS";
import DescriptionFields from "../ParametersTypeComponents/DescriptionFields";

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 10},
    md: {span: 10},
    xxl: {span: 10},
  },
  wrapperCol: {
    xs: {span: 6},
    sm: {span: 12},
    md: {span: 10},
    xxl: {span: 10},
  },
};

const ParameterQuery = ({type, id, addDescriptions = false, ...form}) => {

  return (
    <Query query={GET_PARAMETERS} fetchPolicy={'network-only'} variables={{id, type, addDescriptions}}>
      {({data, loading}) => {
        if (!data.general) return null;
        if (loading) return 'Loading...';
        const {descriptionShort, descriptionLong} = data.general.products.edges[0].node;
        const paramsList = data.general.products.edges.map(e => e.node.category.parameters.edges.map((e, index) => {
          let initialValue = null;
          let productparameter;
          if (e.node.productparameterSet.edges.length > 0) {
            productparameter = e.node.productparameterSet.edges[ 0 ].node;
          } else {
            productparameter = {
              textValue: e.node.defaultTextValue,
              numberValue: e.node.defaultNumberValue,
              numberValueTo: e.node.defaultNumberValueTo,
              arrayValue: e.node.defaultArrayValue,
            }
          }
          switch (e.node.type) {
            case( 'T' ):
            case( 'C' ):
            case( 'L' ):
              initialValue = productparameter.textValue;
              break;
            case( 'N' ):
            case( 'B' ):
              initialValue = productparameter.numberValue;
              break;
            case('A'):
              initialValue = productparameter.arrayValue;
              break;
            case('R'):
              initialValue = {
                  from: productparameter.numberValue,
                  to: productparameter.numberValueTo
              };
              break;
            default:
              break;
          }


          if (type === 'G') {
            return [
              e.node.group,
              <ParameterType
                key={index}
                name={e.node.name}
                type={e.node.type}
                choices={e.node.choices}
                unit={e.node.unit}
                id={e.node.id}
                form={form}
                textLength={e.node.textLength}
                initialValue={initialValue}
                decimalPlaces={e.node.decimalPlaces}
              >
                {e.node.name}
              </ParameterType>
            ]
          } else {
            return <ParameterType
              key={index}
              name={e.node.name}
              type={e.node.type}
              choices={e.node.choices}
              unit={e.node.unit}
              id={e.node.id}
              form={form}
              textLength={e.node.textLength}
              initialValue={initialValue}
              decimalPlaces={e.node.decimalPlaces}
            >
              {e.node.name}
            </ParameterType>
          }
        }));
        if (type === 'G') {
          return <Query query={GET_PARAMETER_GROUPS}>
            {({data, loading, errors}) => {
              let groups = [];
              if (data) {
                groups = data.general.parameterGroupsValues;
              }
              return <Form  {...formItemLayout} className='parameters'>
                {groups.map((group, index) => {
                  const params = paramsList.length > 0 &&
                    paramsList[ 0 ].filter((param) => param[ 0 ] === group[ 0 ]);
                  return params.length > 0 && <Card key={index} title={group[ 1 ]}>
                    {
                      addDescriptions && !index && <DescriptionFields
                          form={form}
                          initialValueShort={descriptionShort}
                          initialValueLong={descriptionLong}
                      />
                    }
                    {params.map((param) => param[ 1 ])}
                  </Card>

                })}
              </Form>
            }
            }
          </Query>
        } else {
          return <Form  {...formItemLayout} className='parameters'>< Card>{paramsList}</Card></Form>
        }
      }}
    </Query>
  )
};

export default ParameterQuery;