import styled from 'styled-components';

const FixedButtonContainer = styled.div`
   position: fixed;
   right: 5rem;
	 margin: 0 8px;
	 display: inline-block;
	 
	 ${({theme}) => theme.mq.laptop`
    right: 2rem;
  `}
`;

export default FixedButtonContainer;