import React, {useState} from 'react';
import Logo from '../Logo';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom';
import Query from "react-apollo/Query";
import GET_ME_INFO from "../../gql/queries/GET_ME_INFO";
import {StyledHeader, StyledButton, StyledIcon} from "../_styledComponents";
import {withTranslation} from "react-i18next";
import ChangeLanguageButton from '../ChangeLanguageButton';


const HeaderComponent = ({t}) => {

    const cookies = new Cookies();
    const [redirect, setRedirect] = useState(cookies.get('jwttoken'));

    cookies.get('role');


    const logOut = () => {
        cookies.remove('jwttoken', {path: '/'});
        cookies.remove('role', {path: '/'});
        setRedirect(false);
    };

    if (!redirect) {
        return <Redirect to='/login'/>
    }

    return (
        <StyledHeader>
            <Logo/>
            <span>
                <Query query={GET_ME_INFO}>
                {({data, loading, error}) => {
                    let text = 'user';
                    if (!loading && !error){
                        text = data.me.user.displayName
                    }
                    return <>
                            <StyledIcon type="user"/>
                            {text}
                        </>
                }}
                </Query>
                <StyledButton
                    type="default"
                    onClick={logOut}>
                    {t('log-out')}
                </StyledButton>
                <ChangeLanguageButton/>
            </span>
        </StyledHeader>
    )
};

const Header = withTranslation('products')(HeaderComponent);
export default Header;