import React from 'react';
import Layout from "../../Layout/Layout";
import GlobalPricesForm from "../../../components/GlobalPricesForm";
import {withApollo} from "react-apollo";

const GlobalPricesValidity = ({client}) => {
    return (
       <Layout>
            <GlobalPricesForm client={client}/>
       </Layout>
    );
}

export default withApollo(GlobalPricesValidity) ;