import gql from 'graphql-tag';

const UPDATE_PRODUCT = gql`
mutation updateProductMutation($input: UpdateProductMutationInput!) {
  updateProductMutation(input: $input) {
    id
    brand
    category {
      id
      name
    }
    updatedBy
    catalogueNumber
    name
    model
    productGroup
    ean
    clientMutationId
    categoryFullPath
    errors {
      messages
      field
    }
  }
}
`;

export default UPDATE_PRODUCT;