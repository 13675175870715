import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
  DataExport,
  Employees,
  Error,
  Login,
  Products,
  AddProductForm,
  PasswordReset,
  ForgotPassword,
  PriceImport,
  Categories,
  Promotions,
  AddPromotionForm, PromotedProducts, GlobalPricesValidity,
} from '../views';
import PrivateRoute from './_components/PrivateRoute';
import ProductImports from "../views/ProductImports";

const Router = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute
          exact
          path='/data-export'
          component={DataExport}
          availableFor={['admin']}
      />
      <PrivateRoute
          exact
          path='/employees'
          component={Employees}
          availableFor={['admin']}
      />
       <PrivateRoute
          exact
          path='/promotions'
          component={Promotions}
          availableFor={['admin']}
      />
       <PrivateRoute
          exact
          path='/add-promotion'
          component={AddPromotionForm}
          availableFor={['admin']}
      />
        <PrivateRoute
          exact
          path={['/add-promotion/:query', '/add-promotion']}
          component={AddPromotionForm}
          availableFor={['admin']}
      />
      <PrivateRoute
          exact
          path={['/', '/products']}
          component={Products}
          availableFor={['admin']}
      />
        <PrivateRoute
          exact
          path={['/prices']}
          component={GlobalPricesValidity}
          availableFor={['admin']}
      />
      <PrivateRoute
          exact
          path={['/add-promoted-products']}
          component={PromotedProducts}
          availableFor={['admin']}
      />
       <PrivateRoute
          exact
          path={['/add-product/:query', '/add-product']}
          component={AddProductForm}
          availableFor={['admin']}
      />
       <PrivateRoute
          exact
          path={['/price-import/']}
          component={PriceImport}
          availableFor={['admin']}
      />
        <PrivateRoute
          exact
          path={['/product-import/']}
          component={ProductImports}
          availableFor={['admin']}
      />
       <PrivateRoute
          path='/categories/:categoryId?'
          component={Categories}
          availableFor={['admin']}
      />
      <Route path='/login' component={Login}/>
      <Route path='/forgot-password' component={ForgotPassword}/>
      <Route path='/password-reset/:token' component={PasswordReset}/>
      <Route
        path='*'
        component={Error}
      />
    </Switch>
  </BrowserRouter>
);

export default Router;
