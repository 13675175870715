import gql from 'graphql-tag';

const CREATE_PRICE_MUTATION = gql`
	mutation createPriceMutation($input: CreatePriceMutationInput!) {
    createPriceMutation(input: $input) {
      id
      errors {
        field 
        messages
      }
      product {
        id
        priceValue
      }
    }
  }
`;

export default CREATE_PRICE_MUTATION;