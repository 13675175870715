import React, {useEffect, useState} from 'react';
import {Button, Col, Icon, Input, Tooltip, Row} from "antd";
import {StyledFormItem} from "../_styledComponents";
import GET_PRODUCT_BY_CATALOGUE_NUMBER from "../../gql/queries/GET_PRODUCT_BY_CATALOGUE_NUMBER";

const PromotionKitElement = ({form, client, updateProductData, t, brand,  promoted, index, category, product, removeFromArray}) => {
        const [searchStatus, setSearchStatus] = useState('');
        const [inputValue, setInputValue] = useState('');
        const [catalogDecorator, setCatalogDecorator] = useState('');
        const [productDecorator, setProductDecorator] = useState('');

        useEffect(() => {
            promoted ? setCatalogDecorator(`productCatalogNumber${index}_${brand}`) : setCatalogDecorator(`productCatalogNumber`);
            promoted ? setProductDecorator(`productName${index}_${brand}`) : setProductDecorator(`productName`);
        }, [])


        const createVariables = (input) => {
            if (!promoted) {
                return input;
            } else {
              input['brand'] = brand;
              input['status_In'] = 'P'
            }

            return input;
        }

         const getProductForPromotion = () => {
            const catalogueNumber = catalogDecorator && form.getFieldValue(catalogDecorator);
            setSearchStatus('validating')

             const input = {
                catalogueNumber: catalogueNumber
             }
             createVariables(input)
            if (catalogueNumber) {
                  client.query({
                    query: GET_PRODUCT_BY_CATALOGUE_NUMBER,
                    variables: input,
                }).then(({data}) => {
                    const product = data.general.products.edges;
                    if (product.length) {
                         const name = product[0].node.name;
                        form.setFieldsValue({
                            [productDecorator]: name,
                            [`id_helper${index}_${brand}`]: product[0].node.id,
                        });
                        updateProductData(product[0].node.id);
                        setSearchStatus('success');
                        setInputValue('')
                    } else {
                        setSearchStatus('error')
                        setInputValue('')
                    }
                })
            }
        };

         const clearInput = () => {
              form.setFieldsValue({
                [catalogDecorator] : '',
                [productDecorator]: ''
              });
             if (!promoted) {
                 updateProductData('')
             } else {
                const id = form.getFieldValue(`id_helper${index}_${brand}`);
                 removeFromArray(id);
                 form.setFieldsValue({
                     [`id_helper${index}_${brand}`] : ''
                 })
             }
         };

         const preventSubmit = ({target}) => {
            setInputValue(target.value)
             if (promoted && inputValue === '') {
                 clearInput()
             }
         }

        return  (
            <Row type={'flex'} >
                    <Col span={11} offset={promoted ? 1 : 0}>
                        <StyledFormItem
                            hasFeedback help={searchStatus === 'error' ? t('noProduct') : ''} validateStatus={searchStatus}
                        >
                            {catalogDecorator && form.getFieldDecorator(catalogDecorator,{
                                initialValue: product && product.catalogueNumber
                            })(
                                <Input placeholder={t('catalogNumber')} onChange={preventSubmit} disabled={ category && category.id !== ''} />
                            )}
                              <Tooltip title={t('clickToFind')} visible={inputValue !== ''}>
                                    <Button disabled={category && category.id !== ''} style={{paddingRight: '35px'}} onClick={getProductForPromotion}><Icon type='search'/>{t('verify')}</Button>
                              </Tooltip>
                        </StyledFormItem>
                     </Col>
                    <Col span={8} offset={1}>
                        <StyledFormItem >
                               {productDecorator && form.getFieldDecorator(productDecorator, {
                                   initialValue: product && product.name

                               })(
                                      <Input disabled placeholder={t('productName')} />

                                    )
                                }
                        </StyledFormItem>

                    </Col>
                    <Col span={3}>
                        <StyledFormItem>
                               <Button onClick={clearInput}><Icon type='delete'/>{t('clear')}</Button>
                        </StyledFormItem>
                        {promoted ?  <StyledFormItem style={{display: 'none'}}>
                                  {form.getFieldDecorator(`id_helper${index}_${brand}`, {
                                      initialValue: product ? product.id : ''

                               })(
                                      <Input disabled />

                                    )
                                }
                        </StyledFormItem> : null}
                    </Col>
                </Row>
        )
};

export default PromotionKitElement;