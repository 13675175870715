export default {
    "categories": "kategorie",
    "image": "grafika",
    "categoryName": "nazwa kategorii",
    "order": 'kolejność',
    "forward": "przejdź dalej",
    "dragAndDrop": "przeciągnij i upuść",
    "uploadImage": "załącz grafikę",
    "msg-success": "operacja przebiegła pomyślnie",
    "msg-error": "operacja nie powiodła się",
    "go-back": "wróć",
    "default-image-download-error": 'coś poszło nie tak podczas pobierania domyślnego obrazka',
    "something-went-wrong-while-fetching-categories": "coś poszło nie tak podczas pobierania kategorii",
    "something-went-wrong-while-reordering-categories": "coś poszło nie tak podczas zmiany kolejności kategorii",
    "default-image": "domyślna grafika",
    "add-default-image": "załącz domyślną grafikę",
    "is-required": "jest wymagana(y)",
    "maximum-number-of-characters-is-48": "maksymalna liczba znaków  to 48"
}