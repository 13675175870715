export default {
    'importFile': 'Importuj plik',
    'importedFiles': 'Importowane pliki',
    'importHeadersText1': 'Każdy plik musi zawierać następujące nagłówki:',
    'importHeadersText2': 'Błędy wierszy można pominąć.',
    'additionalField': '(pole nieobowiązkowe)',
    'cancel': 'Anuluj',
    'importing': 'Importowanie',
    'retryImport': 'Ponów import',
    'skipBadRows': 'Pomiń błędne wiersze',
    'startImport': 'Rozpocznij import',
    'importText': 'Import:',
    'selectFile': ' Wybierz plik',
    'importErrors': 'Błędy importu',
    "priceImport": "Import cen",
    "productImport": "Import produktów",
    'downloadImportFile': "Pobierz szablon",
    'field': 'Pole',
    'error': 'Błąd',
    'rowsErrors': 'Błędy wierszy',
    'row': 'Wiersz',
    'column': 'Kolumna',
    'fileCardComponentText': 'Dodany {{formattedDate}} {{uploaderText}}',
    'fileCardComponentTextBy': 'przez {{name}}',
    'promotionInfo': 'Aby oznaczyć cenę jako promocyjną dodaj w pliku kolumnę z nagłówkiem "promocja". Następnie w każdym wierszu dla promowanej ceny wpisz "tak"',
}