import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ReactDragListView from "react-drag-listview";

import { Icon } from "antd";

import uppercaseFirstLetter from "../../../utils/uppercaseFirstLetter";
import { routes } from "../../../config/routes";

import {
  StyledTable,
  StyledButton,
} from "../../_styledComponents/CategoriesTable/Table";

import { EditableFormRow, EditableCellComponent } from "./Form";
import Breadcrumb from "./Breadcrumb";
import UploadBtn from "./UploadBtn";

const createTableDataModel = (categories) => {
  return categories.map(({ node }) => ({
    name: node.displayName || node.name,
    mainCategory: node.isMain,
    childrenField: node.children,
    id: node.id,
    image: node.image,
    pathToRoot: node.pathToRoot,
    parent: node.parent,
  }));
};

const Table = ({ categories, updateCategoriesOrder, updateTableData, t }) => {
  const [tableData, setTableData] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [currentPage, updateCurrentPage] = useState(1);
  const history = useHistory();

  const PAGE_SIZE = 10;

  useEffect(() => {
    setTableData(createTableDataModel(categories));
    setColumnsData(columnsDataModel);
  }, [categories]);

  const columnsDataModel = [
    {
      title: uppercaseFirstLetter(t("image")),
      dataIndex: "image",
      key: "image",
      className: "upload-btn",
      render: (_, record) => {
        const categoriesWithImage = categories.filter(
          (category) => category.node.image
        );
        const fileListModel = categoriesWithImage.map((category) => ({
          uid: category.node.id,
          name: category.node.image,
          status: "done",
        }));
        const filtered = fileListModel.filter(({ uid }) => uid === record.id);

        return <UploadBtn record={record} myFileList={filtered} />;
      },
    },
    {
      dataIndex: "button",
      key: "back-btn",
      className: "forward-btn",
    },
    {
      title: () => {
        const mainCategories = categories.some(
          (category) => category.node.isMain
        );
        return (
          <>
            {!mainCategories && (
              <StyledButton onClick={history.goBack}>
                <Icon type="left" />
                <span>{uppercaseFirstLetter(t("go-back"))}</span>
              </StyledButton>
            )}
          </>
        );
      },
      dataIndex: "button",
      key: "forward-btn",
      className: "forward-btn",
      render: (_, record) => {
        const lastCategory =
          record.childrenField && record.childrenField.totalCount === 0;

        return (
          <StyledButton
            disabled={lastCategory}
            onClick={() => history.push(`${routes.CATEGORIES}/${record.id}`)}
          >
            {uppercaseFirstLetter(t("forward"))}
            <Icon type="right" />
          </StyledButton>
        );
      },
    },
    {
      title: uppercaseFirstLetter(t("categoryName")),
      dataIndex: "name",
      key: "age",
      editable: true,
    },
    {
      title: uppercaseFirstLetter(t("order")),
      key: "drag-and-drop",
      width: "25%",
      className: "drag-and-drop",
      render: () => (
        <a className="drag-handle" href="/#">
          {uppercaseFirstLetter(t("dragAndDrop"))}
        </a>
      ),
    },
  ];

  const handleSave = (categoryId, isMainCategory) =>
    updateTableData(categoryId, isMainCategory);

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCellComponent,
    },
  };

  const columns = columnsData.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  const createIndexes = (fromIndex, toIndex) => {
    const result = { fromIndex, toIndex };
    if (currentPage !== 1) {
      result.fromIndex = fromIndex + PAGE_SIZE * (currentPage - 1);
      result.toIndex = toIndex + PAGE_SIZE * (currentPage - 1);
    }
    return result;
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const paginatedIndexes = createIndexes(fromIndex, toIndex);
      const data = [...categories];
      const item = data.splice(paginatedIndexes.fromIndex, 1)[0];
      data.splice(paginatedIndexes.toIndex, 0, item);
      updateCategoriesOrder(data);
    },
    handleSelector: "a",
    ignoreSelector: "tr.ant-table-expanded-row",
    nodeSelector: "tr.ant-table-row",
  };

  return (
    <>
      <Breadcrumb categories={categories} />
      <ReactDragListView {...dragProps}>
        <StyledTable
          columns={columns}
          dataSource={tableData}
          components={components}
          rowKey="name"
          pagination={{
            pageSize: PAGE_SIZE,
            total: tableData.length,
            current: currentPage,
            onChange: (page) => updateCurrentPage(page),
          }}
        />
      </ReactDragListView>
    </>
  );
};

Table.propTypes = {
  categories: PropTypes.array.isRequired,
  updateCategoriesOrder: PropTypes.func,
  updateTableData: PropTypes.func,
};

const TranslatedTable = withTranslation("categoriesAndSubcategories")(Table);

export default TranslatedTable;
