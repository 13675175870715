import React from 'react';
import styled from 'styled-components';

const LogoImg = styled.img`
  height: ${({theme}) => theme.layout.logoHeight};
  margin-right: 20px;
`;

const Logo = () => (
  <div>
    <LogoImg src="/logo/vaillant-logo.png" alt="logo" style={{height: '35px'}}/>
    <LogoImg src="/logo/saunier-logo.png" alt="logo"/>
  </div>
);

export default Logo;