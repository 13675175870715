import gql from 'graphql-tag';

const UPDATE_CATEGORY_NAME = gql`
mutation updateCategoryName($input: UpdateCategoryMutationInput!) {
    updateCategoryMutation(input: $input) {
      displayName
      id
    }
  }
`;

export default UPDATE_CATEGORY_NAME;
