export default {
    'dataExport':'Eksport danych',
    'exportFile':'Eksportuj plik',
    'yourTemplates':'Twoje szablony',
    'createNewPrivateTemplate':'Utwórz nowy szablon prywatny',
    'publicTemplates':'Szablony ogólnodostępne',
    'createNewPublicTemplate':'Utwórz nowy szablon publiczny',
    'creatingTemplateTitle':'Tworzenie szablonu do eksportu',
    'editTemplateTitle':'Edycja szablonu do eksportu',
    'duplicateTemplateTitle':'Duplikacja szablonu do eksportu',
    'publicTemplateFooterMessage':
        'Szablon ogólnodostępny może zawierać tylko parametry widoczne dla wszystkich użytkowników.',
    'exportTemplateSuccessMessage': 'Szablon eksportu zapisany.',
    'exportTemplateErrorMessage': 'Wystąpił błąd.',
    'deleteTemplateSuccessMessage': 'Szablon eksportu usunięty.',
    'exportDescription1': 'Każdy plik zawiera nazwę produktu i numer katalogowy.\n' +
        '                        Jeśli wybierzesz parametr, który nie jest dostępny dla\n' +
        '                        produktów danej kategorii, to pole to zostanie\n' +
        '                        oznaczone w pliku jako "Nie dotyczy".',
    'exportDescription2': 'Pamiętaj, że parametry produków pojawią się w pliku w wybranej\n' +
        '                        tutaj kolejności.',
    'cancel': 'Anuluj',
    'save': 'Zachowaj szablon',
    'saveAndExport': 'Zachowaj i eksportuj szablon',
    'duplicate': 'Duplikuj szablon',
    'duplicateAndExport': 'Duplikuj i eksportuj szablon',
    'templateName': 'Nazwa pliku',
    'templateCategories': 'Kategorie produktów',
    'templateParameters': 'Parametry do eksportu',
    'export': 'Eksportuj',
    'exportAndSave': 'Eksportuj i zapisz',
    'confirmExportPopupTitle': 'Czy chcesz eksportować plik z szablonu {{templateName}}?',
    'confirmRemovePopupTitle': 'Czy na pewno chcesz usunąć szablon {{templateName}}?',
    'confirmExportPopupContent': 'Otrzymasz email z wyeksportowanym plikiem. Może to zająć kilka minut.',
    'confirmExportMessageContent': 'Email otrzymasz za kilka minut.',
    'confirmPrivacyChange': 'Czy na pewno chcesz zmienić prywatność szablonu {{templateName}}?',
    'privacyChangeSuccess': 'Prywatność szablonu została zmieniona',
    'privacyChangeButton': 'Zmień prywatność',
    'changePrivacy': 'Zmień prywatność szablonu',
    'editTemplate': 'Edytuj szablon',
    'deleteTemplate': 'Usuń szablon',
    'delete': 'Usuń',
    'exportFileCardComponentText': 'Dodany {{formatted_date}} przez {{displayName}}.',
    'brand': 'Marka',
    'include-attachments': 'Załącz dokumenty',
    'attachment-types': 'Typ dokumentów',
}