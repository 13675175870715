import React from 'react';
import {Form, Select} from "antd";

const Option = Select.Option;

const ChoicesSelect = ({choices, getFieldDecorator, id, initialValue, formItemVariables}) => {



  const validateInput = value => (value === undefined);

  return (
     <Form.Item {...formItemVariables}>
      {getFieldDecorator(id, {
            validateTrigger: ["onChange", "onBlur"],
            initialValue
          })(
            <Select onBlur={validateInput}
                    choices={choices}
                    allowClear={true}
                    placeholder='Wybierz...'>
              {choices.map((choice, index) => <Option key={index} value={choice}>{choice}</Option>)}
            </Select>
         )}


    </Form.Item>
  )
};

export default ChoicesSelect;
