import gql from 'graphql-tag';

const PRICE_IMPORT_HISTORY = gql`query pricesImportHistory {
  general {
    priceImports (orderBy: "-created_at") {
      totalCount
      edges {
        node {
          id
          createdAt
          updatedAt
          file
          uploadedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
}
`;

export default PRICE_IMPORT_HISTORY;