import gql from 'graphql-tag';
const GET_PROMOTED_PRODUCTS = gql`query GetPromotedProducts($brand: String){
      general { 
        productPromoted(brand: $brand) {
            edges{
                node{
                  id
                  brand
                  catalogueNumber
                  name
                }
            }
          }
        }
      }
`;
export default GET_PROMOTED_PRODUCTS;