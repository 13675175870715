import PRODUCTS_TABLE_QUERY from "../queries/PRODUCTS_TABLE_QUERY";


const removeProductList = async (client, idsList, variables) => {

    const cacheData = client.cache.readQuery(
        {
            query: PRODUCTS_TABLE_QUERY,
            variables
        }
    );
    if (cacheData) {
        const products = cacheData.general.products;
        products.edges = products.edges.filter(edge => {
            if (idsList.indexOf(edge.node.id) !== -1){
                products.totalCount -= 1;
            }
            return idsList.indexOf(edge.node.id) === -1
        });
        cacheData.general.products = products;
        client.writeQuery({
            query: PRODUCTS_TABLE_QUERY,
            variables,
            data: cacheData
        });

        if (products.totalCount > products.edges.length && products.edges.length < variables.pageSize) {
            client.query({
                query: PRODUCTS_TABLE_QUERY,
                variables,
                data: cacheData,
                fetchPolicy: 'network-only'
            })
        }


    }
};

export default removeProductList