import React, {Component} from 'react';
import {Col, Form, Input, Modal} from "antd";
import {Mutation, withApollo} from "react-apollo";
import PRODUCTS_CATALOGUE_NUMBER_CHECK from "../../gql/queries/PRODUCT_CHECK_UNIQUE_PARAMETER_QUERY";
import {withTranslation} from "react-i18next";
import DUPLICATE_PRODUCT from "../../gql/mutations/DUPLICATE_PRODUCT";
import addDuplicatedProduct from "../../gql/updaters/addDuplicatedProduct";

const {Item} = Form;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

class DuplicateProductModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                catalogueNumber: {
                    validateStatus: null,
                    help: null,
                },
                ean: {
                    validateStatus: null,
                    help: null,
                },
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.nameInitial!== nextProps.nameInitial){
            this.props.form.setFieldsValue({name:nextProps.nameInitial})
        }
        if(this.props.catalogueNumberInitial!== nextProps.catalogueNumberInitial){
            this.props.form.setFieldsValue({catalogueNumber:nextProps.catalogueNumberInitial})
        }
    }

    toggleModal = () => {
        if (this.props.showModal) {
            this.setState({
                fields: {
                    catalogueNumber: {
                        validateStatus: null,
                        help: null,
                    },
                    ean: {
                        validateStatus: null,
                        help: null,
                    },
                }
            })
        }
        this.props.setShowModal(false);
    };

    handleBlur = async (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const {client} = this.props;
        const {fields} = this.state;
        const stateObj = {...this.state.fields[name]};
        if (!value) {
            if (name === 'catalogueNumber')
                stateObj.help = this.props.t('validationErr-catNum');
            else if (name === 'ean')
                stateObj.help = this.props.t('validationErr-ean');
            stateObj.validateStatus = 'error';
        } else {
            stateObj.validateStatus = 'validating';
            this.setState({fields: {...fields, [name]: stateObj}});
            const {data} = await client.query({
                query: PRODUCTS_CATALOGUE_NUMBER_CHECK(name),
                variables: {number: value},
                fetchPolicy: 'network-only'
            });
            const {totalCount: result} = data.general.products;
            if (result) {
                if (name === 'catalogueNumber')
                    stateObj.help = this.props.t('validationErr-unique-catNum');
                else if (name === 'ean')
                    stateObj.help = this.props.t('validationErr-unique-ean');
                stateObj.validateStatus = 'error';
            } else {
                stateObj.validateStatus = 'success';
                stateObj.help = '';
            }
        }
        this.setState({fields: {...fields, [name]: stateObj}});
    };

    submitData = (mutation) => {
        this.props.form.validateFields((err, fields) => {
            if (!err) {
                const input = fields;
                input.id = this.props.id;
                mutation({variables:{input}
                }).then(({data})=>{
                    if (data.duplicateProductMutation.product){
                        addDuplicatedProduct(
                            this.props.client,
                            this.props.variables,
                            data.duplicateProductMutation.product
                        ).then(()=> this.toggleModal())
                    }
                });
            } else {
                const fields = this.state.fields;
                if (err.catalogueNumber) {
                    fields.catalogueNumber.help = this.props.t('validationErr-catNum');
                    fields.catalogueNumber.validateStatus = 'error';
                }
                if (err.ean) {
                    fields.ean.help = this.props.t('validationErr-ean');
                    fields.ean.validateStatus = 'error';
                }
                this.setState({fields})
            }
        });
    };

    render() {
        const {
            getFieldDecorator
        } = this.props.form;
        const {ean, catalogueNumber} = this.state.fields;
        const {t} = this.props;
        return <Mutation mutation={DUPLICATE_PRODUCT}
                         client={this.props.client}>
            {(mutation, {data}) => (
                <Form onSubmit={this.handleSubmit} {...formItemLayout}>
                    <Modal
                        onOk={
                            (e) => {
                                e.preventDefault();
                                this.submitData(mutation)
                            }
                        }
                        width={750}
                        title={t('copy')}
                        visible={this.props.showModal}
                        okText={t('save')}
                        onCancel={this.toggleModal}
                        cancelText={t('cancel')}
                    >

                        <Col>
                            <Item
                                label={t('name')}
                                hasFeedback
                            >
                                {getFieldDecorator('name', {
                                    rules: [{required: true, message: t('validationErr-name')}],
                                })(
                                    <Input placeholder={t('name')} name={'name'}/>
                                )}
                            </Item>
                            <Item
                                label={t('catalogueNumber')}
                                hasFeedback={!!catalogueNumber.validateStatus}
                                validateStatus={catalogueNumber.validateStatus}
                                help={catalogueNumber.help}
                            >
                                {getFieldDecorator('catalogueNumber', {
                                    rules: [{required: true, message: t('validationErr-catNum')}],
                                })(
                                    <Input onBlur={this.handleBlur} name="catalogueNumber"
                                           placeholder={t('catalogueNumber')}/>
                                )}
                            </Item>
                            <Item
                                label={t('ean')}
                                hasFeedback={!!ean.validateStatus}
                                validateStatus={ean.validateStatus}
                                help={ean.help}
                            >
                                {getFieldDecorator('ean', {
                                    rules: [{required: true, message: t('validationErr-ean')}],
                                })(
                                    <Input onBlur={this.handleBlur} name="ean" placeholder={t('ean')}/>
                                )}
                            </Item>
                        </Col>
                    </Modal>
                </Form>
            )}
        </Mutation>
    }

}

const DuplicateProductModalForm = Form.create()(DuplicateProductModal);
const DuplicateProductModalApollo = withApollo(DuplicateProductModalForm);
const DuplicateProductModalTranslated = withTranslation('addProduct')(DuplicateProductModalApollo);

export default DuplicateProductModalTranslated