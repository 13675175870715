import React, {Component} from 'react';
import client from "../../../../config/apollo/client";
import {Table, Input, Popconfirm, Tooltip, Form, DatePicker, Button, Card, Col, Row, Checkbox} from 'antd';
import CREATE_PRICE_MUTATION from "../../../../gql/mutations/CREATE_PRICE_MUTATION";
import Cookies from 'universal-cookie';
import moment from 'moment';
import GET_PRICES from "../../../../gql/queries/GET_PRICES";
import UPDATE_PRICE_MUTATION from "../../../../gql/mutations/UPDATE_PRICE_MUTATION";
import DELETE_PRICE_MUTATION from "../../../../gql/mutations/DELETE_PRICE_MUTATION";
import {StyledAddPriceBtn} from '../../../_styledComponents';
import {withTranslation} from "react-i18next";
import DELETE_PRICES_MUTATION from "../../../../gql/mutations/DELETE_PRICES_MUTATION";
import GET_PRODUCT_INFO from "../../../../gql/queries/GET_PRODUCT_INFO";
import UPDATE_PRODUCT from "../../../../gql/mutations/UPDATE_PRODUCT";

const cookie = new Cookies();

const data = [];

const EditableContext = React.createContext();


class EditableCell extends Component {

    getInput = () => {
        if (this.props.dataIndex === 'dateFrom' || this.props.dataIndex === 'dateTo') {
            return <DatePicker/>;
        } else if (this.props.dataIndex === 'price') {
            return <Input addonAfter='zł'/>
        }
        return <Input/>;
    };
    getRules = (dataIndex, title) => {
        switch (dataIndex) {
            case 'price':
            case 'dateFrom':
                return [{required: true, message: `Proszę wprowadzić ${title}!`}];
            default:
                return null

        }
    };


    renderCell = ({getFieldDecorator}) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{margin: 0}}>
                        {getFieldDecorator(dataIndex, {
                            rules: this.getRules(dataIndex, title),
                            initialValue: record[dataIndex],
                        })(this.getInput())}
                    </Form.Item>
                ) : (
                    <div>
                        {
                            record ?
                                (dataIndex === 'dateFrom' || dataIndex === 'dateTo') ?
                                    record[dataIndex] === null ?
                                        null : record[dataIndex].format('YYYY-MM-DD')
                                    : record[dataIndex]
                                :
                                children
                        }
                    </div>
                )}
            </td>
        );
    };


    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class EditableTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data,
            editingKey: '',
            checked: false,
            isEditing: false,
            comment: '',
            productInfo: undefined,
        };
        this.currentProductId = this.props.id || cookie.get('currentProductId');
    }

    componentDidMount() {
        this.props.onRef(this);
        this.getProductInfo();
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    nextAction = async () => {
        const currentInfo = this.state.productInfo;
        client.mutate({
            mutation: UPDATE_PRODUCT,
            variables: {
                input: {
                    id: currentInfo.id,
                    name: currentInfo.name,
                    ean: currentInfo.ean,
                    catalogueNumber: currentInfo.catalogueNumber,
                    brand: currentInfo.brand,
                    category: currentInfo.category.id,
                    noPrice: this.state.checked,
                    noPriceComment: this.state.comment,
                }
            }
        });
        return true;
    };

    getProductInfo = () => {
        client.query({
            query: GET_PRODUCT_INFO,
            fetchPolicy: 'network-only',
            variables: {
                id: this.currentProductId,
            }
        }).then(res => {
            const newState = this.state;
            newState.productInfo = res.data.general.products.edges[0].node;
            if (newState.productInfo.noPrice) {
                newState.checked = true;
            }
            else this.getExistingPrices();
            if (newState.productInfo.noPriceComment) newState.comment = newState.productInfo.noPriceComment;
            this.setState({newState});
        });
    };

    getExistingPrices = () => {
        client.query({
            query: GET_PRICES,
            fetchPolicy: 'network-only',
            variables: {
                id: this.currentProductId
            }
        }).then(res => {
            const pricesList = res.data.general.products.edges[0].node.prices.edges.map(({node}) => {
                const dateFrom = (moment(node.dateFrom));
                const dateTo = node.dateTo === null ? null : (moment(node.dateTo));
                return {
                    key: node.id,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    price: node.value,
                    comment: node.description,
                    promotion: node.promotion,
                };
            });
            this.setState({
                data: pricesList,
            });
        })
    };

    handleDelete = key => {
        const data = [...this.state.data];
        client.mutate({
            mutation: DELETE_PRICE_MUTATION,
            variables: {
                input: {
                    id: `${key}`
                }
            }
        }).then(res => {
            this.setState({data: data.filter(item => item.key !== key)});
        })
    };

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({editingKey: '', isEditing: false});
    };

    updateState = (newData, index, row) => {
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            this.setState({data: newData, editingKey: '', isEditing: false})
        } else {
            newData.push(row);
            this.setState({data: newData, editingKey: '', isEditing: false})
        }
    };

    save(form, key) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...this.state.data];
            const index = newData.findIndex(item => key === item.key);
            const dateFrom = row['dateFrom'];
            const dateTo = row['dateTo'];
            const formatedValues = {
                ...row,
                'dateFrom': ((dateFrom).format('YYYY-MM-DD')),
                'dateTo': dateTo === null ? null : ((dateTo).format('YYYY-MM-DD')),
            };
            const input = {
                product: this.currentProductId,
                value: formatedValues.price,
                description: formatedValues.comment === '' ? '-' : formatedValues.comment,
                dateFrom: formatedValues.dateFrom,
                dateTo: formatedValues.dateTo ? formatedValues.dateTo : null,
                promotion: formatedValues.promotion,
            };
            if (!key) {
                client.mutate({
                    mutation: CREATE_PRICE_MUTATION,
                    variables: {
                        input
                    }
                }).then(({data}) => {
                    const {id} = data.createPriceMutation;
                    newData[index].key = id;
                    newData[index].dateFrom = dateFrom;
                    newData[index].dateTo = dateTo === null ? null : dateTo;
                    this.updateState(newData, index, row)
                }).catch(err => {
                    console.log(err)
                })
            } else {
                client.mutate({
                    mutation: UPDATE_PRICE_MUTATION,
                    variables: {
                        input: {
                            id: key,
                            ...input
                        }
                    }
                }).then(res => {
                    this.updateState(newData, index, row);
                    console.log(res)
                }).catch(err => {
                    console.log(err)
                })
            }
        });
    }

    edit(key) {
        this.setState({
            editingKey: key,
            isEditing: true,
        });
    }

    createNewRow = () => {
        const data = this.state.data;
        const newRow = {
            key: '',
            dateFrom: null,
            dateTo: null,
            price: '',
            comment: '',
            promotion: false,
        };
        this.setState({
            data: [...data, newRow],
            isEditing: true,
        })
    };

    noPriceWarning = <Row type='flex' justify='center'>
        <Row style={{textDecoration: 'underline'}}>{this.props.t('warning')}</Row>
        <Row style={{textAlign: 'center'}}>{this.props.t('prices-removal')}</Row>
    </Row>;

    onChangePrice = e => {
        this.setState({
            checked: e.target.checked,
            isEditing: e.target.checked,
            data: [],
            editingKey: '',
        });
        const data = this.state.data;
        const keys = data.map(p => {
            return p.key
        }).filter((key) => key);
        if (keys.length > 0) {
            client.mutate({
                mutation: DELETE_PRICES_MUTATION,
                variables: {
                    input: {
                        ids: keys
                    }
                }
            }).then(res => {
                this.setState({
                    data: [],
                });
            })
        }
    };

    onCommentChange = e => {
      const newState = this.state;
      newState.comment = e;
      this.setState(newState);
    };

    render() {
        const columns = [
            {
                title: this.props.t('start-date'),
                dataIndex: 'dateFrom',
                width: '12%',
                editable: true,
            },
            {
                title: this.props.t('end-date'),
                dataIndex: 'dateTo',
                width: '12%',
                editable: true,
            },
            {
                title: this.props.t('price'),
                dataIndex: 'price',
                width: '15%',
                editable: true,
            },
            {
                title: this.props.t('comment'),
                dataIndex: 'comment',
                width: '35%',
                editable: true,
            },
            {
                title: this.props.t('options'),
                dataIndex: 'operation',
                render: (text, record) => {
                    const {editingKey} = this.state;
                    const editable = this.isEditing(record);
                    const {t} = this.props;
                    return editable ? (
                        <div style={{height: '32px', display: 'flex', alignItems: 'center'}}>
                            <Popconfirm title={t('confirm-cancel')} onConfirm={() => this.cancel(record.key)}>
                                <Button>{t('cancel')}</Button>
                            </Popconfirm>
                            <EditableContext.Consumer>
                                {form => (
                                    <>
                                        <Button
                                            onClick={() => this.save(form, record.key)}
                                            style={{
                                                marginLeft: '10px',
                                            }}
                                        >{t('save')}</Button>
                                        <span style={{
                                            height: '32px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '10px'
                                        }}>
                      <Form.Item style={{marginBottom: 0}}>
                         {form.getFieldDecorator('promotion', {
                             initialValue: record['promotion']
                         })(
                             <Checkbox defaultChecked={record['promotion']}
                                       style={{marginRight: '5px'}}/>
                         )}
                          <span style={{fontSize: '15px'}}>{t('promo-price')}</span>
                      </Form.Item>
                    </span>
                                    </>
                                )}
                            </EditableContext.Consumer>
                        </div>
                    ) : (
                        <>
                            <Button disabled={editingKey !== ''}
                                    onClick={() => this.edit(record.key)}>{t('edit')}</Button>
                            <Button style={{marginLeft: '10px'}}
                                    onClick={() => this.handleDelete(record.key)}>{t('delete')}</Button>
                            {record['promotion'] === true ?
                                <span style={{fontSize: '15px', marginLeft: '10px'}}>{t('promo-price')}</span> : ''}
                        </>
                    );
                },
            },
        ];
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columnsList = columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        return (
            <EditableContext.Provider value={this.props.form}>
                <Card>
                    <Row type='flex'
                         justify='space-between'
                         align='middle'
                         style={{padding: '0 30px', marginBottom: '20px'}}
                    >
                        <Col span={4}>
                        <StyledAddPriceBtn type='primary'
                            onClick={this.createNewRow}
                            disabled={this.state.isEditing ? true : this.state.checked}
                        >
                            {this.props.t('add-price')}
                        </StyledAddPriceBtn>
                        </Col>
                        <Col>
                            <Row>
                                <span>{this.props.t('price-info')}</span>
                            </Row>
                            <Row type='flex' justify='center' style={{width: '100%'}}>
                                <span>{`${this.props.t('no-price-comment')}:`}</span>
                                <Input maxLength={30}
                                       size='small'
                                       style={{width: '50%', marginLeft: '5px'}}
                                       value={this.state.comment}
                                       onChange={e => this.onCommentChange(e.target.value)}
                                />
                            </Row>
                        </Col>
                        <Col span={4}>
                            {this.state.checked ? (
                                <Tooltip title={this.noPriceWarning}>
                                    <Checkbox onChange={this.onChangePrice}
                                              checked={this.state.checked}
                                    >
                                        <span>{this.props.t('no-price')}</span>
                                    </Checkbox>
                                </Tooltip>
                            ) : (
                                <Checkbox onChange={this.onChangePrice}
                                          checked={this.state.checked}
                                >
                                    <span>{this.props.t('no-price')}</span>
                                </Checkbox>
                            )}
                        </Col>
                        </Row>
                    <Table
                        components={components}
                        bordered
                        dataSource={this.state.data}
                        columns={columnsList}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: this.cancel,
                        }}
                    />
                </Card>
            </EditableContext.Provider>
        );
    }
}

const PricesForm = Form.create()(EditableTable);
const Prices = withTranslation('prices')(PricesForm);

export default Prices;