import gql from 'graphql-tag';

const REMOVE_USER_MUTATION = gql`
    mutation deleteUserMutation($id: ID) {
      deleteUserMutation(id: $id) {
        success
        errors {
          field
          messages
        }
      }
    }
`;

export default REMOVE_USER_MUTATION;