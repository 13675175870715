import gql from 'graphql-tag';

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserMutationInput!) {
    updateUserMutation(input: $input) {
      id
      displayName
      email
      phoneNumber
      role
      roleDisplay
      lastActivity
      companyName
      taxId
      isActive
      errors {
        field
        messages
      }
    }
  }
`;

export default UPDATE_USER;