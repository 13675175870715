import styled from "styled-components";
import {Icon} from "antd";

const StyledIcon = styled(Icon)`
  &.anticon {
    margin-right: 5px;
    color: ${({theme}) => theme.color.green};
  }
`;

export default StyledIcon;