import React, {Component} from 'react';
import DocumentsGroups from './DocumentsGroups';
import {withTranslation} from "react-i18next";
import {withApollo} from "react-apollo";
import GET_MAIN_PHOTO from "../../../../gql/queries/GET_MAIN_PHOTO";

class ProductDocumentsComponent extends Component {

	componentDidMount() {
      this.props.onRef(this)
    }

    componentWillUnmount() {
      this.props.onRef(null)
    }

    nextAction = async () => {
		this.props.client.query({
		  query: GET_MAIN_PHOTO,
		  fetchPolicy: 'network-only',
		  variables: {
			id: this.props.id,
		  }
		});
    	return true;
    };

	render() {
		const {t} = this.props;
		return (
			<>
				<p>{t('upload-info')}</p>
				<DocumentsGroups id={this.props.id}/>
			</>
		)
	}
}

const ProductDocuments = withApollo(withTranslation('documents')(ProductDocumentsComponent));
export default ProductDocuments;