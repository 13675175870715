export default {
  "basicData": "Dane podstawowe",
  "technicalData": "Dane techniczne",
  "logisticData": "Dane logistyczne",
  "files": 'Dokumenty',
  "accessories": "Wyposażenie",
  "save": "Zapisz",
  "price": 'Cena',
  "next": "Dalej",
  "prev": "Poprzedni",
  "done": "Zakończ",
  "process-msg": "Proces dodawania produktu zakończony!",
}