import React from 'react';
import LayoutCenter from "../../../containers/LayoutCenter";
import {Icon} from "antd";

const Error = () => (
  <LayoutCenter>
    <Icon type="meh" style={{fontSize: '55px'}}/><span style={{fontSize: '55px'}}>Error</span>
  </LayoutCenter>
);

Error.defaultProps = {};

Error.propTypes = {};

export default Error;