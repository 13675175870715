import gql from 'graphql-tag';

const GET_PRODUCT_ID = gql`
    mutation createProductMutation($input: CreateProductMutationInput!) {
    createProductMutation(input: $input) {
      id
      clientMutationId
      errors{
        messages
      }
    }
  }
`;

export default GET_PRODUCT_ID;