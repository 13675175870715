import gql from 'graphql-tag';

const CATEGORY_MAIN_PARAMETERS_QUERY = gql`query CategoryMainParameters($categoryId: ID!) {
    general {
        categories(id: $categoryId) {
            edges{
                node{
                    mainParameters{
                        edges{
                            node{
                                id
                                name
                                type
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

export default CATEGORY_MAIN_PARAMETERS_QUERY;