import gql from "graphql-tag";

const createQuery = parentId => {
  const filter = parentId ? `parent: "${parentId}"` : 'parent_Isnull: true';
  return gql`query chooseCategoryQuery($brand: String){
          general {
           categories(${filter}, brand: $brand ) {
            edges {
              node {
                name
                id
                children {
                  edges {
                    node {
                      name
                      id
                      isRoot
                      children {
                        edges {
                          node {
                            id
                          }
                        }
                      }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
};

export default createQuery;