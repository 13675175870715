import React, {useEffect, useState} from 'react';
import {Form, Card} from "antd";
import {withApollo} from "react-apollo";
import GET_PRODUCT_KIT from "../../../../../gql/queries/GET_PRODUCT_KIT";
import ProductKitElement from "../ProductKitElement/ProductKitElement";
import DELETE_PRODUCT_KIT_MUTATION from "../../../../../gql/mutations/PRODUCT_KIT/DELETE_PRODUCT_KIT_MUTATION";
import UPDATE_PRODUCT_KIT_MUTATION from "../../../../../gql/mutations/PRODUCT_KIT/UPDATE_PRODUCT_KIT_MUTATION";
import CREATE_PRODUCT_KIT_MUTATION from "../../../../../gql/mutations/PRODUCT_KIT/CREATE_PRODUCT_KIT_MUTATION";

const emptyKitObject = {
    id: null,
    fromProduct: {
        id: null,
        catalogueNumber: null,
        name: ''
    },
    quantity: null,
    index: null
};

const ProductKitGroup = ({client, type, name, id}) => {
    const [kits, setKits] = useState([emptyKitObject]);

    useEffect(() => {
        client.query({
            query: GET_PRODUCT_KIT,
            variables: {
                id, type
            },
            fetchPolicy: 'network-only'
        }).then(({data}) => mapKitData(data))
    }, []);

    const addKitAfter = (index) => {
        let temporaryKits = Array.from(kits);
        temporaryKits.splice(index + 1, 0, emptyKitObject);
        setKits(temporaryKits);
    };

    const moveKitUp = (index) => {
        let temporaryKits = Array.from(kits);
        let element = temporaryKits.splice(index, 1);
        temporaryKits.splice(index - 1, 0, ...element);
        temporaryKits.forEach((kit, index) => {
            if (kit.id)
                client.mutate({
                    mutation: UPDATE_PRODUCT_KIT_MUTATION,
                    variables: {
                        input: {
                            id: kit.id,
                            toProduct: id,
                            type,
                            fromProduct: kit.fromProduct.id,
                            quantity: kit.quantity,
                            order: index
                        }
                    }
                })
        });
        setKits(temporaryKits);
    };

    const updateElement = (index, data) => {
        let temporaryKits = Array.from(kits);
        const element = {...temporaryKits[index], ...data};
        if (element.id) {
            client.mutate({
                mutation: UPDATE_PRODUCT_KIT_MUTATION,
                variables: {
                    input: {
                        id: element.id,
                        toProduct: id,
                        type,
                        fromProduct: element.fromProduct.id,
                        quantity: element.quantity,
                        order: index
                    }
                }
            })
        } else if (element.quantity && element.fromProduct.id) {
            client.mutate({
                mutation: CREATE_PRODUCT_KIT_MUTATION,
                variables: {
                    input: {
                        toProduct: id,
                        type,
                        fromProduct: element.fromProduct.id,
                        quantity: element.quantity,
                        order: index
                    }
                }
            }).then(({data}) => {
                if (data.createProductKitMutation.id) {
                    element.id = data.createProductKitMutation.id
                }
            })
        }
        temporaryKits[index] = element;
        setKits(temporaryKits);
    };

    const removeElement = (index) => {
        let temporaryKits = Array.from(kits);
        if (temporaryKits[index].id) {
            client.mutate({
                mutation: DELETE_PRODUCT_KIT_MUTATION,
                variables: {
                    input: {
                        id: temporaryKits[index].id
                    }
                }
            }).then(({data}) => {
                if (data.deleteProductKitMutation.ok) {
                    temporaryKits.splice(index, 1);
                    if (temporaryKits.length === 0) {
                        temporaryKits.push(emptyKitObject);
                    }
                    setKits(temporaryKits);
                }
            })
        } else {
            temporaryKits.splice(index, 1);
            if (temporaryKits.length === 0) {
                temporaryKits.push(emptyKitObject);
            }
            setKits(temporaryKits);
        }
    };

    const mapKitData = (data) => {
        const kitsData = data.general.products.edges[0].node.toProduct;
        if (kitsData.totalCount !== 0) {
            setKits(kitsData.edges.map((kit, index) => {
                return kit.node
            }));
        }
    };

    return (
        <Card title={name}>
            {
                kits.map((kit, index) => <ProductKitElement
                    moveKitUp={moveKitUp}
                    updateElement={updateElement}
                    removeElement={removeElement}
                    addKitAfter={addKitAfter} data={kit} index={index} key={index}/>)
            }
        </Card>
    )

};

const ProductKitElementForm = Form.create()(ProductKitGroup);
const ProductKitElementApollo = withApollo(ProductKitElementForm);

export default ProductKitElementApollo;