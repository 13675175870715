import React, {Component} from 'react';
import client from "../../config/apollo/client";
import PropTypes from "prop-types";
import {Button, Spin, Divider, Row, Col, Select, Modal, Input, Checkbox, message} from 'antd';
import MAIN_CATEGORY_QUERY from "../../gql/queries/MAIN_CATEGORY_QUERY";
import BRAND_QUERY from "../../gql/queries/BRAND_QUERY";
import CATEGORY_PARAMETERS_QUERY from "../../gql/queries/CATEGORY_PARAMETERS_QUERY"
import EXPORT_TEMPLATE_MUTATION from "../../gql/mutations/EXPORT_TEMPLATES/EXPORT_TEMPLATE_MUTATION";
import EXPORT_TEMPLATES_QUERY from "../../gql/queries/EXPORT_TEMPLATES_QUERY";
import BRAND_ROOT_CATEGORY_QUERY from "../../gql/queries/BRAND_ROOT_CATEGORY_QUERY";
import GET_ATTACHMENT_TYPES from "../../gql/queries/GET_ATTACHMENT_TYPES";
import {withTranslation} from "react-i18next";
import Cookies from 'universal-cookie';

message.config({maxCount: 1});

const cookies = new Cookies();

class DataExportModal extends Component {
  constructor(props) {
    super(props);
    this.paramSelectRef = React.createRef();
    this.state = {
      loadingExport: false,
      loadingSaveAndExport: false,
      privateTemplate: true,
      parametersDisabled: true,
      categoriesDisabled: true,
      submitDisabled: true,
      attachmentsVisible: false,
      template: undefined,
      title: '',
      all_brands: [],
      all_categories: [],
      all_parameters: [],
      attachment_types: [],
      root_category: '',
      fields: {
        id: '',
        name: '',
        brand: '',
        categories: [],
        parameters: [],
        attachments: [],
      }
    };
  }

  async updateDefaultValues() {
    this.setState({
      parametersDisabled: false,
      categoriesDisabled: false,
      submitDisabled: false,
    });

    let fields = this.state.fields;
    fields.id = this.props.template.id;
    fields.name = this.props.template.name;
    fields.brand = this.props.template.brand;

    await this.getCategories();
    let selected = this.props.template.categories.edges.map(edge => edge.node.id);
    for (let [index, category] of this.state.all_categories.entries()) {
      if (selected.includes(category.id)) fields.categories.push(index);
    }

    if (this.props.template.attachmentTypes) {
      this.setAttachmentsField(this.props.template.attachmentTypes);
      this.setState({attachmentsVisible: true});
    }
    else this.setState({attachmentsVisible: false});

    this.setState({fields});
    await this.getParameters();
    fields.parameters = this.props.template.parameters.edges.map(edge => edge.node.id);

    this.setState({fields});
  }

  stateUpdate() {
    const {t} = this.props;
    this.setState({privateTemplate: this.props.privateTemplate});
    switch (this.props.action) {
      case 'instant':
        this.setState({
          title: t('export'),
          parametersDisabled: true,
          categoriesDisabled: true,
          submitDisabled: true,
          attachmentsVisible: false,
        });
        break;
      case 'create':
        this.setState({
          title: t('creatingTemplateTitle'),
          parametersDisabled: true,
          categoriesDisabled: true,
          submitDisabled: true,
          attachmentsVisible: false,
        });
        break;
      case 'edit':
        this.updateDefaultValues();
        this.setState({
          title: t('editTemplateTitle'),
          template: this.props.template,
        });
        break;
      case 'duplicate':
        this.updateDefaultValues();
        this.setState({
          title: t('duplicateTemplateTitle'),
          template: this.props.template,
        });
        break;
      default:
        break;
    }
  }

  componentWillMount() {
    client.query({
      query: BRAND_QUERY,
    }).then(res => { this.setState({
      all_brands: (res.data.general.brands)})
    });

    client.query({
      query: GET_ATTACHMENT_TYPES,
    }).then(res => {
      let choices = [];
      for (let item of res.data.general.attachmentTypeValues) {
        choices.push({key: item[0], display: item[1]})
      }
      this.setState({attachment_types: choices});
    })
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.showModal !== this.props.showModal && this.props.showModal) {
      this.stateUpdate();
      this.setState({id: '', name: ''});
    }
  }

  sendFileRequest = async (exportType, template=null) => {
    const {t} = this.props;
    let body = {
      export_type: exportType,
      brand: this.state.fields.brand,
      categories: this.state.fields.categories.map(id => this.state.all_categories[id].id),
      parameters: this.state.fields.parameters,
      name: this.state.fields.name,
    };
    if (this.state.attachmentsVisible) body.attachments = this.state.fields.attachments;
    if (template) {
      body.export_template_id = template.id;
      body.name = template.name;
    }
    await fetch(process.env.REACT_APP_URL_EXPORT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${cookies.get('jwttoken')}`,
      },
      body: JSON.stringify(body)
    }).then((response) => {
      if (!response.ok) throw response;
      return true;
    }).then(res => {
      message.success(t('confirmExportMessageContent'))
    }).catch(err => {
      this.hideModal();
      message.error('Error');
      return console.error(err);
    });
  };

  execExportTemplateMutation = (event, download) => {
    const {t} = this.props;
    let mutationInput = {
      name: this.state.fields.name,
      private: this.state.privateTemplate,
      brand: this.state.fields.brand,
      categories: this.state.fields.categories.map(id => this.state.all_categories[id].id),
      parameters: this.state.fields.parameters,
    };
    if (this.state.fields.id) mutationInput.id = this.state.fields.id;
    if (this.state.attachmentsVisible) mutationInput.attachments = this.state.fields.attachments;
    let template = undefined;
    client.mutate({
      mutation: EXPORT_TEMPLATE_MUTATION,
      variables: {
        input: mutationInput
      },
      update: (store, {data: {exportTemplateMutation}}) => {
        template = exportTemplateMutation.template;
        const data = store.readQuery({
          query: EXPORT_TEMPLATES_QUERY,
          variables: {
            private: this.state.privateTemplate,
          }
        });
        let index = data.me.exportTemplates.edges.map(edge => edge.node.id).indexOf(exportTemplateMutation.template.id);
        if (index >= 0) data.me.exportTemplates.edges[index].node = exportTemplateMutation.template;
        else {
          data.me.exportTemplates.totalCount += 1;
          data.me.exportTemplates.edges.push({
            node: exportTemplateMutation.template,
            __typename: 'ExportTemplateModelTypeEdge'
          });
        }
        store.writeQuery({
          query: EXPORT_TEMPLATES_QUERY,
          variables: {
            private: this.state.privateTemplate,
          },
          data
        });
      }
    }).then(async res => {
      if (res.data.exportTemplateMutation.ok) {
        message.success(t('exportTemplateSuccessMessage'));
        if (download === true) await this.sendFileRequest('normal', template);
        this.hideModal();
      }
      else message.error(t('exportTemplateErrorMessage'));
    });
  };

  saveAndDownload = (event) => {
    this.execExportTemplateMutation(event, true);
  };

  hideModal = () => {
    if (this.state.loadingExport) this.setState({loadingExport: false});
    if (this.state.loadingSaveAndExport) this.setState({loadingSaveAndExport: false});
    if (this.props.showModal) this.props.setShowModal(false);
    this.setState({
      all_parameters: [],
      fields: {
        brand: '',
        categories: [],
        parameters: [],
      }
    });
  };

  getParameters = async () => {
    let new_parameters = [];
    let categories = this.state.fields.categories.map(idx => this.state.all_categories[idx].id);
    let queryCategories = [this.state.root_category.id, ...categories];
    await client.query({
      query: CATEGORY_PARAMETERS_QUERY,
      variables: {categoryIds: queryCategories},
    }).then(res => {
      for (let edge of res.data.general.parameters.edges) {
        let parameter = edge.node;
        let selected_categories = this.state.fields.categories.map(id => this.state.all_categories[id]);
        selected_categories.push(this.state.root_category);
        let categoriesPresent = [];
        for (let category_edge of parameter.category.edges) {
          let category = category_edge.node;
          if (selected_categories.map(selected_category => selected_category.id).indexOf(category.id) >= 0) categoriesPresent.push(category);
        }
        new_parameters.push({
          name: parameter.name,
          id: parameter.id,
          categoryNames: categoriesPresent.map(category => category.name),
          categoryIds: categoriesPresent.map(category => category.id),
        });
      }
    });
    this.setState({all_parameters: new_parameters});
  };

  getRootCategory = async () => {
    await client.query({
      query: BRAND_ROOT_CATEGORY_QUERY,
      variables: {
        brand: this.state.fields.brand,
      }
    }).then(res => {
      this.setState({root_category: res.data.general.categories.edges[0].node});
    });
  };

  getCategories = async () => {
    await this.getRootCategory();
    await client.query({
      query: MAIN_CATEGORY_QUERY,
      variables: {
        brand: this.state.fields.brand
      }
    }).then(res => {this.setState({
      all_categories: (res.data.general.categories.edges).map(obj => obj.node)
    })})
  };

  handleBrandSelect = (value) => {
    let fields = this.state.fields;
    fields['brand'] = value;
    if (fields['categories']) fields['categories'] = [];
    if (fields['parameters']) fields['parameters'] = [];
    this.setState({fields});
    if (this.state.categoriesDisabled === true) this.setState({categoriesDisabled: false});
    this.getCategories();
    this.verifyFields();
  };

  handleCategorySelect = (value) => {
    let fields = this.state.fields;
    fields['categories'].push(value);
    this.setState({fields});
    if (this.state.parametersDisabled === true) this.setState({parametersDisabled: false});
    this.getParameters();
    this.verifyFields();
  };

  handleCategoryDeselect = (value) => {
    let fields = this.state.fields;
    let idx = fields['categories'].indexOf(value);
    fields['categories'].splice(idx, 1);
    if (fields['categories'].length === 0) {
      const paramSelect = this.paramSelectRef;
      paramSelect.current.blur();
      this.setState({parametersDisabled: true});
      fields['parameters'] = [];
    }
    else {
      this.getParameters().then(res => {
        fields['parameters'] = this.state.fields.parameters.filter(value => this.state.all_parameters.map(obj => obj.id).includes(value));
        this.setState({fields});
        this.verifyFields();
      });
    }
    this.setState({fields});
  };

  handleParametersChange = (value) => {
    let fields = this.state.fields;
    fields['parameters'] = value;
    this.setState({fields});
    this.verifyFields();
  };

  verifyFields = () => {
    if (this.state.fields.brand
      && this.state.fields.categories.length > 0
      && this.state.fields.parameters.length > 0
      && this.state.fields.name) {
      if (this.state.submitDisabled === true) this.setState({submitDisabled: false});
    }
    else if (!this.state.submitDisabled) this.setState({submitDisabled: true});
  };

  onFilenameChange = (value) => {
    let fields = this.state.fields;
    fields.name = value;
    this.setState({fields});
    this.verifyFields();
  };

  footer = () => {
    const {t} = this.props;
    if (this.props.action === 'instant') {
      return <Row key={0}>
        <Button onClick={this.hideModal}>
          {t('cancel')}
        </Button>
        {this.state.loadingExport ?
          <Button disabled style={{width: '7em'}}>
            <Spin size='small'/>
          </Button>
            :
          <Button disabled={this.state.submitDisabled}
                  onClick={async e => {
                    this.setState({loadingExport: true});
                    await this.sendFileRequest('instant');
                    this.hideModal();
                  }}
                  style={{width: '7em'}}
          >
            {t('export')}
          </Button>
        }
        {this.state.loadingSaveAndExport ?
          <Button disabled style={{width: '10em'}}>
            <Spin size='small'/>
          </Button>
            :
          <Button disabled={this.state.submitDisabled}
                  style={{width: '10em'}}
                  onClick={e => {
                    this.setState({loadingSaveAndExport: true});
                    this.saveAndDownload(e);
                  }}
          >
            {t('exportAndSave')}
          </Button>
        }
      </Row>
    }
    let saveBtn = '';
    let saveExportBtn = '';
    let size = 15;
    switch (this.props.action) {
      case 'edit':
        saveBtn = t('save');
        saveExportBtn = t('saveAndExport');
        break;
      case 'duplicate':
        saveBtn = t('duplicate');
        saveExportBtn = t('duplicateAndExport');
        size = 15;
        break;
      case 'create':
      default:
        saveBtn = t('save');
        saveExportBtn = t('saveAndExport');
        break
    }
    let footerMessage = '';
    if (this.state.privateTemplate === false) footerMessage = t('publicTemplateFooterMessage');
    return <Row key={0}>
      <Col md={12}>
        <Row type='flex' justify='center'>
          <span className='export-info'>{footerMessage}</span>
        </Row>
      </Col>
      <Col md={12}>
        <Button onClick={this.hideModal}>
          Anuluj
        </Button>
        <Button disabled={this.state.submitDisabled} onClick={e => this.execExportTemplateMutation(e)}>
          {saveBtn}
        </Button>
        {this.state.loadingSaveAndExport ?
          <Button disabled style={{width: `${size}em`}}>
            <Spin size='small'/>
          </Button>
            :
          <Button disabled={this.state.submitDisabled}
                  style={{width: `${size}em`}}
                  onClick={e => {
                    this.setState({loadingSaveAndExport: true});
                    this.saveAndDownload(e);
                  }}
          >
            {saveExportBtn}
          </Button>
        }
      </Col>
    </Row>;
  };

  setAttachmentsField = (defaultValues) => {
    let fields = this.state.fields;
    if (defaultValues) fields.attachments = defaultValues;
    else fields.attachments = this.state.attachment_types.map(item => item.key);
    this.setState({fields});
  };

  toggleAttachments = (value) => {
    if (value) this.setAttachmentsField();
    this.setState({attachmentsVisible: value});
  };

  handleAttachments = (e) => {
    let fields = this.state.fields;
    fields.attachments = e;
    this.setState({fields});
  };

  render() {
    const {t} = this.props;
    return <Modal visible={this.props.showModal}
                  width={900}
                  destroyOnClose={true}
                  maskClosable={false}
                  onCancel={this.hideModal}
                  title={[
                    <Row key={0} type='flex' justify='center'><h3><b>{this.state.title}</b></h3></Row>,
                    <Row key={1} type='flex' justify='center'>
                      <span className='export-info'>
                        {t('exportDescription1')}
                      </span>
                      <span className='export-info'>{t('exportDescription2')}</span>
                    </Row>,
                  ]}
                  footer={[
                    this.footer()
                  ]}
    >
      <Row>
        <Col md={5}>
          {t('templateName')}
        </Col>
        <Col md={8}>
          <Input name={'name'}
                 value={this.state.fields.name}
                 onChange={(event) => this.onFilenameChange(event.target.value)}/>
        </Col>
        <Col md={1}>
          <Divider type='vertical' style={{marginLeft: '20px'}}/>
        </Col>
        <Col md={2}>
          {t('brand')}
        </Col>
        <Col md={8}>
          <Select value={this.state.fields.brand}
                  name={'brands'}
                  style={{width: '100%'}}
                  onSelect={(value) => this.handleBrandSelect(value)}
          >
            {this.state.all_brands.map((obj, idx) => {
              return <Select.Option value={obj[0]} key={idx}>
                {obj[1]}
              </Select.Option>
            })}
          </Select>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col md={5}>
          {t('templateCategories')}
        </Col>
        <Col md={19}>
          <Select disabled={this.state.categoriesDisabled}
                  value={this.state.fields.categories}
                  name='categories'
                  optionFilterProp="children"
                  mode='multiple'
                  style={{width: '100%'}}
                  onSelect={(value) => this.handleCategorySelect(value)}
                  onDeselect={(value) => this.handleCategoryDeselect(value)}
          >
            {this.state.all_categories.map((obj, idx) => {
              return <Select.Option value={idx} key={idx}>
                {obj.name}
              </Select.Option>
            })}
          </Select>
        </Col>
      </Row>
      <Row style={{'marginTop': '15px'}}>
        <Col md={5}>
          {t('templateParameters')}
        </Col>
        <Col md={19}>
          <Select disabled={this.state.parametersDisabled}
                  value={this.state.fields.parameters}
                  name='parameters'
                  optionFilterProp="children"
                  mode='multiple'
                  style={{width: '100%'}}
                  onChange={(value) => this.handleParametersChange(value)}
                  ref={this.paramSelectRef}
          >
            {this.state.all_parameters.map((obj, idx) => {
              return <Select.Option value={obj.id} key={idx}>
                {obj.name} ({obj.categoryNames.join(', ')})
              </Select.Option>
            })}
          </Select>
        </Col>
      </Row>
      <Row style={{'margin': '15px 0'}}>
        <Checkbox checked={this.state.attachmentsVisible}
                  onChange={(e) => this.toggleAttachments(e.target.checked)}
        >
          {t('include-attachments')}
        </Checkbox>
      </Row>
      <Row style={{display: this.state.attachmentsVisible ? 'block' : 'none'}}>
        <Col md={5}>
            {t('attachment-types')}
        </Col>
        <Col md={19}>
          <Select mode='multiple'
                  style={{width: '100%'}}
                  value={this.state.fields.attachments}
                  onChange={this.handleAttachments}
          >
            {this.state.attachment_types.map((item, idx) => (
              <Select.Option value={item.key} key={idx}>
                  {item.display}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Modal>
  }
}

DataExportModal.defaultProps = {};
DataExportModal.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    owner: PropTypes.shape({
      displayName: PropTypes.string,
    })
  }),
  setTemplate: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  setAction: PropTypes.func.isRequired,
  privateTemplate: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default withTranslation('dataExport')(DataExportModal);
