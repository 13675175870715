import gql from 'graphql-tag';

const EMPLOYEE_ACTIVITIES_QUERY = gql`query EmployeeActivity
      (
        $userId: ID
        $pageSize: Int,
        $offset: Int,
        $sortColumn: [String],
      ) {
      management {
        users( first: 1, id: $userId ) {
          edges {
            node {
              id
              displayName
              activitylogSet ( first: $pageSize, offset: $offset, orderBy: $sortColumn ) {
                totalCount
                edges{
                  node{
                    id
                    createdAt
                  }
                }
              }
            }
          }
        }
      }
    }
    `;

export default EMPLOYEE_ACTIVITIES_QUERY;