import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { get, isNil } from "lodash";
import { message } from "antd";

import Table from "./Table";
import DefaultCategoryImage from "./DefaultCategoryImage";

import uppercaseFirstLetter from "../../utils/uppercaseFirstLetter";
import { routes } from "../../config/routes";

import client from "../../config/apollo/client";
import MAIN_CATEGORY_QUERY from "../../gql/queries/MAIN_CATEGORY_QUERY";
import SUBCATEGORY_QUERY from "../../gql/queries/SUBCATEGORY_QUERY";
import CHANGE_CATEGORIES_ORDER_MUTATION from "../../gql/mutations/CHANGE_CATEGORIES_ORDER_MUTATION";

const Categories = ({ t }) => {
  const [categories, setCategories] = useState([]);
  const { pathname } = useLocation();
  const { categoryId } = useParams();

  useEffect(() => {
    fetchMainCategories();
  }, []);

  useEffect(() => {
    if (!isNil(categoryId)) fetchSubCategories(categoryId);
    if (pathname === routes.CATEGORIES) fetchMainCategories();
  }, [categoryId]);

  const setErrorMsg = (errorType) =>
    uppercaseFirstLetter(
      t(`something-went-wrong-while-${errorType}-categories`)
    );

  const fetchMainCategories = () => {
    client
      .query({
        query: MAIN_CATEGORY_QUERY,
        fetchPolicy: "network-only",
        variables: {
          brand: "V",
        },
      })
      .then(({ data }) => {
        const categories = get(data, "general.categories.edges");
        setCategories(categories);
      })
      .catch(() => message.error(setErrorMsg("fetching")));
  };

  const fetchSubCategories = (id) => {
    client
      .query({
        query: SUBCATEGORY_QUERY,
        fetchPolicy: "network-only",
        variables: {
          brand: "V",
          parent: id,
        },
      })
      .then(({ data }) => {
        const subcategories = get(data, "general.categories.edges");
        setCategories(subcategories);
      })
      .catch(() => message.error(setErrorMsg("fetching")));
  };

  const updateTableDataOrder = (orderedCategories) => {
    const orderedCategoryId = orderedCategories.map((el) => el.node.id);
    client
      .mutate({
        mutation: CHANGE_CATEGORIES_ORDER_MUTATION,
        variables: {
          input: {
            ids: orderedCategoryId,
          },
        },
      })
      .then(() => setCategories(orderedCategories))
      .catch(() => message.error(setErrorMsg("reordering")));
  };

  const updateTableDataContent = (categoryId, isMainCategory) => {
    if (isNil(isMainCategory)) {
      fetchSubCategories(categoryId);
    } else {
      fetchMainCategories();
    }
  };

  return (
    <>
      <DefaultCategoryImage />
      <Table
        categories={categories}
        updateTableData={updateTableDataContent}
        updateCategoriesOrder={updateTableDataOrder}
      />
    </>
  );
};

const TranslatedCategories = withTranslation("categoriesAndSubcategories")(
  Categories
);

export default TranslatedCategories;
