import gql from 'graphql-tag';

const GET_PARAMETERS = gql`
    query parametersForProduct($id: ID, $type: String, $addDescriptions: Boolean!) {
      general {
        parameterGroupsValues
        products(id: $id, first: 1) {
          edges {
            node {
              id
              descriptionShort @include(if: $addDescriptions)
              descriptionLong @include(if: $addDescriptions)
              category {
                id
                name
                parameters(parameterType: $type, orderBy: "group", automaticGeneration: false) {
                  totalCount
                  edges {
                    node {
                      name
                      group
                      groupDisplay
                      type
                      choices
                      brand
                      unit
                      textLength
                      internal
                      id
                      decimalPlaces
                      defaultTextValue
                      defaultNumberValue
                      defaultNumberValueTo
                      defaultArrayValue
                      productparameterSet(product: $id, first: 1) {
                        edges {
                          node {
                            textValue
                            numberValue
                            numberValueTo
                            arrayValue
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`;

export default GET_PARAMETERS;
