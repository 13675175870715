import gql from 'graphql-tag';

const UPLOAD_FILE_MUTATION = gql`
		mutation createAttachmentMutation($input: CreateAttachmentMutationInput!) {
      createAttachmentMutation(input: $input) {
        id
        file
        errors {
          field
          messages
        }
      }
    } 
`;

export default UPLOAD_FILE_MUTATION;