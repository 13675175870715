import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {setContext} from 'apollo-link-context';
import {createUploadLink} from 'apollo-upload-client';
import Cookie from 'universal-cookie';

import {newProduct} from './initState';

const cookies = new Cookie();

const authLink = setContext((_, {headers}) => {
  const token = cookies.get('jwttoken');
  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : token
    }
  }
});
const cache = new InMemoryCache({  dataIdFromObject: object => {
        return object.id || object._id
  }
    });

cache.originalReadQuery = cache.readQuery;
cache.readQuery = (...args) => {
    try {
        return cache.originalReadQuery(...args);
    } catch (err) {
        return undefined;
    }
};


const client = new ApolloClient({
  link: authLink.concat(createUploadLink({
    uri: process.env.REACT_APP_URL_GRAPHQL,
  })),
  cache
});

client.writeData({
  data: {
    newProduct,
  }
});

export default client;