import gql from 'graphql-tag';

const GET_PRODUCT_BY_CATALOGUE_NUMBER = gql`
    query getProductByCatalogueNumber($catalogueNumber: String, $brand: String, $status_In: String ){
      general{
        products(catalogueNumber:$catalogueNumber, brand: $brand, status_In: $status_In ){
          edges{
            node{
              id
              name
            }
          }
        }
      }
    }
  `;

export default GET_PRODUCT_BY_CATALOGUE_NUMBER;