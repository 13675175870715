import React, {Component} from 'react';
import {Input, Row, Col, Icon, Select} from 'antd';
import EmployeesTable from "../EmployeesTable";
import {withTranslation} from 'react-i18next';
import client from "../../config/apollo/client";
import EmployeeActivitiesModal from "../EmployeeModal/EmployeeActivitiesModal";
import USER_CONSTS_QUERY from '../../gql/queries/USER_CONSTS_QUERY';


class EmployeesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            search: '',
            showEmployeeActivitiesModal: false,
            employeeActivitiesModalUserId: null,
            isActiveFilter: null,
            roleFilter: '',
            allRoles: []
        }
    }

    async getRoles() {
        const res = await client.query({
            query: USER_CONSTS_QUERY
        });
        this.setState({allRoles: res.data.management.userRoles});
    }

    componentWillMount() {
        this.getRoles();
    }

    renderSearchInput = () => {
        const {t} = this.props;

        return <Input
            placeholder={t("searchEmployees")}
            onChange={
                event => {
                    const value = event.target.value;
                    this.setState({inputValue: value}, () => {
                        this.searchTimeout = setTimeout(
                            () => this.setState({search: value})
                            , 500
                        )
                    })
                }
            }
            addonAfter={<Icon type="search" />}
            style={{width: 200}}
        />;
    };

    renderFilters = () => {
        const {t} = this.props;
        return <Row type='flex' align='middle' style={{'marginBottom': '30px'}}>
            <Icon type="control" style={{'fontSize': '30px'}}/>
            <Select placeholder= {t('position')}
                    allowClear={true}
                    clearIcon={<Icon type="close" />}
                    onChange={(e) => {this.setState({roleFilter: e});}}
                    style={{'width': '150px', 'margin': '0 15px'}}>
                {this.state.allRoles.map(entry => {
                    return <Select.Option value={entry[0]} key={entry[0]}>
                            {entry[1]}
                    </Select.Option>
                })}
            </Select>
            <Select placeholder='Status'
                   allowClear={true}
                   clearIcon={<Icon type="close" />}
                   onChange={(e) => {this.setState({isActiveFilter: e});}}
                   style={{'width': '150px'}}>
                <Select.Option value={true}>{t('active')}</Select.Option>
                <Select.Option value={false}>{t('inactive')}</Select.Option>
            </Select>
        </Row>
    };

    componentWillReceiveProps(newProps) {
        this.setState({activeKey: newProps.navTab});
    }

    render() {
        return (
            <div>
                <Row type="flex" justify="end">
                    <Col>
                        {this.renderSearchInput()}
                    </Col>
                </Row>
                {this.renderFilters()}
                <EmployeesTable
                    toggleEmployeeActivitiesModal={
                        (userId) => {
                            this.setState(
                                {
                                    employeeActivitiesModalUserId: userId,
                                    showEmployeeActivitiesModal: true
                                }
                            )
                        }
                    }
                    search={this.state.search}
                    setModalProps={this.props.setModalProps}
                    role={this.state.roleFilter}
                    isActive={this.state.isActiveFilter}
                />
                <EmployeeActivitiesModal
                    userId={this.state.employeeActivitiesModalUserId}
                    setShowModal={(value) => this.setState({showEmployeeActivitiesModal: value})}
                    showModal={this.state.showEmployeeActivitiesModal}/>
            </div>
        );
    }
}

export default withTranslation('employees')(EmployeesComponent);