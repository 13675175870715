import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Form, Upload, Icon, Modal, message } from 'antd';
import client from '../../../../../config/apollo/client'
import UPLOAD_FILE_MUTATION from "../../../../../gql/mutations/UPLOAD_FILE_MUTATION";
import GET_FILES from "../../../../../gql/queries/GET_FILES";
import REMOVE_FILE_MUTATION from "../../../../../gql/mutations/REMOVE_FILE_MUTATION";
import { withTranslation } from "react-i18next";

const cookie = new Cookies();

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


class UploadDocumentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      fileList: [],
    };
    this.currentProductId = this.props.id || cookie.get('currentProductId');
  }
  
  
  componentDidMount() {
    this.getExistingFiles();
  }
  
  handleCancel = () => this.setState({ previewVisible: false });
  
  handlePreview = async file => {
    const { url, name, originFileObj } = file;

    if (!url && !file.preview) {
      file.preview = await getBase64(originFileObj);
    }
    const previewImage = url || file.preview;
    const isPreviewAvailable = /\.(jpg|png|jpeg|bmp)+$/i.test(name);
    
    this.setState({
      previewVisible: true,
      previewName: name,
      previewImage,
      isPreviewAvailable,
    });
  };
  
  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < process.env.REACT_APP_MAX_SIZE;
    if (!isLt2M) {
      message.error(this.props.t('sizeError', { size: process.env.REACT_APP_MAX_SIZE }));
    }
    return isLt2M;
  }
  
  getExistingFiles = () => {
    client.query({
      query: GET_FILES,
      fetchPolicy: 'network-only',
      variables: {
        id: this.currentProductId,
        type: this.props.attachmentType
      }
    }).then(res => {
      const file = res.data.general.products.edges[ 0 ].node.attachments.edges.map(af => {
        const name = af.node.file;
        const nameCut = name ? name.substring(name.lastIndexOf("/") + 1, name.indexOf("?") > 0 ? name.indexOf("?") : name.length) : '';
        return {
          uid: af.node.id,
          id: af.node.id,
          name: nameCut,
          status: 'done',
          thumbUrl: `${process.env.REACT_APP_URL}${af.node.file}`,
          preview: `${process.env.REACT_APP_URL}${af.node.file}`
        }
      });
      this.setState({
        fileList: file
      });
    })
  };
  
  handleDelete = (fileList) => {
    const successMsg = this.props.t('msg-success');
    const errorMsg = this.props.t('msg-error');
    client.mutate({
      mutation: REMOVE_FILE_MUTATION,
      variables: {
        id: fileList.id,
      }
    }).then(res => {
      message.success(successMsg)
    }).catch(err => {
      message.error(errorMsg);
    })
  };
  
  handleChange = ({ file, fileList }) => {
    if (fileList.length < this.state.fileList.length)
      this.setState({ fileList });
  };
  
  uploadFile = ({ onSuccess, onError, file, ...data }) => {
    const { fileList } = this.state;
    const uid = fileList.length;
    const newFileList = [...fileList];
    newFileList.push({
      uid: uid,
      name: file.name,
      status: 'uploading',
    });
    this.setState({ fileList: newFileList }, () => {
      client.mutate({
        mutation: UPLOAD_FILE_MUTATION,
        variables: {
          input: {
            file,
            product: this.currentProductId,
            type: this.props.attachmentType,
          }
        }
      }).then(({ data: { createAttachmentMutation } }) => {
        const newFileList = [...this.state.fileList];
        let currentFileIndex = newFileList.findIndex(e => e.uid === uid);
        newFileList[ currentFileIndex ] = {
          ...newFileList[ currentFileIndex ],
          uid: createAttachmentMutation.id,
          id: createAttachmentMutation.id,
          status: 'done',
          thumbUrl: `${process.env.REACT_APP_URL}${createAttachmentMutation.file}`,
          preview: `${process.env.REACT_APP_URL}${createAttachmentMutation.file}`
        };
        this.setState({ fileList: newFileList }, () => onSuccess());
      }).catch(err => {
        const newFileList = [...this.state.fileList];
        let currentFileIndex = newFileList.findIndex(e => e.uid === uid);
        newFileList[ currentFileIndex ] = {
          ...newFileList[ currentFileIndex ],
          status: 'error',
        };
        this.setState({ fileList: newFileList }, () => onError());
      })
    });
  };
  
  render() {
    const { previewVisible, previewImage, fileList, isPreviewAvailable, previewName } = this.state;
    const { t, form: { getFieldDecorator } } = this.props;
    
    const uploadButton = (
      <div>
        <Icon type="plus"/>
        <div className="ant-upload-text">{t('add-file')}</div>
      </div>
    );
    return (
      <Form>
        <div className="clearfix">
          <Form.Item style={{ marginTop: '15px', minHeight: '160px', paddingLeft: '15px' }}>
            {getFieldDecorator('document', {})(
              <Upload
                name='document'
                multiple={true}
                customRequest={this.uploadFile}
                listType="picture-card"
                fileList={fileList}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
                onPreview={this.handlePreview}
                onRemove={this.handleDelete}
              >
                {
                  fileList.map(t => {
                    if (t.thumbUrl === '') {
                      return t.thumbUrl = t.name;
                    }
                    return true
                  })}
                {uploadButton}
              </Upload>
            )}
          </Form.Item>
          <Modal
            width={isPreviewAvailable ? '800px' : '400px'}
            visible={previewVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            {isPreviewAvailable && <img alt="example" style={{ width: '100%' }} src={previewImage}/>}
            <p>{previewName}</p>
          </Modal>
        </div>
      </Form>
    );
  }
}

const UploadDocument = withTranslation('documents')(Form.create()(UploadDocumentForm));

export default UploadDocument;
