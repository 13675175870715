import React, {Component} from 'react';
import {Button, Col, Divider, Icon, Modal, Row, Upload, Table, Select, message} from "antd";
import {withApollo} from "react-apollo";
import IMPORT_PRICES_MUTATION from "../../gql/mutations/IMPORT_PRICES/IMPORT_PRICES_MUTATION";
import styled from "styled-components";
import updatePriceImport from "../../gql/updaters/addPriceImport";
import {withTranslation} from "react-i18next";
import IMPORT_PRODUCTS_MUTATION from "../../gql/mutations/IMPORT_PRODUCT_MUTATION";
import updateProductImport from "../../gql/updaters/productImport";

const TableContainer = styled.div`
  .ant-table-header{
    overflow: auto !important
  }
  .ant-table-body {
    overflow: auto !important
  }
`;


const ButtonGroup = Button.Group;
//const {Title, Paragraph, Text} = Typography;

class PriceImportModal extends Component {
    constructor(props) {
        super(props);
        this.paramSelectRef = React.createRef();
        this.state = {
            fileList: [],
            uploading: false,
            errors: null,
            rowsErrors: null,
            brand: 'V',
        }
    }

    getProductImportTemplate = () => {
        const template = `/import_template/${this.state.brand}`
         fetch(process.env.REACT_APP_URL_IMPORT + template).then((response) => {
            if (!response.ok) throw response;
            return response.blob();
        }).then(blob => {
             const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'szablon.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.href = '';
            document.body.removeChild(link);
        }).catch(err => {
            return console.error(err)
        }).then(err_json => {
            if (err_json) message.error(err_json.message);
        })
    }

    toggleModal = () => {
        this.setState({
            fileList: [],
            uploading: false,
            errors: null,
            rowsErrors: null,
        }, () => {
            this.props.setShowModal(false)

        })
    };

    handleProductsUpload = () => {
       const file = this.state.fileList[0];
       if (file) {
           const input = {
                file: file,
                brand: this.state.brand,
            }
         this.props.client.mutate({
            mutation: IMPORT_PRODUCTS_MUTATION,
            variables: {
                input
            }
        }).then(({data}) => {
            const mutationResponse = data.importProductsMutation;
             if (mutationResponse.errors || mutationResponse.workbookErrors) {
                 this.setState(
                    {
                         uploading: false,
                        errors: mutationResponse.errors && mutationResponse.errors.map((error) => {
                            return {field: error.field, message: error.messages[0]}
                        }),
                         rowsErrors: mutationResponse.workbookErrors && mutationResponse.workbookErrors.map((error) => {
                            return {row: error.row, column: error.column, message: error.messages[0]}
                         })
                     }
                 )
             } else {
                 updateProductImport(this.props.client, mutationResponse.priceImport).then(() => this.toggleModal());
                 message.info('Otrzymasz email po zakończeniu importu');
            }
        })
       }
    }

    handlePriceUpload = (force) => {
        this.props.client.mutate({
            mutation: IMPORT_PRICES_MUTATION,
            variables: {
                input: {
                    file: this.state.fileList[0],
                    ignoreErrors: force
                }
            }
        }).then(({data}) => {
            const mutationResponse = data.importPricesMutation;
            if (mutationResponse.errors || mutationResponse.workbookErrors) {
                this.setState(
                    {
                        uploading: false,
                        errors: mutationResponse.errors && mutationResponse.errors.map((error) => {
                            return {field: error.field, message: error.messages[0]}
                        }),
                        rowsErrors: mutationResponse.workbookErrors && mutationResponse.workbookErrors.map((error) => {
                            return {row: error.row, column: error.column, message: error.messages[0]}
                        })
                    }
                )
            } else {
                updatePriceImport(this.props.client, mutationResponse.priceImport).then(() => this.toggleModal());
            }
        })
    }

    handleUpload = (force = false) => {
        const {fileList} = this.state;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });
        this.setState({
            uploading: true,
        });

        if (this.props.importType === 'price-import') {
            this.handlePriceUpload(force)
        } else {
            this.handleProductsUpload(fileList)
        }
    };

    importProductsModalContent = () => (
          <>
              <h3>Aby zaimportować produkty skorzystaj z gotowego szablonu pliku Excel - jest do pobrania poniżej. Zwróć uwagę, że w 2. zakładce tego pliku znajduje się lista wszystkich kategorii marki wraz z ich ID, które będzie potrzebne podczas importu.</h3>
            <b>Pola obowiązkowe</b>
            <ul style={{marginTop: '15px'}}>
                <li>nazwa</li>
                <li>numer katalogowy</li>
                <li>kod EAN</li>
                <li>kategoria (id kategorii będącej na końcu ścieżki kategorii, lista id jest na drugim arkuszu szablonu)</li>
            </ul>
            <b>Pola nieobowiązkowe</b>
             <ul style={{marginTop: '15px'}}>
                 <li>cena regularna</li>
                 <li>cena regularna: Data od (dd.mm.yyyy)</li>
                 <li>cena regularna: Data do (dd.mm.yyyy)</li>
                 <li>cena regularna: opis</li>
            <li>model</li>
            <li>opis towaru krótki</li>
            <li>opis towaru długi</li>
            <li>grupa produktów</li>
            <li>status produktu</li>
            <li>cena promocyjna</li>
            <li>cena promocyjna: Data od (dd.mm.yyyy)</li>
            <li>cena promocyjna: Data do (dd.mm.yyyy)</li>
            <li>cena promocyjna: opis</li>
            <li>Pola do załączników -  w każdym z pól powinny znajdować się wartości oddzielone przecinkami, dla każdego załącznika musi być dodana wartość w każdym z poniższych pól</li>
                 <ul>
                     <li>Załączniki: url np. [https://some_url.pl/photo.jpg,https://some_url.pl/document.pdf,https://some_url.pl/diagram.png]</li>
                     <li>Załączniki: opis np. [Opis zdjęcia,Opis dokumentu,Opis diagramu]</li>
                     <li>Załączniki: typ np. [Zdjęcie,Dokumenty ErP,Schematy]</li>
                     <li>Załączniki: czy wewnętrzny (Tak lub Nie) np. [Tak,Nie,Tak]</li>
                 </ul>
             <li>Wyposażenie dodatkowe (oddzielone przecinkami numery katalogowe wyposażenia dodatkowego)</li>
             <li>Wyposażenie dodatkowe: ilość (oddzielone przecinkami liczby)</li>
             <li>Produkty wchodzące w skład dostawy (oddzielone przecinkami numery katalogowe produktów wchodzących w skład dostawy)</li>
             <li>Produkty wchodzące w skład dostawy: ilość (oddzielone przecinkami liczby)</li>
             <li>Wyposażanie obowiązkowe (oddzielone przecinkami numery katalogowe produktów wchodzących w skład dostawy)</li>
             <li>Wyposażanie obowiązkowe: ilość (oddzielone przecinkami liczby)</li><br/>
                 <p>(Jeżeli do danego produktu zostanie dodane "Wyposażenie dodatkowe", "Produkty wchodzące w skład dostawy" lub "Wyposażanie obowiązkowe", które jest już do niego przypisane, to zostanie ono powielone - przypisane po raz kolejny.)</p>
             <li>Parametry: [Nazwa parametru,Kategoria parametru/Brak kategorii,Typ parametru] - jedna kolumna na każdy parametr
                <ul>
                    <li>Multichoice: wartości odzdzielone przecinkami</li>
                    <li>Zakres:[początek zakresu]:[koniec zakresu]</li>
                    <li>Pozostałe type: pojedyncze wartości</li>
                </ul>

             </li>
        </ul>
        </>
    );

     importPricesModalContent = () => (
        <>
            <b>{this.props.t('importHeadersText1')}</b>
            <ul style={{marginTop: '15px'}}>
                <li>numer katalogowy produktu</li>
                <li>cena</li>
                <li>data od</li>
                <li>data do {this.props.t('additionalField')}</li>
                <li>komentarz do ceny</li>
                <li>promocja</li>
            </ul>
            <div style={{marginBottom: '10px'}}>
                {this.props.t('promotionInfo')}
           </div>
            {this.props.t('importHeadersText2')}
        </>
    )


    render() {
        const {t, importType } = this.props;
        const {uploading, fileList} = this.state;
        const uploadProps = {
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: [],
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file],
                }));
                return false;
            },
            fileList,
            accept: '.xlsx',
            multiple: false
        };
        return <Modal
            visible={this.props.showModal}
            width={850}
            onCancel={this.toggleModal}
            title={[
                <>
                <div style={{display: "flex", justifyContent: 'center', position: 'relative'}} key={0}>
                    {this.props.importType === 'product-import' &&
                        <Select
                            value={this.state.brand}
                            name={'brands'}
                            style={{width: '200px', position: 'absolute', left: '20px', top: '0'}}
                            onSelect={(value) => this.setState({brand : value})}
                        >
                          <Select.Option value={'V'}>
                            Vaillant
                          </Select.Option>
                         <Select.Option value={'S'}>
                            Saunier Duval
                          </Select.Option>
                    </Select>
                    }
                    <h3>
                        <b>{t('importFile')}</b>
                    </h3>
                 </div>
                </>
            ]}
            footer={[
                <Row key={0}>
                    <Col md={9}>
                        <Row type='flex' justify='center'>
                            <span className='export-info'>{this.state.footerMessage}</span>
                        </Row>
                    </Col>
                    <Col md={15}>
                        <Button onClick={this.toggleModal}>
                            {t('cancel')}
                        </Button>
                        {!this.state.uploading && this.state.rowsErrors && !this.state.errors ?
                            <ButtonGroup>
                                <Button
                                    type="primary"
                                    onClick={() => this.handleUpload(false)}
                                    disabled={fileList.length === 0}
                                    loading={uploading}
                                >
                                    {uploading ? t('importing') : t('retryImport')}
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => this.handleUpload(true)}
                                    disabled={fileList.length === 0}
                                    loading={uploading}
                                >
                                    {uploading ? t('importing') : t('skipBadRows')}
                                </Button>
                            </ButtonGroup>
                            :
                            <Button
                                type="primary"
                                onClick={() => this.handleUpload(false)}
                                disabled={fileList.length === 0}
                                loading={uploading}
                            >
                                {
                                    uploading ?
                                        t('importing')
                                        :
                                        (this.state.rowsErrors || this.state.errors) ?
                                            t('retryImport')
                                            :
                                            t('startImport')
                                }
                            </Button>

                        }

                    </Col>
                </Row>
            ]}
        >
            <Row>
                <div style={{padding: '0 14px 14px 14px',
                            marginBottom: '24px',
                            borderBottom: '1px solid #efefef'}}>
                    {importType === 'price-import' ? this.importPricesModalContent() : this.importProductsModalContent()}
                </div>
                {importType === 'product-import' &&   <Col md={12}>
                    <span style={{lineHeight: '32px'}}><Button onClick={this.getProductImportTemplate}>{t('downloadImportFile')}</Button></span>
                </Col>}
                <Col md={3}>
                    <span style={{lineHeight: '32px'}}>{t('importText')}</span>
                </Col>
                <Col md={9}>
                    <Upload {...uploadProps}>
                        <Button>
                            <Icon type="upload"/>{t('selectFile')}
                        </Button>
                    </Upload>
                </Col>
            </Row>
            <TableContainer>
                {(this.state.errors) &&
                <>
                    <Divider/>
                    <Table
                        title={() => <h3><b>{t('importErrors')}</b></h3>}
                        pagination={false}
                        scroll={{y: 300}}
                        dataSource={this.state.errors}
                        columns={
                            [
                                {
                                    title: t('field'),
                                    dataIndex: 'field',
                                },
                                {
                                    title: t('error'),
                                    dataIndex: 'message',
                                },

                            ]
                        }

                    />
                </>
                }
                {(this.state.rowsErrors) &&
                <>
                    <Divider/>
                    <Table
                        title={() => <h3><b>{t('rowsErrors')}</b></h3>}
                        pagination={false}
                        scroll={{y: 300}}
                        dataSource={this.state.rowsErrors}
                        columns={
                            [
                                {
                                    title: t('row'),
                                    dataIndex: 'row',
                                    width: '25%'
                                },
                                {
                                    title: t('column'),
                                    dataIndex: 'column',
                                    width: '25%'
                                },
                                {
                                    title: t('error'),
                                    dataIndex: 'message',
                                    width: '50%'
                                }

                            ]
                        }

                    />
                </>
                }
            </TableContainer>
        </Modal>
    }
}

export default withTranslation('priceImport')(withApollo(PriceImportModal));