import React from 'react';
import {Form, Input} from "antd";

const InputTypeTextLong = ({getFieldDecorator, id, initialValue='', formItemVariables}) => {
  return (
    <Form.Item {...formItemVariables}>
       {getFieldDecorator(id, {
         initialValue
          })(
            <Input.TextArea
                  autosize={{ minRows: 3, maxRows: 10 }}
            />
          )}

    </Form.Item>
  );

};

export default InputTypeTextLong;