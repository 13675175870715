export default {
  "before": "Before publication",
  "ready": "Ready to publish",
  "out": 'Withdrawn',
  "current" : "On the market",
  "hide": "Hidden",
  "archival": "Archival",
  "updatedAt": "Updated at",
  "modyfiedBy": "Modyfied by",
  "products": "Products",
  "add-product": "+ Add product",
  "employees": "Employees and premissions",
  "employeesList": "Employees list",
  "addEmployee": "+ add employee",
  "addValidDate": 'Price validity date',
  "dataExport": "Data export",
  "templates": "Templates",
  "fileExport": "Export file",
  "createTemplate": "Create templates",
  "priceImport": "Price import",
  "productImports": "Product import",
  "importsHistory": "Imports history",
  "importProductsHistory": "Product imports history",
  "importPrice": "Import prices",
  "importProduct": "Import products",
  "searchProduct": "search for product",
  "log-out": "Log out",
  "brand": "Brand",
  "status": "Status",
  "category": "Category",
  "name": "Name",
  "catalougeNumber": "Catalouge number",
  "price": "Price",
   "more": "More",
  "edit": "Edit the product",
  "hide-product": "Hide the product",
  "cancel-product": "Widthdrawn the product",
  "delete": "Delete the product",
  "publish": "Publish the product",
  "transfer": "Transfer to publication",
  "edit-product": "Edit the product",
  "copy": "Make a copy",
  "restore": "Restore the product",
  "archive-product": "Archive product",
  "yes": "Yes",
  "no": "No",
  "confirm-delete": "Are You sure you want to delete this product?",
  "confirm-publish": "Are You sure you want to publish this product?",
  "confirm-hide": "Are You sure you want to hide this product?",
  "confirm-withdrawn": "Are You sure you want to withdrawn this product?",
  "confirm-transfer": "Are You sure you want to transfer this product to publication?",
  "confirm-restore": "Are You sure you want to restore this product?",
  "confirm-archival": "Are You sure ypu want to archival this product?",
  "-copy": "-copy",
  "delete-checked": "Delete all checked",
  "confirm-checked": "Are you sure You want to delete all checked?",
  "group-actions": "Group actions",
   "unknownNumber": "Unknown number",
  "giveNumber": "Provide number",
  "catalogueNumberPlaceholder": "Enter catalog number",
  "verify": "Verify",
  "noProduct": "No product",
  "noQuantity": "No quantity",
  "quantityPlaceholder": "Quantity",
  "removePosition": "Remove position",
  "moveUp": "Move up",
  "addUnder": "Add underneath",
  "value": "Input a value",
  "select": "Select option",
  "from": "from",
  "to": "to",
  "filters": "Filters",
  "all": "All",
  "more-filters": "More filters",
  "validationErr-length" : "Text too long!",
  "clearFilters" : "Clear filters",
  "promotions": "Promotions",
  "categoriesAndSubcategories": "Categories and subcategories",
}