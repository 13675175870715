import React from 'react';
import PropTypes from "prop-types";
import Layout from "../../structure/Layout/Layout";
import {Button, Divider, Row, Col, Icon} from 'antd';
import PricesImportCards from "../PricesImportCards";
import PriceImportModal from "../PriceImportModal/PriceImportModal";
import {withTranslation} from "react-i18next";
import {StyledTitle} from "../_styledComponents";

const PriceImportComponent = ({showImportModal, setShowImportModal, importType, t}) => {
    return (
        <Layout>
            <Row>
                <Col md={19}>
                  <StyledTitle>{importType === 'price-import' ?  t('priceImport') : t('productImport')}</StyledTitle>
                </Col>
                <Col md={5}>
                    <Button onClick={() => setShowImportModal(true)} block><Icon type='upload'/>{t('importFile')}</Button>
                    <PriceImportModal showModal={showImportModal} setShowModal={setShowImportModal} importType={importType}/>
                </Col>
            </Row>
            <Divider/>
            <Row type='flex' align='middle' style={{'paddingTop': '15px'}}>
                <Col md={19}>
                    <span style={{'fontSize': '16px'}}>{t('importedFiles')}</span>
                </Col>
            </Row>
            <Divider/>
            <Row gutter={24}>
                    <PricesImportCards />
            </Row>
        </Layout>
    )
};
PriceImportComponent.defaultProps = {};
PriceImportComponent.propTypes = {
    showImportModal: PropTypes.bool.isRequired,
    setShowImportModal: PropTypes.func.isRequired,
};

export default withTranslation('priceImport')(PriceImportComponent);