import gql from 'graphql-tag';

const GET_ME_INFO = gql`
    query meInfo{
      me{
        user{
          id
          displayName
          role
        }
      }
    }
`;

export default GET_ME_INFO;