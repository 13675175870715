const trimString = (str, letterNumber) => {
    if (typeof str !== 'string') {
        console.warn('it is not a string');
        return false;
    }
    if (str.length < letterNumber - 3) {
        return str;
    }
    return `${str.substring(0, letterNumber)}...`;

};

export default trimString;