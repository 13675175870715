export default {
  "position": "Position",
  "active": "Active",
  "inactive": "Inactive",
  "nameSurname": "Name and surname",
  "emailLabel": "Email",
  "phoneLabel": "Phone",
  "roleLabel": "Position",
  "lastActivityLabel": "Last activity",
  "editProfile": "Edit profile",
  "activityHistory": "Activity history",
  "none": "None",
  "searchEmployees": "find employee",
  "more": "Actions",
  "addEmployee": "Add new employee",
  "save": "Save",
  "nameLabel": "Name",
  "firstNameError": "Please fill name field!",
  "lastNameLabel": "Surname",
  "lastNameError": "Please fill surname field!",
  "emailError": "Please fill email field!",
  "roleError": "Please fill role field",
  "companyLabel": "Company",
  "taxIdLabel": "Tax id",
  "brandsAccessLabel": "Acces to brands:",
  "dateLabel": "Activity date",
  "close": "Close",
  "saveSuccess": "Saved",
  "saveFail": "Error",
  "removeEmployee": "Remove employee",
  "confirm-delete": "Are you sure you want to remove this employee?",
  "resetPassword": "Reset password",
  "confirmPasswordReset": "Are you sure you want to reset password of ",
  "resetEmailSend" : "Reset e-mail send to user!",
  'yes': 'Yes',
  'no': 'No',
}