import React, {useEffect, useState} from 'react';
import {Button, Card, DatePicker, Row, Form, Col, message} from "antd";
import locale from "antd/es/date-picker/locale/pl_PL";
import {withApollo} from "react-apollo";
import ADD_BRAND_PRICE_DATE from "../../gql/mutations/ADD_BRAND_PRICE_DATE";
import GET_VALIDITY_DATE_BRAND from '../../gql/queries/GET_VALIDITY_DATE_BRAND';
import moment from "moment";
const formItemLayout = {
  labelCol: {
    xs: {span: 12},
    sm: {span: 12},
    md: {span: 12},
    xxl: {span: 12},
  },
  wrapperCol: {
    xs: {span: 12},
    sm: {span: 12},
    md: {span: 12},
    xxl: {span: 12},
  },
};

const BrandPricesDateForm = ({brand, form, t, client, date}) => {
    const [stateBrand, setStateBrand] = useState('');
    const [stateDate, setStateDate] = useState(null)
    const {getFieldDecorator, validateFields} = form;

    useEffect(() => {
       setStateDate(date);
        brand === "V" ? setStateBrand('Vaillant') : setStateBrand('Saunier Duval')
    }, [])

    const onChange = (date, dateString) => {
        setStateDate(dateString)
    }

    const onSubmit = (e) => {
      e.preventDefault();
     validateFields((err, value) => {
         const input = {
             brand: brand,
             validFrom:  moment(stateDate).format('YYYY-MM-DD'),
         }
         if (!err) {
            client.mutate({
                mutation: ADD_BRAND_PRICE_DATE,
                refetchQueries: [{query: GET_VALIDITY_DATE_BRAND}],
                variables: {
                    input: input
                }
            }).then(res => {
                const date = res.data.createUpdatePriceValidFromMutation.validFrom;
                setStateDate(date)
                 message.success(t('dateSaveSuccess'))
              }).catch(err => {
                    message.error(t('dateSaveError'))
              })
         }
      });
    }

    return (
        <Form onSubmit={onSubmit} {...formItemLayout}>
            <Card title={stateBrand}>
                 <Row>
                     <Col offset={6} span={12}>
                       <Form.Item
                        name={brand}
                        label={t('chooseDate')}>
                        {getFieldDecorator(brand, {
                            rules: [ {required: true, message: t('fieldWarning') } ],
                            initialValue: stateDate !== null ? moment(stateDate, 'YYYY-MM-DD') : null
                        })(<DatePicker
                                onChange={onChange}
                                locale={locale}
                                style={{width: '100%'}}
                            />
                        )}
                       </Form.Item>
                     </Col>
                     <Col offset={1} span={3}>
                        <Button
                            type='primary'
                            style={{marginTop: '5px'}}
                            htmlType='submit'>
                            {t('save')}
                        </Button>
                     </Col>
                 </Row>
            </Card>
        </Form>
    )
}

const BrandPricesDate = Form.create()(BrandPricesDateForm);
export default withApollo(BrandPricesDate);