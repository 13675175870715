import React, { useState, useRef } from "react";

import { Form, Input, message } from "antd";

import uppercaseFirstLetter from "../../../../utils/uppercaseFirstLetter";

import client from "../../../../config/apollo/client";
import UPDATE_CATEGORY_NAME from "../../../../gql/mutations/UPDATE_CATEGORY_NAME";

import { withTranslation } from "react-i18next";

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => {
  return (
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  );
};

export const EditableFormRow = Form.create()(EditableRow);

export const EditableCell = (props) => {
  const [myForm, setMyForm] = useState();
  const [editing, setEditing] = useState(false);
  const inputEl = useRef(null);

  const { t } = props;

  const successMsg = uppercaseFirstLetter(t("msg-success"));
  const errorMsg = uppercaseFirstLetter(t("msg-error"));

  const toggleEdit = () => {
    setEditing(!editing);
    if (editing && inputEl.current) {
      inputEl.current.focus();
    }
  };

  const save = (e) => {
    myForm.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      toggleEdit();
      const { record, handleSave } = props;
      client
        .mutate({
          mutation: UPDATE_CATEGORY_NAME,
          variables: {
            input: {
              id: record.id,
              displayName: values.name,
            },
          },
        })
        .then(() => {
          handleSave(record.parent.id, record.mainCategory);
          message.success(successMsg);
        })
        .catch(() => message.error(errorMsg));
    });
  };

  const renderCell = (form) => {
    setMyForm(form);
    const { children, dataIndex, record } = props;

    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${t(title)} ${t("is-required")}`,
            },
            {
              max: 48,
              message: uppercaseFirstLetter(
                t("maximum-number-of-characters-is-48")
              ),
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={inputEl} onPressEnter={save} onBlur={save} />)}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  };

  const {
    editable,
    dataIndex,
    title,
    record,
    index,
    handleSave,
    children,
    ...restProps
  } = props;
  return (
    <td {...restProps}>
      {editable ? (
        <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
      ) : (
        children
      )}
    </td>
  );
};

export const EditableCellComponent = withTranslation(
  "categoriesAndSubcategories"
)(EditableCell);
