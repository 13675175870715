import styled from 'styled-components';
import {Menu} from "antd";

export default styled(Menu.Item)`
  &.ant-menu-item {
    transition: none;
      
    > a:hover {
      color: ${({theme}) => theme.color.green};
    }
  }
`;