import styled from "styled-components";

import { Table, Button } from "antd";

export const StyledTable = styled(Table)`
  .ant-upload-list {
    max-width: 200px;
  }

  .forward-btn button {
    color: ${({ theme }) => theme.color.green};

    &:hover {
      background-color: ${({ theme }) => theme.color.green};
      color: ${({ theme }) => theme.color.white};
    }
  }

  .forward-btn button:disabled,
  .forward-btn button:disabled:hover {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
  }

  .drag-and-drop {
    a {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      color: ${({ theme }) => theme.color.green};
    }
  }
`;

export const StyledButton = styled(Button)`
  min-width: 135px;
`;
