import gql from 'graphql-tag';

const REMOVE_PRODUCTS = gql`
mutation removeProductsMutation($input: DeleteProductsMutationInput!) {
  deleteProductsMutation(input: $input) {
    ok
    errors {
      field
      messages
    }
  }
}
`;

export default REMOVE_PRODUCTS;