import gql from 'graphql-tag';

const DELETE_PRODUCT_KIT_MUTATION = gql`
mutation deleteProductKitMutation($input: DeleteProductKitMutationInput!){
  deleteProductKitMutation(input:$input){
    ok
    errors{
      field
      messages
    }
  }
}
`;

export default DELETE_PRODUCT_KIT_MUTATION;