export default {
  "position": "Stanowisko",
  "active": "Aktywny",
  "inactive": "Nieaktywny",
  "nameSurname": "Imię i nazwisko",
  "emailLabel": "Adres email",
  "phoneLabel": "Numer telefonu",
  "roleLabel": "Stanowisko",
  "lastActivityLabel": "Ostatnia aktywność",
  "editProfile": "Edytuj profil",
  "activityHistory": "Historia aktywności",
  "none": "Brak",
  "searchEmployees": "wyszukaj pracownika",
  "more": "Więcej",
  "addEmployee": "Dodaj pracownika",
  "save": "Zapisz",
  "nameLabel": "Imię",
  "firstNameError": "Proszę podać imię!",
  "lastNameLabel": "Nazwisko",
  "lastNameError": "Proszę podać nazwisko!",
  "emailError": "Proszę podać adres email!",
  "roleError": "Proszę podać stanowisko",
  "companyLabel": "Firma",
  "taxIdLabel": "NIP",
  "brandsAccessLabel": "Dostęp do marki:",
  "dateLabel": "Data aktywności",
  "close": "Zamknij",
  "saveSucces": "Zapisano",
  "saveFail": "Wystąpił błąd",
  "removeEmployee": "Usuń pracownika",
  "confirm-delete": "Czy na pewno chcesz usunąć tego pracownika?",
  "resetPassword": "Zresetuj hasło",
  "confirmPasswordReset": "Czy na pewno chcesz zresetować hasło użytkownikowi",
  "resetEmailSend" : "E-mail z linkiem do resetu hasła wysłany użytkownikowi!",
  'yes': 'Tak',
  'no': 'Nie',
}