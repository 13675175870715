import gql from "graphql-tag";

const IMPORT_CATEGORY_IMAGE_MUTATION = gql`
  mutation importCategoryImageMutation(
    $input: ImportCategoryImageMutationInput!
  ) {
    importCategoryImageMutation(input: $input) {
      ok
      errors {
        messages
      }
    }
  }
`;

export default IMPORT_CATEGORY_IMAGE_MUTATION;
