import gql from 'graphql-tag';

const ADD_USER = gql`
   mutation AddUser($input: CreateUserMutationInput!) {
     createUserMutation(input:$input){
       id
       displayName
       email
       phoneNumber
       role
       roleDisplay
       lastActivity
       companyName
       taxId
       isActive
       errors{
         field
         messages
       }
     }
   }
`;

export default ADD_USER;