import gql from 'graphql-tag';

const GET_PARAMETER_GROUPS = gql`
    query parameterGroups{
      general{
        parameterGroupsValues
      }
    }
`;

export default GET_PARAMETER_GROUPS;
