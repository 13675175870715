import gql from 'graphql-tag';

const CHANGE_CATEGORIES_ORDER_MUTATION = gql`
mutation changeCategoriesOrderMutation($input: ChangeCategoriesOrderMutationInput!) {
  changeCategoriesOrderMutation(input: $input) {
    ok
    clientMutationId
  }
}
`;

export default CHANGE_CATEGORIES_ORDER_MUTATION;
