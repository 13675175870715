import React from 'react';
import {Form, Radio} from 'antd';
import {withTranslation} from "react-i18next";


const RadioButtonParam = ({id, initialValue, getFieldDecorator, t, formItemVariables}) => {

  return (
    <Form.Item {...formItemVariables}>
      {getFieldDecorator(id, {
         initialValue
          })(
            <Radio.Group style={{width: '100%'}}>
              <Radio.Button style={{width: '50%'}} value={1}>{t('yes')}</Radio.Button>
              <Radio.Button style={{width: '50%'}} value={0}>{t('no')}</Radio.Button>
            </Radio.Group>,
          )}

      </Form.Item>
  )
};

const RadioButton = withTranslation('products')(RadioButtonParam)
export default RadioButton;