import styled from "styled-components";
import {Form} from 'antd';

const {Item} = Form;

const StyledFormItem = styled(Item)`
    &.flex {
         .ant-form-item-children {
             display: flex;
         }
    }
    
    &:not(.flex) {
       .ant-form-item {
            width: 100%;
        }
        
        .ant-form-item-control-wrapper {
            width: 100%;
        }
        
        .ant-form-item-children {
            width: 100%;
            display: flex;
            height: 40px;
            align-items: center;
        }  
        
    }
    
`;

export default StyledFormItem;