export default {
  "addProductHeader": "Select brand and category of a product to move to the next step ",
  "brand" : "Brand:",
  "category" :  "Category",
  "chooseCategory": "Choose category",
  "name" : "Name",
  "catalogueNumber": "Catalogue number",
  "ean": "EAN",
  "model": "Model",
  "productGroup": "Product group",
  "placeholder": "Select...",
  "nextBtn" : "Next",
  "abortBtn" : "Cancel",
  "validationErr-name": "Please provide name!",
  "validationErr-name-length": "Name is too long!",
  "validationErr-ean": "Please provide EAN!",
  "validationErr-unique-ean": "Please provide unique EAN!",
  "validationErr-catNum": "Please provide catalogue number!",
  "validationErr-unique-catNum": "Please provide unique catalogue number!",
  "validationErr-category" : "Please provide category!",
  "unique": "Please provide unique value!",
  "general-err": "This field can't be empty!",
  "copy" : "Make a copy of the product",
  "save": "Save",
  "cancel": "Cancel",
}