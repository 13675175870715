export default {
    "promotions": "Promocje",
    "chooseBrand": "Wybierz markę",
    "title": "Tytuł",
    "description": "Opis",
    "category": "Kategoria",
    "product": "Produkt",
    "verify": "Weryfikuj",
    "clear": "Wyczyść",
    "dateRange": "Zakres dat trwania promocji",
    "banners" : "Banery reklamowe",
    "bannerDesktop": "Dodaj baner na desktop",
    "bannerTablet": "Dodaj baner na tablet",
    "bannerMobile": "Dodaj baner na mobile",
    "pdfFile": "Plik PDF",
    "addPdf": "Dodaj plik PDF",
    "prices": "Cennik",
    "addPrices": "Dodaj cennik",
    "wrongSizeImg": "Nieprawidłowy rozmiar zdjęcia! Poprawne wymiary to:",
    "fileSuccess": "Plik został pomyślnie załadowany!",
    "fileToHeavy": " Plik jest za duży! Upewnij się, że nie ma więcej niż 25MB",
    "required": "Pole wymagane!",
    "formError": "Formularz zawiera błędy!",
    "somethingsWrong": "Coś poszło nie tak!",
    "formSuccess": "Promocja została pomyślnie dodana!",
    "addPromotion": "Dodaj promocję",
    "fillForm": "Wypełnij formularz by dodać promocję",
    "catalogNumber": "Podaj numer katalogowy produktu",
    "cantFindProduct": "Brak produktu",
    "maxSizeD": "Maksymalne wymiary obrazka: 920x540",
    "maxSizeT": "Maksymalne wymiary obrazka: 790x540",
    "maxSizeM": "Maksymalne wymiary obrazka: 660x540",
    "maxWeight": "Plik nie może przekraczać 25MB",
    "promoted-success": "Promowane produkty dodane!",
    "addPromoted": "dodaj promowane produkty",
    "addProducts": "Zapisz",
     "addPromotionTitle": "dodaj promocję",
    "clickToFind": "Naciśnij by znaleźć produkt!",
     "productName": "Nazwa produktu",
    "noProduct": "Brak produktu!",
    "startDate": "Data początkowa",
    "endDate" : "Data końcowa",
}