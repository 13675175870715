import {Steps, Button, message} from 'antd';
import {withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import BasicData from './Steps/BasicData';
import TechnicalParameters from './Steps/TechnicalParameters';
import LogisticParameters from "./Steps/LogisticParameters";
import ProductDocuments from './Steps/ProductDocuments';
import {withTranslation} from "react-i18next";
import ProductKit from "./Steps/ProductKit/ProductKit";
import Prices from "./Steps/Prices";
import Cookies from 'universal-cookie';
import {Query} from 'react-apollo';
import GET_PRODUCT_INFO from "../../gql/queries/GET_PRODUCT_INFO";
import {FixedButtonContainer} from "../_styledComponents";

const cookies = new Cookies();

const Step = Steps.Step;

class AddProductStepsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentProductId: this.props.id,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.id !== this.props.id && nextProps.id) {
            this.setState({currentProductId: nextProps.id})
        }
    }

    next = async () => {
        const action = await this.child.nextAction();
        if (action) {
            this.redirect(this.props.currentStep + 1);
        } else {
            message.error('Sprawdź formularz i spróbuj ponownie')
        }
    };

    save = async () => {
        const action = await this.child.nextAction();
        if (action) {
            message.success('Zmiany pomyślnie zapisane')
            return true;
        } else {
            message.error('Sprawdź formularz i spróbuj ponownie')
            return false;
        }
    };

    done = async () => {
        const success = await this.save();
        if (success) {
            this.clearCurrentId();
            this.props.history.push('/products');
        }
    };

    prev = () => {
        this.redirect(this.props.currentStep - 1)
    };

    clearCurrentId = () => {
        const productCookie = cookies.get('currentProductId');
        this.setState({currentProductId: null});
        if (productCookie) {
            cookies.remove('currentProductId', {path: '/'});
        }
    };


    redirect = step => {
        const url = this.props.location.pathname;
        const newUrl = url.replace(/step=\d+/, `step=${step}`);
        this.props.history.push(newUrl);
    };

    preventRedirect = () => {
        message.warning('Proszę wypełnić formularz by przejść dalej!')
    };

    render() {
        const {currentStep, t} = this.props;
        const currentProductId = this.state.currentProductId || cookies.get('currentProductId');
        const steps = [
            {
                title: this.props.t('basicData'),
                content: BasicData,
            },
            {
                title: this.props.t('technicalData'),
                content: TechnicalParameters,
            },
            {
                title: this.props.t('logisticData'),
                content: LogisticParameters
            },
            {
                title: this.props.t('files'),
                content: ProductDocuments,
            },
            {
                title: this.props.t('accessories'),
                content: ProductKit,
            },
            {
                title: this.props.t('price'),
                content: Prices,
            }
        ];
        return (
            <div>
                <Steps current={currentStep}>
                    {steps.map((item, idx) => (
                        <Step key={item.title} title={item.title}
                              onClick={currentProductId ? () => this.redirect(idx) : () => this.preventRedirect()}
                              style={{cursor: 'pointer'}}/>
                    ))}
                </Steps>
                <div className="steps-content">
                    {
                        currentProductId ? (
                            <Query query={GET_PRODUCT_INFO}
                                   variables={{id: currentProductId}}
                            >
                                {({data, loading, error}) => {
                                    if (!loading && data) return <span>{data.general.products.edges[0].node.name} ({data.general.products.edges[0].node.catalogueNumber})</span>;
                                    else return null;
                                }}
                            </Query>
                        ) : null
                    }
                    {
                        React.createElement(
                            steps[currentStep].content,
                            {
                                onRef: ref => (this.child = ref),
                                clearCurrentId: this.clearCurrentId,
                                id: this.props.id,
                            },
                            null,
                        )
                    }
                </div>
                <div className="steps-action"
                     style={{position: 'fixed', bottom: '0', display: 'block', height: '50px', zIndex: '999'}}>
                    {
                        currentStep > 0
                        && (
                            <Button onClick={() => this.prev()} style={{marginBottom: '8px'}}>
                                {t('prev')}
                            </Button>
                        )
                    }
                    {
                        currentStep === 0
                        &&
                        <FixedButtonContainer>
                            <Button style={{margin: '8px', width: '83px'}}
                                    onClick={() => this.save()}>{t('save')}</Button>
                            <Button type="primary" style={{width: '83px'}}
                                    onClick={() => this.next()}>{t('next')}</Button>
                        </FixedButtonContainer>
                    }
                    {
                        currentStep > 0 && currentStep < steps.length - 1
                        &&
                        <FixedButtonContainer>
                            <Button style={{margin: '0 8px', width: '83px'}}
                                    onClick={() => this.save()}>{t('save')}</Button>
                            <Button style={{marginRight: '8px'}} type="primary"
                                    onClick={() => this.done()}>{t('done')}</Button>
                            <Button type="primary" style={{width: '83px', marginRight: '8px'}}
                                    onClick={() => this.next()}>{t('next')}</Button>
                        </FixedButtonContainer>
                    }
                    {
                        currentStep === steps.length - 1
                        &&
                        <FixedButtonContainer>
                            <Button style={{margin: '0 8px', width: '83px'}}
                                    onClick={() => this.save()}>{t('save')}</Button>
                            <Button type="primary" onClick={() => this.done()}>{t('done')}</Button>
                        </FixedButtonContainer>
                    }
                </div>
            </div>
        );
    }
}

const AddProductSteps = withTranslation('steps')(AddProductStepsComponent);

export default withRouter(AddProductSteps);