import React, {useEffect, useState} from 'react';
import {Button, Card, Form, message} from "antd";
import PromotionKitElement from "../PromotionKitElement";
import {withTranslation} from "react-i18next";
import ADD_PROMOTED_PRODUCTS from "../../gql/mutations/ADD_PROMOTED_PRODUCTS";
import GET_PROMOTED_PRODUCTS from "../../gql/queries/GET_PROMOTED_PRODUCT";
import {DivFlexEnd} from "../_styledComponents";
import {withRouter} from 'react-router-dom';

const AddPromotedProducts = ({form, client, history, t}) => {
    const [productsArray, setProductsArray] = useState([]);
    const [vaillantItems, setVaillantItems] = useState([])
    const [sdItems, setSdItems] = useState([]);

    useEffect(() => {
        getPromoted()
    }, []);

    const getPromoted = () => {
        client.query({
            query: GET_PROMOTED_PRODUCTS,

        }).then(res => {
            const items = res.data.general.productPromoted.edges;
            const getIds = items.map(item => item.node.id);

            setProductsArray(getIds);
            const vaillantItems = items.filter(item => {
                return item.node.brand === 'V'
            });
            const sdItems = items.filter(item => {
                return item.node.brand === 'S'
            });

            setVaillantItems(vaillantItems)
            setSdItems(sdItems)
        }).catch(err => {
            message.error(t('somethingsWrong'));
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        client.mutate({
            mutation: ADD_PROMOTED_PRODUCTS,
            refetchQueries: [{query: GET_PROMOTED_PRODUCTS}],
            variables: {
                input: {
                    productIds: productsArray
                }
            },
        }).then(res => {
            message.success(t('promotedSuccess'));
            setTimeout(() => history.push('/promotions'), 500);
        }).catch(err => {
            message.error(t('somethingsWrong'));
        })
    }

    const createArray = (item) => {
        if (!productsArray.includes(item)) {
              setProductsArray(items => [...items, item]);
        }
    }

    const removeFromArray = (el) => {
        let filteredArray = productsArray.filter(item => item !== el);
        setProductsArray(filteredArray);
    }

    const formItemProps = {
        t: t,
        form: form,
        promoted: true,
        updateProductData: createArray,
        category: null,
        client: client,
        removeFromArray: removeFromArray,
    }

    const createFields = (brand, fromState) => {
        let items = [];

        if(fromState) {
         const productSet = brand === 'V' ? vaillantItems : sdItems;
           productSet.forEach((item, i) => {

                items.push(
                    <PromotionKitElement
                        {...formItemProps}
                        key={i}
                        brand={brand}
                        promoted={true}
                        index={i}
                        product={item.node}
                    />
                )

            });
            if (items.length < 3) {
                while (items.length < 3) {

                      items.push(<PromotionKitElement
                        {...formItemProps}
                        brand={brand}
                        promoted={true}
                        index={items.length}
                        key={items.length}

                    />)
                }
            }
        } else {
            for (let i = 0; i < 3; i++) {
               items.push(
                    <PromotionKitElement
                        {...formItemProps}
                        key={i}
                        brand={brand}
                        index={i}
                        product={null}

                    />
                )
            }
        }

        return items;
    }

    return (
        <>
            <Card title={'Vaillant'} style={{textAlign: "center"}}>
                {vaillantItems.length ?
                    createFields('V', true)
                    : createFields('V', false)}

            </Card>
            <Card title={'Saunier Duval'} style={{textAlign: "center"}}>
               {sdItems.length ? createFields('S', true ) : createFields('S', false )}
            </Card>
              <DivFlexEnd>
                <Button onClick={onSubmit} style={{marginTop: '30px'}} type="primary" htmlType="submit">{t('addProducts')}</Button>
            </DivFlexEnd>
        </>
    );
};
const AddPromotedWithRouter = withRouter(AddPromotedProducts);
const AddPromotedProductsForm = withTranslation('promotions')(AddPromotedWithRouter)
export default Form.create()(AddPromotedProductsForm);