import styled from "styled-components";

const StyledIconWrapper = styled.span`
  color: ${props => props.status === 'error' ? props.theme.color.antRed : props.theme.color.antGreen};
  display: flex !important;
  position: absolute !important;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 32px;
  height: 20px;
`;

export default StyledIconWrapper;
