import React from 'react';
import {withApollo} from "react-apollo";
import PromotionKitElement from "../PromotionKitElement";

const PromotionKitContainer = ({form, client, updateProductData,product, t, promoted, category}) => {

    return (
            <PromotionKitElement
                  form={form}
                  client={client}
                  updateProductData={updateProductData}
                  category={category}
                  t={t}
                  product={product}
                  promoted={promoted}
               />
    )
};
const PromotionKit = withApollo(PromotionKitContainer);

export default PromotionKit;
