import styled from "styled-components";

const StyledFormContainer = styled.div`
    display: flex;
    justify-content: center;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    padding-top: 24px;
    
    .ant-card-head-title {
        text-align: center;
    }
    
    form {
        width: 90%;
        
        .ant-form-item.no-label {
            .ant-col-sm-18 {
                width: 100% !important;
            }
        }
    }
`;

export default StyledFormContainer;