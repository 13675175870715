import gql from 'graphql-tag';

const PRODUCTS_TABLE_QUERY = gql`query ProductsTable(
    $status: String,
    $pageSize: Int,
    $offset: Int, 
    $sortColumn: [String], 
    $search: String, 
    $categoryId: String, 
    $minPrice: Float, 
    $maxPrice: Float, 
    $filters: [FilterProductInput!], 
    $brand: String
  ) {
  general {
    products(
      brand: $brand, 
      status_In: $status, 
      first: $pageSize, 
      offset: $offset, 
      orderBy: $sortColumn, 
      search: $search, 
      mainCategory: $categoryId, 
      priceValue_Gte: $minPrice, 
      priceValue_Lte: $maxPrice,
      params: $filters
    ) {
      totalCount
      edges {
        node {
          name
          mainCategory {
            name
          }
          id
          categoryFullPath
          catalogueNumber
          priceValue
          updatedAt
          status
          statusName
          brandDisplay
          attachments(type: "P", first: 1 ) {
              edges {
                node {
                  file
                }
              }
          }
          updatedBy {
            displayName
          }
        }
      }
    }
  }
}

    `;

export default PRODUCTS_TABLE_QUERY;