import gql from 'graphql-tag';

const CATEGORY_PARAMETERS_QUERY = gql`query CategoryParameters($categoryIds: [ID!], $internalParams: Boolean) {
    general {
        parameters(category: $categoryIds, internal: $internalParams, orderBy: "name"){
            edges{
                node{
                    id
                    name
                    category{
                        edges{
                            node{
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

export default CATEGORY_PARAMETERS_QUERY;