import PRODUCTS_TABLE_QUERY from "../queries/PRODUCTS_TABLE_QUERY";


const addDuplicatedProduct = async (client, variables, newProduct) => {
    const cacheData = client.cache.readQuery(
        {
            query: PRODUCTS_TABLE_QUERY,
            variables
        }
    );
    if (cacheData) {

        const cachedData = client.cache.readQuery(
            {
                query: PRODUCTS_TABLE_QUERY,
                variables: {
                    ...variables,
                }
            }
        );

        const {status} =  variables;
        if (cachedData && (status === 'D' || status === '' )){
            const products = cachedData.general.products;
            products.totalCount += 1;
            if ( products.edges.length < variables.pageSize){
                products.edges.push({node:newProduct, __typename: 'ProductTypeEdge'});

            }
            cachedData.general.products = products;
            client.writeQuery({
                query: PRODUCTS_TABLE_QUERY,
                variables,
                data: cachedData
            });
        }

    }
};

export default addDuplicatedProduct