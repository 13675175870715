import {Component} from "react";
import {Form} from "antd";
import React from "react";
import ParameterQuery from "../../ParameterQuery";
import client from "../../../../config/apollo/client";
import Cookies from 'universal-cookie';
import PARAMETER_MUTATION from "../../../../gql/mutations/PARAMETER_MUTATION";

const cookie = new Cookies();

class LogisticParametersForm extends Component {
    constructor(props) {
        super(props);
        this.currentProductId = this.props.id || cookie.get('currentProductId');
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    nextAction = () => {

        return new Promise(
            resolve => {
                this.props.form.validateFields();
                this.props.form.validateFields(async (err, value) => {
                    if (!err) {
                        const parameters = [];
                        Object.keys(value).forEach(v => {
                            if (value[v] instanceof Array) {
                                parameters.push({
                                    parameterId: v,
                                    arrayValue: value[v],
                                });
                            } else {
                                if (v.indexOf('-from') !== -1) {
                                    const id = v.replace('-from', '');
                                    let index = null;
                                    parameters.find(
                                        (object, i) => {
                                            if (object.parameterId === id) {
                                                index = i;
                                                return true
                                            }
                                            return false
                                        }
                                    );
                                    if (index) {
                                        parameters[index].value = value[v] === '' ? null : value[v]
                                    } else {
                                        parameters.push({
                                            parameterId: id,
                                            value: value[v] === "" ? null : value[v],
                                        })
                                    }
                                } else if (v.indexOf('-to') !== -1) {
                                    const id = v.replace('-to', '');
                                    let index = null;
                                    parameters.find(
                                        (object, i) => {
                                            if (object.parameterId === id) {
                                                index = i;
                                                return true
                                            }
                                            return false
                                        }
                                    );
                                    if (index) {
                                        parameters[index].valueTo = value[v] === '' ? null : value[v]
                                    } else {
                                        parameters.push({
                                            parameterId: id,
                                            valueTo: value[v] === "" ? null : value[v],
                                        })
                                    }
                                } else {
                                    parameters.push({
                                        parameterId: v,
                                        value: value[v] === "" ? null : value[v],
                                    })
                                }
                            }
                        });
                        const {data} = await client.mutate({
                            mutation: PARAMETER_MUTATION,
                            variables: {
                                input: {
                                    productId: this.currentProductId,
                                    parameters,
                                    updateDescriptions: false
                                }
                            }
                        });
                        resolve(data.productParametersMutation.ok);
                    } else {
                        resolve(false)
                    }
                })
            }
        );
    };

    render() {

        return (

            <ParameterQuery
                id={this.currentProductId}
                type='L'
                {...this.props.form}
            />
        )
    }

}

const LogisticParameters = Form.create()(LogisticParametersForm);

export default LogisticParameters;

