import gql from 'graphql-tag';

const DELETE_PRICES_MUTATION = gql`
  mutation deletePricesMutation($input: DeletePricesMutationInput!) {
    deletePricesMutation(input: $input) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;

export default DELETE_PRICES_MUTATION;