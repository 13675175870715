import gql from 'graphql-tag';

const DELETE_PRICE_MUTATION = gql`
	mutation deletePriceMutation($input: DeletePriceMutationInput!) {
    deletePriceMutation(input: $input) {
      ok
      errors {
        field
        messages
      }
      product {
        id
        priceValue
      }
    }
  }
`;

export default DELETE_PRICE_MUTATION;