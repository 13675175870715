import PRODUCT_IMPORT_HISTORY from "../queries/PRODUCT_IMPORT_QUERY";

const updateProductImport = async (client, productImports) => {

    const cacheData = client.cache.readQuery(
        {
            query: PRODUCT_IMPORT_HISTORY,
        }
    );
    if (cacheData) {
        console.log(cacheData)
        const productImport = cacheData.general.productImports;
        productImport.edges.unshift({node:productImports, __typename: 'ProductImportTypeEdge'});
        productImport.totalCount += 1;
        cacheData.general.priceImports = productImport;
        client.writeQuery({
            query: PRODUCT_IMPORT_HISTORY,
            data: cacheData
        });

    }
};

export default updateProductImport;