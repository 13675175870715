import gql from 'graphql-tag';

const PARAMETER_CHOICES_QUERY = gql`query ParameterChoices($id: ID!) {
      general {
        parameters(id: $id) {
          edges{
            node{
              id
              choices
            }
          }
        }
      }
    }
`;

export default PARAMETER_CHOICES_QUERY;