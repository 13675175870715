import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9em;
  color: #c0c0c0;
`;

const Footer = () => (
  <StyledSpan>Vaillant Saunier Duval Sp. z o.o.</StyledSpan>
);

export default Footer;