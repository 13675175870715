import styled from 'styled-components';

const DivSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  
  &.row {
   justify-content: center;
   margin: 0 auto;
   
   .ant-upload-list-item-name {
        top: 0;
        left: 0;
   }
   
   .ant-form-item {
        display: flex;
        flex: auto;
   }
`;

export default DivSpaceBetween;