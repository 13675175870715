import gql from 'graphql-tag';

const UPDATE_PRODUCTS_STATUSES = gql`
mutation updateProductsStatusesMutation($input: UpdateProductStatusesMutationInput!) {
  updateProductsStatusesMutation(input: $input) {
    ok
    errors {
      field
      messages
    }
  }
}
`;

export default UPDATE_PRODUCTS_STATUSES;