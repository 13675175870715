import React, {Component} from 'react';
import Cookies from 'universal-cookie';
import {Card, Form, Input, Select} from 'antd';
import ChooseCategory from "../../../ChooseCategory";
import {withTranslation} from "react-i18next";
import GET_PRODUCT_ID from "../../../../gql/mutations/GET_PRODUCT_ID";
import UPDATE_PRODUCT from "../../../../gql/mutations/UPDATE_PRODUCT";
import {Query, withApollo} from "react-apollo";
import {updateObjectValues} from "./../../../../utils";
import GET_FIELDS_VALUE from "../../../../gql/queries/GET_FIELDS_VALUE";
import generateQuery from "../../../../gql/queries/generateQuery";
import GET_PRODUCT_GROUPS from "../../../../gql/queries/GET_PRODUCT_GROUPS";
import {withRouter} from 'react-router-dom';

const {Item} = Form;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

const initialState = {
    isValidating: false,
            brand: {
                value: 'V',
                validateStatus: 'success',
            },
            name: {
                value: '',
                validateStatus: null,
                help: null,
            },
            model: {
                value: '',
                validateStatus: null,
                help: null,
            },
            productGroup: {
                value: null,
                validateStatus: null,
                help: null,
            },
            catalogueNumber: {
                value: '',
                validateStatus: null,
                help: null,
            },
            ean: {
                value: '',
                validateStatus: null,
                help: null,
            },
            category: {
                value: [],
                name: '',
                fullPath: '',
                validateStatus: null,
                help: null,
                isLeaf: null,
            }
};

const cookie = new Cookies();

class BasicData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: !!cookie.get('currentProductId') || !!this.props.id,
            currentProductId: this.props.id || cookie.get('currentProductId'),
            ...initialState,
        }
    }

    componentDidMount() {
        this.props.onRef(this);
        if (this.state.isEdit) {
            this.props.client.query({
                query: GET_FIELDS_VALUE,
                variables: {id: this.state.currentProductId},
            }).then(({data}) => {
                const {
                    brand,
                    name,
                    productGroup,
                    model,
                    catalogueNumber,
                    ean,
                    category,
                    categoryFullPath
                } = data.general.products.edges[0].node;
                const valuesObj = {brand, name, catalogueNumber, ean, model, productGroup};
                const newState = {...this.state};
                (Object.keys(valuesObj)).forEach(v => {
                    newState[v] = updateObjectValues(this.state[v], {value: valuesObj[v]});
                });
                newState.category = updateObjectValues(this.state.category, {
                    name: category.name,
                    value: [category.id],
                    fullPath: categoryFullPath,
                    isLeaf: true,
                });
                this.setState({...newState});
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if( nextProps.id !== this.props.id){
            this.setState({currentProductId: nextProps.id,
                isEdit: !!cookie.get('currentProductId') || !!this.props.id})
        }
        if (this.props.id && nextProps.location.action === 'redirect') {
            this.props.clearCurrentId();
            this.setState({
                isEdit: false,
                ...initialState,
            })
        }
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    nextAction = async () => {
        const {client} = this.props;
        const {brand, name, catalogueNumber, ean, category, model, productGroup} = this.state;
        this.toggleValidating();

        const asyncValidate = async () => {
            const eanValidation = await this.asyncValidation(ean.value, 'ean');
            const catalogueNumberValidation = await this.asyncValidation(catalogueNumber.value, 'catalogueNumber');
            return eanValidation && catalogueNumberValidation
        };

        const syncValidateResult = this.validateName('name');
        const asyncValidateResult = await asyncValidate();
        const categoryValidateResult = () => {
            if (this.state.isEdit) return true;
            return category.validateStatus === 'success';
        };
        const validated = asyncValidateResult && syncValidateResult && categoryValidateResult;

        const input = {
            brand: brand.value,
            category: category.value && (Array.from(category.value).reverse())[0],
            name: name.value,
            model: model.value,
            productGroup: productGroup.value?productGroup.value:null,
            catalogueNumber: catalogueNumber.value,
            ean: ean.value,
        };

        if (validated && !this.state.isEdit) {
            return client.mutate({
                mutation: GET_PRODUCT_ID,
                variables: {input},
            }).then(({data:{createProductMutation}}) => {
                cookie.set('currentProductId', createProductMutation.id, {path: '/'});
                this.setState({
                    isEdit: !!cookie.get('currentProductId') || !!this.props.id,
                    currentProductId: cookie.get('currentProductId')
                });
                return true;
            });
        } else if (validated && this.state.isEdit) {
            return client.mutate({
                mutation: UPDATE_PRODUCT,
                variables: {
                    input: {
                        ...input,
                        id: this.state.currentProductId,
                    }
                },
            }).then(() => true)
        } else {
            return false;
        }
    };

    toggleValidating = () => {
        this.setState({
            isValidating: !this.state.isValidating
        });
    };

    validateName = (field) => {
        const {value} = this.state[field];
        const error = {validateStatus: 'error', help: this.props.t('validationErr-name')};
        const success = {validateStatus: 'success', help: null};
        if (!value) {
            this.setState({[field]: updateObjectValues(this.state[field], error)});
            return false
        }
        if (value.length > 60 ){
            error.help = this.props.t('validationErr-name-length');
            this.setState({[field]: updateObjectValues(this.state[field], error)});
            return false
        }
        this.setState({[field]: updateObjectValues(this.state[field], success)});
        return true;
    };

    handleBlur = (e) => {
        this.validateName(e.target.name);
    };

    handleBlurAsync = (e) => {
        const {name} = e.target;
        const {value} = this.state[name];
        this.asyncValidation(value, name);
    };

    asyncValidation = async (value, name) => {
        const {client} = this.props;
        const stateObj = {...this.state[name]};
        if (!value) {
            stateObj.help = this.props.t('general-err');
            stateObj.validateStatus = 'error';
        } else {
            stateObj.validateStatus = 'validating';
            this.setState({[name]: stateObj});
            const {data} = await client.query({
                query: generateQuery(name),
                variables: {number: value}
            });
            const {totalCount} = data.general.products;
            const existingId = totalCount && data.general.products.edges[0].node.id;
            const isTheSame = existingId === this.state.currentProductId;
            if (totalCount && !isTheSame) {
                stateObj.help = this.props.t('unique');
                stateObj.validateStatus = 'error';
            } else {
                stateObj.validateStatus = 'success';
                stateObj.help = '';
            }
            this.setState({[name]: stateObj});
            return stateObj.validateStatus === 'success';
        }
        this.setState({[name]: stateObj});
        return false
    };

    updateTextField = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: updateObjectValues(this.state[name], {value})})
    };

    updateProductGroupField = (value) => {
        this.setState({productGroup: updateObjectValues(this.state.productGroup, {value})})
    };

    updateBrandValue = (value) => this.setState(
        {
            brand: {value},
            category: {
                value: [],
                name: '',
                fullPath: '',
                validateStatus: null,
                help: null,
                isLeaf: null,
            }
        }
    );

    updateCategoryData = (data) => {
        const category = updateObjectValues(this.state.category, {...data});
        this.setState({category})
    };

    chooseCategoryFeedback = (feedback) => {
        const category = updateObjectValues(this.state.category, {...feedback});
        this.setState({category});
    };

    render() {

        const {ean, catalogueNumber, brand, category, name, model, productGroup} = this.state;
        const {t} = this.props;

        return (
            <Form {...formItemLayout} hideRequiredMark style={{minWidth: '950px'}}>
                <Card title={t('addProductHeader')}>
                    <Item label={t('brand')}>
                        <Select name='brand' onChange={this.updateBrandValue} value={brand.value}>
                            <Select.Option value='V'>Vaillant</Select.Option>
                            <Select.Option value='S'>Saunier Duval</Select.Option>
                        </Select>
                    </Item>
                    <Item
                        label={t('category')}
                        help={category.help}
                        validateStatus={category.validateStatus}
                    >
                        <ChooseCategory
                            brand={brand.value}
                            validate={this.state.isValidating}
                            toggleValidating={this.toggleValidating}
                            feedback={this.chooseCategoryFeedback}
                            updateCategoryData={this.updateCategoryData}
                            value={category.value}
                            fullPath={category.fullPath}
                            name={category.name}
                            validateStatus={category.validateStatus}
                            isLeaf={category.isLeaf}
                            isPromotion={false}
                        />
                    </Item>
                    <Item
                        label={t('name') + `:`}
                        hasFeedback={!!name.validateStatus}
                        validateStatus={name.validateStatus}
                        help={name.help}
                    >
                        <Input
                            name='name'
                            placeholder={t('name')}
                            onBlur={this.handleBlur}
                            onChange={this.updateTextField}
                            value={name.value}
                        />
                    </Item>
                    <Item
                        label={t('model') + `:`}
                        // hasFeedback={!!model.validateStatus}
                        // validateStatus={model.validateStatus}
                        // help={model.help}
                    >
                        <Input
                            name='model'
                            placeholder={t('model')}
                            onBlur={this.handleBlur}
                            onChange={this.updateTextField}
                            value={model.value}
                        />
                    </Item>
                    <Item
                        label={t('catalogueNumber') + `:`}
                        hasFeedback={!!catalogueNumber.validateStatus}
                        validateStatus={catalogueNumber.validateStatus}
                        help={catalogueNumber.help}
                    >
                        <Input
                            onBlur={this.handleBlurAsync}
                            name="catalogueNumber"
                            placeholder={t('catalogueNumber')}
                            value={catalogueNumber.value}
                            onChange={this.updateTextField}
                        />
                    </Item>
                    <Item
                        label={t('ean') + `:`}
                        hasFeedback={!!ean.validateStatus}
                        validateStatus={ean.validateStatus}
                        help={ean.help}
                    >
                        <Input
                            onBlur={this.handleBlurAsync}
                            name="ean"
                            placeholder={t('ean')}
                            value={ean.value}
                            onChange={this.updateTextField}
                        />
                    </Item>
                    <Item
                        label={t('productGroup') + `:`}
                        // hasFeedback={!!model.validateStatus}
                        // validateStatus={model.validateStatus}
                        // help={productGroup.help}
                    >
                        <Query query={GET_PRODUCT_GROUPS}>
                            {({data, loading, error}) => {
                                return <Select
                                    name='productGroup'
                                    allowClear={true}
                                    placeholder={t('productGroup')}
                                    onChange={this.updateProductGroupField}
                                    value={productGroup.value?productGroup.value:undefined}
                                >
                                    {!loading && !error && data.general.productGroups.map((obj, idx) => {
                                        return <Select.Option value={obj[0]} key={idx}>
                                            {obj[1]}
                                        </Select.Option>
                                    })}
                                </Select>
                            }
                            }
                        </Query>
                    </Item>
                </Card>
            </Form>
        );
    }
}

const BasicDataForm = Form.create()(BasicData);
const BasicDataApollo = withApollo(BasicDataForm);
const BasicDataTranslated = withTranslation('addProduct')(BasicDataApollo);

export default withRouter(BasicDataTranslated);
