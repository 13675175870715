import gql from 'graphql-tag';

const DELETE_CATEGORY_IMAGE_MUTATION = gql`
mutation deleteCategoryImageMutation($input: DeleteCategoryImageMutationInput!) {
  deleteCategoryImageMutation(input: $input) {
    ok
    clientMutationId
  }
}
`;

export default DELETE_CATEGORY_IMAGE_MUTATION;
