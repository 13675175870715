import PRODUCTS_TABLE_QUERY from "../queries/PRODUCTS_TABLE_QUERY";
import GET_PRODUCT_STATUSES from "../queries/GET_PRODUCT_STATUSES";


const updateProductList = async (client, idsList, variables, toStatus) => {

    const cacheData = client.cache.readQuery(
        {
            query: PRODUCTS_TABLE_QUERY,
            variables
        }
    );
    const {data: {general: {productStatuses}}} = await client.query({query: GET_PRODUCT_STATUSES});
    if (cacheData) {
        const products = cacheData.general.products;
        const toStatusName = productStatuses.find((status)=>status[0]===toStatus)[1];
        products.edges = products.edges.map(
            (product) => {
                if (idsList.indexOf(product.node.id) !== -1) {
                    product.node.status = toStatus;
                    product.node.statusName = toStatusName
                }
                return product
            }
        );
        cacheData.general.products = products;
        client.writeQuery({
            query: PRODUCTS_TABLE_QUERY,
            variables,
            data: cacheData
        });
        if (variables.status) {
            let productsRemoved = [];
            products.edges = products.edges.filter(edge => {
                if (idsList.indexOf(edge.node.id) !== -1) {
                    productsRemoved.push(edge);
                }
                return idsList.indexOf(edge.node.id) === -1
            });
            products.totalCount -= productsRemoved.length;
            cacheData.general.products = products;
            client.writeQuery({
                query: PRODUCTS_TABLE_QUERY,
                variables,
                data: cacheData
            });

            if (products.totalCount > products.edges.length && products.edges.length < variables.pageSize) {
                client.query({
                    query: PRODUCTS_TABLE_QUERY,
                    variables,
                    data: cacheData,
                    fetchPolicy: 'network-only'
                })
            }

            const toCachedData = client.cache.readQuery(
                {
                    query: PRODUCTS_TABLE_QUERY,
                    variables: {
                        ...variables,
                        status: toStatus
                    }
                }
            );

            if (toCachedData && productsRemoved) {
                const products = toCachedData.general.products;
                let index = 0;
                while (products.edges.length < variables.pageSize && productsRemoved.length > index) {
                    products.edges.push(productsRemoved[index]);
                    index++
                }
                products.totalCount += productsRemoved.length;
                toCachedData.general.products = products;
                client.writeQuery({
                    query: PRODUCTS_TABLE_QUERY,
                    variables: {
                        ...variables,
                        status: toStatus
                    },
                    data: toCachedData
                });
            }
        }

    }
};

export default updateProductList