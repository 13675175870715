import styled from "styled-components";
import {Button} from "antd";

const StyledButton = styled(Button)`
    &.ant-btn {
      border: 1px solid ${({theme}) => theme.color.green};
      color: ${({theme}) => theme.color.green};
      margin-left: 20px;
      &:hover {
        color: ${({theme}) => theme.color.white};
        background-color: ${({theme}) => theme.color.green};
        border: 1px solid ${({theme}) => theme.color.green};
      }
    }
`;

export default StyledButton;