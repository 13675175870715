import React, {Component} from 'react';
import moment from 'moment';
import {Button, Dropdown, Menu, message, Popconfirm, Table} from 'antd';
import _ from 'lodash';
import EMPLOYEES_TABLE_QUERY from "../../gql/queries/EMPLOYEES_TABLE_QUERY";
import {Query, withApollo} from "react-apollo";
import {withTranslation} from "react-i18next";
import REMOVE_USER_MUTATION from "../../gql/mutations/REMOVE_USER_MUTATION";
import removeUserList from "../../gql/updaters/removeUserList";
import client from "../../config/apollo/client";
import SEND_PASSWORD_RESET_MAIL from "../../gql/mutations/SEND_PASSWORD_RESET_MAIL";


class EmployeesTable extends Component {
    constructor(props) {
        super(props);
        this.pageSize = 9;
        this.state = {
            currentPage: 1,
            role: this.props.role,
            isActive: this.props.isActive,
            sortColumn: [],
            loading: true,
            searchState: this.props.search,
            activeDropdown: null,
            showResetModal: false,
        };
        const {t} = this.props;
        this.columns = [
            {
                title: t('nameSurname'),
                dataIndex: 'displayName',
                sortName: ['display_name'],
                key: 'displayName',
                sorter: true,
            },
            {
                title: t('emailLabel'),
                dataIndex: 'email',
                sortName: ['email'],
                key: 'email',
                sorter: true,
            },
            {
                title: t('phoneLabel'),
                dataIndex: 'phoneNumber',
                sortName: ['phone_number'],
                key: 'phoneNumber',
                sorter: true
            },
            {
                title: t('roleLabel'),
                dataIndex: 'role',
                key: 'role',
                sortName: ['role'],
                sorter: true,
            },
            {
                title: t('lastActivityLabel'),
                dataIndex: 'lastActivity',
                key: 'lastActivity',
                sortName: ['last_activity'],
                sorter: true,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sortName: ["is_active"],
                key: 'status',
                sorter: true,
            },
            {
                dataIndex: 'more',
                key: 'more',
                render: (text, record, index) => {
                    const {activeDropdown} = this.state;
                    const {t} = this.props;
                    return <Dropdown
                        onClick={() => {
                            if (record.key === activeDropdown)
                                this.setState({activeDropdown: null});
                            else
                                this.setState({activeDropdown: record.key})
                        }}
                        trigger={['click']}
                        onVisibleChange={(visible) => !visible && record.key === activeDropdown && this.setState({activeDropdown: null})}
                        visible={activeDropdown === record.key}
                        overlay={<Menu>
                            {[
                                <Menu.Item onClick={() => this.onEditButton(record)} key={1}>
                                    {t('editProfile')}
                                </Menu.Item>,
                                <Menu.Item onClick={() => {
                                    this.props.toggleEmployeeActivitiesModal(record.key);
                                    this.setState({activeDropdown: null})
                                }
                                } key={2}>
                                    {t('activityHistory')}
                                </Menu.Item>,
                                this.renderConfirmableButton(
                                    t('removeEmployee'),
                                    t('confirm-delete'),
                                    () => this.removeEmployee(record.key), 0
                                ),

                                  this.renderConfirmableButton(
                                    t('resetPassword'),
                                    `${t('confirmPasswordReset')} ${record.displayName}?`,
                                    () => this.resetPassword(record.email), 0
                                )

                            ]}
                        </Menu>}><Button>{t('more')}</Button></Dropdown>
                },
            }
        ];
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.role !== nextProps.role || this.props.isActive !== nextProps.isActive)
            this.setState({currentPage: 1});
    }

    renderConfirmableButton = (text, confirmText, action, key) => {
        const {t} = this.props;
        return <Menu.Item key={text}>
            <Popconfirm
                style={{width: '100%', height: '100%'}}
                placement="left"
                title={confirmText}
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={action}
                onCancel={() => {
                    this.setState({activeDropdown: null});
                }}
            >
                <div style={{width: '100%', height: '100%'}}>{text}</div>
            </Popconfirm>
        </Menu.Item>
    };

    onEditButton = (record) => {
        this.setState({activeDropdown: null});
        this.props.setModalProps({
            id: record.key,
            firstName: record.firstName,
            lastName: record.lastName,
            email: record.email,
            phoneNumber: record.phoneNumber,
            taxId: record.taxId,
            companyName: record.companyName,
            role: record.roleValue,
            vaillant: record.vaillant,
            saunierDuval: record.saunierDuval,
        })
    };

    resetPassword = (record) => {
        if (record) {
             client.mutate({
               mutation: SEND_PASSWORD_RESET_MAIL,
               variables: {
                 input: {
                   userEmail: record
               }}
             }).then((res) => {
               if (res.data.sendPasswordResetMailMutation.ok) {
                 message.success(this.props.t('resetEmailSend'), 10);
               }
             }).catch((err) => message.error(this.props.t('saveFail'), 10));
           }
        }



    onTableChange = (pagination, filters, sorter) => {
        if (pagination.current !== this.state.currentPage) {
            this.setState({currentPage: pagination.current})
        }
        if (_.isEmpty(sorter) && this.state.sortColumn.length > 0) {
            this.setState({
                sortColumn: [],
                currentPage: 1
            });
        } else if (!_.isEmpty(sorter)) {
            let sortColumns = sorter.column.sortName;
            if (sorter.order === "descend") {
                sortColumns = sortColumns.map((value) => '-' + value)
            }
            if (!_.isEqual(sortColumns, this.state.sortColumn)) {
                this.setState({
                    sortColumn: sortColumns,
                    currentPage: 1
                });
            }
        }

    };

    removeEmployee = (id) => {
        const {client} = this.props;
        client.mutate({
            mutation: REMOVE_USER_MUTATION,
            variables: {id}
        }).then(res => {
            removeUserList(client, id, {
                pageSize: this.pageSize,
                offset: (this.state.currentPage - 1) * this.pageSize,
                sortColumn: this.state.sortColumn,
                search: this.props.search,
                isActive: this.props.isActive,
                role: this.props.role,
            }).then(() => this.setState({activeDropdown: null}))
        })
    };

    getEmployeesData = (data) => {
        if (!data.management) return null;
        const {t} = this.props;
        return data.management.users.edges.map(employee => {
            employee = employee.node;
            return {
                key: employee.id,
                displayName: employee.displayName,
                firstName: employee.firstName,
                lastName: employee.lastName,
                taxId: employee.taxId,
                companyName: employee.companyName,
                roleValue: employee.role,
                vaillant: employee.vaillant,
                saunierDuval: employee.saunierDuval,
                email: employee.email,
                phoneNumber: employee.phoneNumber,
                role: employee.roleDisplay,
                lastActivityBool: !!employee.lastActivity,
                lastActivity: employee.lastActivity ?
                    moment(employee.lastActivity).format('YYYY-MM-DD')
                    :
                    t('none'),
                status: employee.isActive ? t('active') : t("inactive"),
            };
        });
    };

    render() {
        return (
            <>
            <Query query={EMPLOYEES_TABLE_QUERY}
                   fetchPolicy={'cache-and-network'}
                   variables={{
                       pageSize: this.pageSize,
                       offset: (this.state.currentPage - 1) * this.pageSize,
                       sortColumn: this.state.sortColumn,
                       search: this.props.search,
                       isActive: this.props.isActive,
                       role: this.props.role,
                   }}>
                {({loading, error, data}) => {
                    let totalRecords = 0;
                    if (!loading)
                        totalRecords = data.management.users.totalCount;
                    return (
                        <Table
                            columns={this.columns}
                            rowSelection={{columnWidth: '3%'}}
                            loading={loading}
                            onChange={this.onTableChange}
                            pagination={
                                {
                                    pageSize: this.pageSize,
                                    total: totalRecords,
                                    current: this.state.currentPage
                                }
                            }
                            dataSource={this.getEmployeesData(data)}
                        />
                    )
                }}
            </Query>
        </>
        )
    }
}

export default withApollo(withTranslation('employees')(EmployeesTable));