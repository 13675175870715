import React from "react";

import Layout from "../../Layout/Layout";

import Categories from "../../../components/Categories";
import { StyledTitle } from "../../../components/_styledComponents";

import { withTranslation } from "react-i18next";

const CategoriesTableView = ({ t }) => {
  return (
    <Layout>
      <StyledTitle>{t("categories")}</StyledTitle>
      <Categories />
    </Layout>
  );
};

const CategoriesView = withTranslation("categoriesAndSubcategories")(
  CategoriesTableView
);

export default CategoriesView;
