import React from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from "react-i18next";

const EditProduct = ({id, t}) => {
  return (
    <Link to={`/add-product/step=0&id=${id}`}>
      {t('edit-product')}
    </Link>
  )
};
const EditProductLink = withTranslation('products')(EditProduct);
export default EditProductLink;