import React, {useState} from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import LayoutCenter from "../../../containers/LayoutCenter";
import PasswordResetForm from '../../../components/PasswordResetForm';
import FormContainer from "../../../containers/FormContainer";
import {Query} from "react-apollo";
import PASSWORD_RESET_TOKEN_VALID_QUERY from "../../../gql/queries/PASSWORD_RESET_TOKEN_VALID_QUERY";
import {withTranslation} from 'react-i18next';

const PasswordReset = ({match, t}) => {
  const [redirect, setRedirect] = useState(false);
  const url_token = match.params.token;

  if (redirect) return <Redirect to='/login'/>;

  return (
    <Query query={PASSWORD_RESET_TOKEN_VALID_QUERY}
           variables={{token:url_token}}
    >
      {({loading, data}) => {
        if (!loading) {
          if (data.general.valid) {
            return (
              <LayoutCenter>
                <FormContainer>
                  <PasswordResetForm redirect={setRedirect}
                                     token={match.params.token}
                  />
                </FormContainer>
              </LayoutCenter>
            );
          }
          return (
            <LayoutCenter>
              <div>{t('token-invalid')}</div>
            </LayoutCenter>
          )
        }
        return (
          <LayoutCenter>
            <div>{t('validating-token')}</div>
          </LayoutCenter>
        )
      }}
    </Query>
  )
};

PasswordReset.defaultProps = {};
PasswordReset.propTypes = {};

const TranslatedComponent = withTranslation('passwordReset')(PasswordReset);
export default withRouter(TranslatedComponent);