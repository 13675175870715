import PRICE_IMPORT_HISTORY from "../queries/PRICE_IMPORT_QUERY";


const updatePriceImport = async (client, priceImport) => {

    const cacheData = client.cache.readQuery(
        {
            query: PRICE_IMPORT_HISTORY,
        }
    );
    if (cacheData) {
        const priceImports = cacheData.general.priceImports;
        priceImports.edges.unshift({node:priceImport, __typename: 'PriceImportTypeEdge'});
        priceImports.totalCount += 1;
        cacheData.general.priceImports = priceImports;
        client.writeQuery({
            query: PRICE_IMPORT_HISTORY,
            data: cacheData
        });

    }
};

export default updatePriceImport