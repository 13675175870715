import gql from 'graphql-tag';

const GET_MAIN_PHOTO = gql`
    query documentsForProduct($id: ID) {
      general {
        products(id: $id) {
          edges {
            node {
              id
              attachments(type: "P", first: 1 ) {
                  edges {
                    node {
                      file
                    }
                  }
              }
            }
          }
        }
      }
    }
`;

export default GET_MAIN_PHOTO;