import React, {Component} from 'react';
import {Form, Input, Modal, Radio, Select, message} from "antd";
import client from "../../config/apollo/client";
import USER_CONSTS_QUERY from "../../gql/queries/USER_CONSTS_QUERY";
import {Mutation} from "react-apollo";
import ADD_USER from "../../gql/mutations/ADD_USER";
import UPDATE_USER from "../../gql/mutations/UPDATE_USER";
import {withTranslation} from "react-i18next";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 9},
    },
    wrapperCol: {
        xs: {span: 16},
        sm: {span: 8},
    },
};

class EmployeeModalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rolesOptions: [],
            id: null,
            fields: {
                vaillant: true,
                saunierDuval: true,
            }
        }
    }

    componentWillMount() {
        this.getConsts();
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.modalProps !== nextProps.modalProps) {
            const modalProps = nextProps.modalProps;
            const updateFields = () => this.props.form.setFieldsValue({
                firstName: modalProps.firstName,
                lastName: modalProps.lastName,
                email: modalProps.email,
                taxId: modalProps.taxId,
                phoneNumber: modalProps.phoneNumber,
                companyName: modalProps.companyName,
                role: modalProps.role,
            });
            this.setState({
                id: modalProps.id,
                fields: {
                    ...modalProps
                }
            }, updateFields())
        }
    }


    async getConsts() {
        const res = await client
            .query({
                query: USER_CONSTS_QUERY,
            });
        const management = res.data.management;
        this.setState({
            rolesOptions: management.userRoles,
        })
    }

    toggleModal = () => {
        let fields = this.state.fields;
        fields.vaillant = true;
        fields.saunierDuval = true;
        this.setState({fields});
        this.props.setShowModal(false);
        this.props.form.resetFields();
    };

    getMutation = () => {
        if (this.state.id) {
            return UPDATE_USER
        }
        return ADD_USER
    };

    render() {
        const {t} = this.props;
        const {getFieldDecorator} = this.props.form;

        return <Mutation mutation={this.getMutation()}
                         refetchQueries={["EmployeesTable"]}
        >
            {(mutation, {data}) => (
                <Modal onOk={e => {
                    e.preventDefault();
                    this.props.form.validateFields((err, fields) => {
                        if (!err) {
                            const variables = {
                                input: {
                                    ...this.state.fields,
                                    username: fields.email,
                                    firstName:  fields.firstName,
                                    lastName: fields.lastName,
                                    email: fields.email,
                                    role: fields.role,
                                    taxId: fields.taxId,
                                    phoneNumber: fields.phoneNumber,
                                    companyName: fields.companyName,
                                }
                            };
                            if (this.state.id) {
                                variables.id = this.state.id;
                            }
                            mutation({
                                variables
                            }).then(res => {
                                const result = res.data.createUserMutation || res.data.updateUserMutation;
                                if (!result.errors) {
                                    message.success(t('saveSuccess'));
                                    this.toggleModal();
                                }
                                else message.error(t('saveFail'))
                            });
                        }
                    });
                }}
                       width={750}
                       title={this.state.id ? t("editProfile") : t("addEmployee")}
                       visible={this.props.showModal}
                       okText={t('save')}
                       onCancel={this.toggleModal}>
                    <Form {...formItemLayout}>
                        <Form.Item
                            label={t("nameLabel")}
                        >
                            {getFieldDecorator('firstName', {
                                rules: [{required: true, message: t('firstNameError')}],
                            })(
                                <Input name={'firstName'}/>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={t("lastNameLabel")}
                        >
                            {getFieldDecorator('lastName', {
                                rules: [{required: true, message: t('lastNameError')}],
                            })(
                                <Input name={'lastName'}/>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={t("emailLabel")}
                        >
                            {getFieldDecorator('email', {
                                rules: [{required: true, message: t('emailError')}],
                            })(
                                <Input name={'email'} type='email'/>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={t("phoneLabel")}
                        >
                            {getFieldDecorator('phoneNumber')(
                                <Input name={'phoneNumber'}/>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={t("companyLabel")}
                        >
                            {getFieldDecorator('companyName')(
                                <Input name={'companyName'}/>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={t("taxIdLabel")}
                        >
                            {getFieldDecorator('taxId')(
                                <Input name={'taxId'}/>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={t("roleLabel")}
                        >
                            {getFieldDecorator('role', {
                                rules: [{required: true, message: t('roleError')}]
                            })(
                                <Select size={"default"} style={{width: '100%'}} name={'role'}>
                                    {this.state.rolesOptions.map((role, index) => {
                                        return <Select.Option key={index} value={role[0]}>{role[1]}</Select.Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label={t("brandsAccessLabel")}>
                            <Radio.Button
                                name={'vaillant'}
                                checked={this.state.fields.vaillant}
                                onClick={() => {
                                    let fields = this.state.fields;
                                    fields.vaillant = !fields.vaillant;
                                    this.setState({fields})
                                }}
                            >
                                Vaillant
                            </Radio.Button>
                            <Radio.Button
                                name={'saunierDuval'}
                                checked={this.state.fields.saunierDuval}
                                onClick={() => {
                                    let fields = this.state.fields;
                                    fields.saunierDuval = !fields.saunierDuval;
                                    this.setState({fields})
                                }}
                            >
                                Saunier Duval
                            </Radio.Button>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </Mutation>
    }
}

const EmployeeModal = Form.create()(EmployeeModalForm);


export default withTranslation('employees')(EmployeeModal);