import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { en, pl } from './translations'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      pl,
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["login"],
    defaultNS: "common",

    keySeparator: false, // we use content as keys

    interpolation: {
      format: function(value, format, lng) {
            if (format === 'uppercase') return value.toUpperCase();
            return value;
        }
    }
  });

export default i18n;
