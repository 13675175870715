import gql from 'graphql-tag';

const GET_PRODUCT_INFO = gql`
    query getProductInfo($id: ID){
      general{
        products(id: $id){
          edges{
            node{
              id
              name
              catalogueNumber
              ean
              category {
                id
              }
              brand
              noPrice
              noPriceComment
            }
          }
        }
      }
    }
  `;

export default GET_PRODUCT_INFO;