import gql from 'graphql-tag';

const GET_PRODUCT_GROUPS = gql`
    query productGroups{
      general{
        productGroups
      }
    }
`;

export default GET_PRODUCT_GROUPS;