import gql from 'graphql-tag';

const GET_PRODUCT_KIT_TYPES = gql`
query producKitTypes{
  general{
    productKitTypes
  }
}
`;

export default GET_PRODUCT_KIT_TYPES;