import styled from 'styled-components';
import {Tabs} from "antd";

//Styled Tabs

export default styled(Tabs)`
  .ant-tabs-ink-bar {
    background-color: ${({theme}) => theme.color.green};
  }

  div.ant-tabs-tab.ant-tabs-tab-active {
    color: ${({theme}) => theme.color.green};
    border-bottom: ${({theme}) => theme.color.green};
  }

  div.ant-tabs-tab:hover {
    color: ${({theme}) => theme.color.green}
  }
`;



