import gql from 'graphql-tag';

const REMOVE_FILE_MUTATION = gql`
	mutation deleteAttachmentMutation($id: ID) {
    deleteAttachmentMutation(id: $id) {
      success
      errors {
        field 
        messages
      }
    }
  }
`;

export default REMOVE_FILE_MUTATION;