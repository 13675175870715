import React from 'react';
import {Form, Select} from "antd";
import {withTranslation} from "react-i18next";
const { Option } = Select;


const  MultipleSelect = ({id,choices, getFieldDecorator, initialValue, t, formItemVariables}) => {

  return (
    <Form.Item {...formItemVariables}>
      {getFieldDecorator(id, {
              initialValue: initialValue === null ? [] : initialValue
            })(
         <Select
          mode="multiple"
          style={{width: '100%'}}
          placeholder={t('placeholder')}
          choices={choices}
          initialValue={initialValue}
        >
           {choices.map((choice, index) => <Option key={index} value={choice}>{choice}</Option>)}
        </Select>
      )}
    </Form.Item>
  )
};

export default withTranslation('addProduct')(MultipleSelect);