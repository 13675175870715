import gql from 'graphql-tag';

const GET_PRICES = gql`
    query existingPrices($id: ID){
      general {
        products(id: $id) {
          edges {
            node {
              id
              prices {
                edges {
                  node {
                    dateFrom
                    dateTo
                    value
                    description
                    promotion
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
`;

export default GET_PRICES;