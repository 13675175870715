import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import ProductFilterContext from "./ProductFilterContext";


const reducer = ( state, update) => {
    if (update.clear){
      return {
            currentPage: 1,
            sortColumn: [],
            minPriceFilter: 0,
            maxPriceFilter: 0,
            brandFilter: null,
            categoryFilter: null,
            paramFilters: [],
            search: '',
            activeTab: ''
        }
    }
    update.changes.forEach((change)=>state[change.field] = change.value);
    return state
};

const ProductFilterProvider = ({children}) => {
    const [filtersContext, setFiltersContext] = useReducer(
        reducer,
        {
            currentPage: 1,
            sortColumn: [],
            minPriceFilter: 0,
            maxPriceFilter: 0,
            brandFilter: null,
            categoryFilter: null,
            paramFilters: [],
            search: '',
            activeTab: ''
        },

    );

    return (
        <ProductFilterContext.Provider value={{filtersContext, setFiltersContext}}>
            {children}
        </ProductFilterContext.Provider>
    );
};


ProductFilterProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ProductFilterProvider;