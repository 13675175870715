import React from 'react';
import PropTypes from 'prop-types';
import Layout from "../../Layout/Layout";
import ProductsTabs from "../../../components/ProductsTabs";
import {Button} from "antd";
import {Link} from "react-router-dom"
import Cookies from "universal-cookie";
import {withTranslation} from "react-i18next";
import {StyledTitle} from '../../../components/_styledComponents'

const cookies = new Cookies();
const ProductsView = ({location, t}) => {


    const removeCookie = () => {
        const productCookie = cookies.get('currentProductId');
        if (productCookie) {
            cookies.remove('currentProductId',  { path: '/' });
        }
    };

    return <Layout>
        <StyledTitle>{t('products')}</StyledTitle>
        <Link  to='/add-product/step=0' onClick={removeCookie}><Button>{t('add-product')}</Button></Link>
        <ProductsTabs navTab={location.state ? location.state.tab : 'before'}/>
    </Layout>
};

ProductsView.propTypes = {
    location: PropTypes.shape({
        state:PropTypes.shape({
          tab: PropTypes.string
        })
    })
};

const Products = withTranslation('products')(ProductsView);
export default Products;