export default {
  "basicData": "Basic data",
  "technicalData": "Technical parameters",
  "logisticData": "Logistic parameters",
  "files": 'Documents',
  "accessories": "Product kit",
  "price": 'Price',
  "save": "Save",
  "next": "Next",
  "prev": "Previous",
  "done": "Done",
  "process-msg": "Processing complete!",
}