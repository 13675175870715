import React, {Component} from 'react';
import Cookies from "universal-cookie";
import {Query} from "react-apollo";
import GET_PRODUCT_KIT_TYPES from "../../../../gql/queries/GET_PRODUCT_KIT_TYPES";
import ProductKitGroup from "./ProductKitGroup/ProductKitGroup";
import {Spin} from "antd";

const cookie = new Cookies();

class ProductKit extends Component {
    constructor(props) {
        super(props);
        this.currentProductId = this.props.id || cookie.get('currentProductId');
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    nextAction = async () => {
        return true;
    };

    render() {

        return (
            <>
                <Query query={GET_PRODUCT_KIT_TYPES}>
                    {({data, loading, errors}) => {
                       	if (loading) return <div style={{height: '500px'}}><Spin/></div>
                        return !loading && data && data.general.productKitTypes.map((type, index) =>
                            <ProductKitGroup id={this.currentProductId} key={index} type={type[0]} name={type[1]}/>)
                    }
                    }
                </Query>
            </>
        )
    }

}

// const ProductKitForm = Form.create()(ProductKit);

export default ProductKit;