import gql from 'graphql-tag';

const BRAND_ROOT_CATEGORY_QUERY = gql`query RootCategory($brand: String!) {
    general{
      categories(brand: $brand, parent_Isnull: true) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
}
`;

export default BRAND_ROOT_CATEGORY_QUERY;