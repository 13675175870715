import gql from 'graphql-tag';

const MAIN_CATEGORY_QUERY = gql`query MainCategory ($brand: String) {
    general {
        categories(isMain: true, brand: $brand) {
            edges{
                node{
                    parent {
                        name
                        id
                        pathToRoot {
                          displayName
                          name
                          id
                        }
                      }
                    id
                    name
                    image
                    displayName
                    isMain
                    pathToRoot{
                        name
                        displayName
                        id
                    }
                    children{
                        totalCount
                    }
                }
            }
        }
        
    }
}
`;

export default MAIN_CATEGORY_QUERY;