export default {
    'importFile': 'Import file',
    'importedFiles': 'Imported files',
    'importHeadersText1': 'Every file must have those headers:',
    'importHeadersText2': 'Invalid rows can be skipped.',
    'additionalField': '(field not required)',
    'cancel': 'Cancel',
    'importing': 'Importing',
    'retryImport': 'Retry import',
    'skipBadRows': 'Skip invalid rows',
    'startImport': 'Begin import',
    'importText': 'Import:',
    'selectFile': ' Choose a file',
    'importErrors': 'Import errors',
    'field': 'Field',
    'error': 'Error',
    "priceImport": "Price import",
    "productImport": "Product import",
    'rowsErrors': 'Rows errors',
    'downloadImportFile': "Download template",
    'row': 'Row',
    'column': 'Column',
    'fileCardComponentText': 'Added on {{formattedDate}} {{uploaderText}}',
    'fileCardComponentTextBy': 'by {{name}}',
    'promotionInfo': 'To mark the price as promotional, add a column with the header "promotion" in the file. Then, in each row for the promoted price, enter "yes"',
}