import gql from 'graphql-tag';

const CREATE_PROMOTION = gql`
   mutation  createPromotionMutation($input: CreatePromotionMutationInput!){
  createPromotionMutation(input: $input) {
    id
    clientMutationId
    errors {
      messages
    }
  }
}
`;

export default CREATE_PROMOTION;