import gql from 'graphql-tag';

const ADD_PROMOTED_PRODUCTS = gql`
    mutation updateProductsPromotedStatusMutation($input: UpdateProductsPromotedStatusMutationInput!) {
        updateProductsPromotedStatusMutation(input: $input) {
            ok
            errors {
                field
                messages
            }
        }
    }
`;

export default ADD_PROMOTED_PRODUCTS;