import React from 'react';
import PropTypes from 'prop-types';
import {InputTypeText, ChoicesSelect, InputNumberUnit, MultipleSelect, RadioButton, RangeInput} from "../ParametersTypeComponents";
import InputTypeTextLong from "../ParametersTypeComponents/InputTypeTextLong";


const ParameterType = ({children, type, name, choices, unit, id, form, initialValue, textLength, decimalPlaces}) => {

    const {getFieldDecorator, setFieldsValue} = form;

    const formItemVariables = {
            label:children,
            type,
            name,
            choices,
            unit,
            id,
            ...form
    };

    const ComponentType = {
        'T': {
            component: <InputTypeText
                getFieldDecorator={getFieldDecorator}
                textLength={textLength}
                initialValue={initialValue}
                formItemVariables={formItemVariables}
                id={id}/>
        },
        'L': {
            component: <InputTypeTextLong
                getFieldDecorator={getFieldDecorator}
                initialValue={initialValue}
                formItemVariables={formItemVariables}
                id={id}/>
        },
        'C': {
            component: <ChoicesSelect
                choices={choices}
                id={id}
                getFieldDecorator={getFieldDecorator}
                initialValue={initialValue}
                formItemVariables={formItemVariables}
            />
        },
        'N': {
            component: <InputNumberUnit
                unit={unit}
                id={id}
                getFieldDecorator={getFieldDecorator}
                initialValue={initialValue}
                formItemVariables={formItemVariables}
                decimalPlaces={decimalPlaces}
                setFieldsValue={setFieldsValue}
            />
        },
        'B': {
            component: <RadioButton
                id={id}
                getFieldDecorator={getFieldDecorator}
                initialValue={initialValue}
                formItemVariables={formItemVariables}
            />
        },
        'A': {
            component: <MultipleSelect
                choices={choices}
                id={id}
                getFieldDecorator={getFieldDecorator}
                initialValue={initialValue}
                formItemVariables={formItemVariables}
            />
        },
        'R': {
            component: <RangeInput
                unit={unit}
                id={id}
                getFieldDecorator={getFieldDecorator}
                initialValueFrom={initialValue ? initialValue.from : null}
                initialValueTo={initialValue? initialValue.to : null}
                formItemVariables={formItemVariables}
                decimalPlaces={decimalPlaces}
                setFieldsValue={setFieldsValue}
            />
        },
    };
    return (ComponentType[type].component)
};

ParameterType.propTypes = {
    name: PropTypes.string,
    choices: PropTypes.array,
    unit: PropTypes.string,
    internal: PropTypes.bool,
    id: PropTypes.string,
    itemType: PropTypes.string,
    type: PropTypes.oneOf([
        'N',
        'T',
        'L',
        'C',
        'B',
        'A',
        'R'
    ]),
};

export default ParameterType;