import gql from 'graphql-tag';

const CREATE_PRODUCT_KIT_MUTATION = gql`
mutation createProductKitMutation($input: CreateProductKitMutationInput!){
  createProductKitMutation(input:$input){
    id
    errors{
      field
      messages
    }
  }
}
`;

export default CREATE_PRODUCT_KIT_MUTATION;