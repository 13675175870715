export default {
  "add-price" : "Add price",
  "date-range" : "Dates range",
  "price" : "Price",
  "comment" : "Comment",
  "options" : "Options",
  "save": "Save",
  "cancel": "Cancel",
  "edit": "Edit",
  "delete": "Delete",
  "confirm-cancel": "Are You sure to cancel?",
  "no-price": "Product doesn't have a price?",
  "price-info": "In order to add new price, click 'Add price' button. Comment and ending date are not required",
  "start-date": "Start date",
  "end-date": "End date",
  "promo-price": "Promotional price",
  'no-price-comment': 'Comment in absence of price',
  'warning': 'Careful!',
  'prices-removal': 'After checking this field, all prices will be removed!',
  "dateValidToTitle" : "Validity date for brands",
  "chooseDate" : "Choose date from",
  'fieldWarning': "Date is required!",
  'dateSaveSuccess': 'Validity date added!',
  'dateSaveError': 'Error! Something wrong!'
}