import gql from 'graphql-tag';

const UPDATE_PROMOTION = gql`
mutation updatePromotionMutation($input: UpdatePromotionMutationInput!) {
  updatePromotionMutation(input: $input) {
    id
    brand
    category {
      id
      name
    }
    errors {
      messages
      field
    }
  }
}
`;

export default UPDATE_PROMOTION;