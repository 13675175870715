import React from 'react';
import {Cascader, Icon} from "antd";
import {withApollo} from "react-apollo";
import {withTranslation} from "react-i18next";
import createQuery from "../../gql/queries/createQuery";
import {StyledIconWrapper, StyledCategoryButton} from "../_styledComponents";


class ChooseCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      fakeLabel: true,
      isLeaf: this.props.isLeaf,
      value: this.props.value,
      validateStatus: this.props.validateStatus,
      fullPath: this.props.fullPath,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const {value, fullPath, validateStatus, isLeaf} = nextProps;
      return {
        value,
        fullPath,
        validateStatus,
        isLeaf,
      }
  }

  shouldComponentUpdate = (nextProps) => {
    const {validate, toggleValidating, brand, client} = nextProps;
    if (validate) {
      toggleValidating();
      this.validateInput()
    }
    if (this.props.brand !== brand){
      client.query({
        query: createQuery(),
        variables: {brand}
      }).then(res => {
        this.setState({options: this.getInitialCategories(res.data)});
      });

    }
    return true;
  };

  componentDidMount = () => {
    const {client} = this.props;
    client.query({
      query: createQuery(),
      variables: {brand: this.props.brand}
    }).then(res => {
      this.setState({options: this.getInitialCategories(res.data)});
    });
  };

  setWidth = (ul1, ul2) => {
     ul1.style.maxWidth = '50px';
     ul1.style.minWidth = '0';
     ul2.style.maxWidth = '50px';
     ul2.style.minWidth = '0';
  };

  resetWidth = (ul1, ul2, ul3) => {
      ul1.style.maxWidth = 'unset';
      ul1.style.minWidth = 'unset';
      ul2.style.maxWidth = 'unset';
      ul2.style.minWidth = 'unset';
      if (ul3) {
          ul3.style.maxWidth = 'unset';
          ul3.style.minWidth = 'unset';
      }
  };

  setWidthOnLongerPath = (ul1, ul2, ul3) => {
    ul1.style.maxWidth = '50px';
    ul1.style.minWidth = '0';
    ul2.style.maxWidth = '50px';
    ul2.style.minWidth = '0';
    ul3.style.maxWidth = '50px';
    ul3.style.minWidth = '0';
  };

  popupChange = () => {
    const popup = [...document.getElementsByClassName('cascader-popup')][0];
    if (popup) {
        const observer = new MutationObserver((mutations) => {
        const popupMenu = [...popup.getElementsByClassName('ant-cascader-menu')];
        const windowWidth = window.innerWidth;

        mutations.forEach((mutation) => {
          const popupMenuLength = mutation.target.childNodes[0].childNodes.length;
          if (popupMenuLength > 2 && popupMenuLength < 6) {
            if (popupMenu) {
                popup.offsetWidth > windowWidth - 300 ? this.setWidth(popupMenu[1], popupMenu[2])
                  : this.resetWidth(popupMenu[1], popupMenu[2], popupMenu[3])
              }
            } else if (popupMenuLength > 6) {
                this.setWidthOnLongerPath(popupMenu[1], popupMenu[2], popupMenu[3])
          }
        })
      });

       observer.observe(popup, {
         attributes: true,
         attributeFilter: ['style']
      });
    }
  };

  onChange = (value, selectedOptions) => {
    if (this.state.fakeLabel) this.setState({fakeLabel: false});
    const fullPath = selectedOptions.map(({label}) => label).join(' > ');
    const isLeaf = !!selectedOptions.length && selectedOptions[ selectedOptions.length - 1 ].isLeaf;
    const data = {
      value,
      fullPath,
      isLeaf,
      validateStatus: isLeaf ? 'success' : 'error',
      help: isLeaf ? '' : this.props.t('validationErr-category'),
    };
    this.props.updateCategoryData(data);
  };

  validateInput = () => {
      let status;
      if (this.state.isLeaf) {
        status = {
          help: '',
          validateStatus: 'success'
        }
      } else {
        status = {
          help: this.props.t('validationErr-category'),
          validateStatus: 'error'
        }
      }
      this.props.updateCategoryData(status);
  };

  getNewBranch = (data) => {
    if (!data.general) return null;
    return data.general.categories.edges.map(c => {
      return {
        value: c.node.id,
        label: c.node.name,
        isLeaf: !c.node.children.edges.length,
      }
    });
  };

  getInitialCategories = c => {
    if (!c.general) return null;
    const categories = c.general.categories.edges.map(e => e.node);
    const categoriesList = categories.map(p => p.children.edges.map(cn => {
      return {
        value: cn.node.id,
        label: cn.node.name,
        isLeaf: !cn.node.children.edges.length,
      }
    }));
    return categoriesList[ 0 ];
  };

  loadData = (selectedOptions) => {
    const targetOption = selectedOptions[ selectedOptions.length - 1 ];
    targetOption.loading = true;
    const {client} = this.props;
    client.query({
      query: createQuery(targetOption.value)
    }).then(res => {
      targetOption.loading = false;
      targetOption.children = this.getNewBranch(res.data);
      this.setState({
        options: [ ...this.state.options ],
      });
    })
  };


  generateLabel = () => {
    const { fullPath, fakeLabel } = this.state;
    if (!fullPath) return this.props.t('chooseCategory');
    const trimed = fullPath.replace('Oferta Vaillant >', '').replace('Oferta Saunier Duval >', '');
    return fakeLabel ? `${trimed} > ${this.props.name}` : trimed;
   };

  render() {
    const {value, options, fullPath, validateStatus} = this.state;
    return (
      <Cascader
        defaultValue={[]}
        popupClassName='cascader-popup'
        displayRender={label => label.join(' > ')}
        options={options}
        loadData={this.loadData}
        onChange={this.onChange}
        onPopupVisibleChange={this.popupChange}
        value={value && value}
        changeOnSelect
      >
        <StyledCategoryButton block isEmpty={!fullPath} error={validateStatus === 'error'}>
          <span>{this.generateLabel()}</span>
          {validateStatus && (
            <StyledIconWrapper status={validateStatus}>
              <Icon
                type={validateStatus === 'error' ? 'close-circle' : 'check-circle'}
                theme='filled'
              />
            </StyledIconWrapper>
          )}
        </StyledCategoryButton>
      </Cascader>
    );
  }
}

const Apollo = withApollo(ChooseCategory);
const Translated = withTranslation('addProduct')(Apollo);

export default Translated;