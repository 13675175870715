import {Card, Col, Divider, Dropdown, Icon, Row} from "antd";
import React from "react";

const FileCardComponent = ({menu, linkAction , name, text}) => {
    return <Col span={6}>
        <Card style={{'marginBottom': '24px', paddingBottom: '15px'}}>
            <Row type='flex'
                 justify='end'
                 style={{height: 5}}>
                {menu &&
                <Dropdown
                    overlay={menu}
                    trigger={['click']}>
                    <span className='ant-dropdown-link export-link'
                       href='#'>
                        <Icon
                            style={{'fontSize': '35px', marginRight: '10px'}}
                            type='ellipsis'/>
                    </span>
                </Dropdown>
                }
            </Row>
            <Row type='flex'
                 justify='center'
                 align='middle'
                 style={{height: 90}}>
                <Icon style={{'fontSize': '85px'}}
                      type='table'/>
            </Row>
            <Divider/>

            <div className='export-link'
                  onClick={linkAction}
                  style={{padding: '5px 10px', display: 'flex', flexWrap: 'wrap', overflowX: 'hidden'}}
            >
                <span style={{display: 'flex'}}>
                         <Icon style={{'fontSize': '25px', marginRight: '5px'}}
                      type='file-excel'/>
                    {name}</span>
            </div>

            <p style={{'fontSize': '11px', padding: '5px 10px'}}>
                {text}
            </p>

        </Card>
    </Col>
};

export default FileCardComponent