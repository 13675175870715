import React from 'react';
import Layout from "../../Layout/Layout";
import {StyledTitle} from '../../../components/_styledComponents'
import {withTranslation} from "react-i18next";
import AddPromotionForm from "../../../components/AddPromotionForm";

const AddPromotionView = ({t}) => {
    return <Layout>
        <StyledTitle>{t('addPromotionTitle')}</StyledTitle>
        <AddPromotionForm/>
    </Layout>
};

const AddPromotion = withTranslation('promotions')(AddPromotionView);
export default AddPromotion;