import React from 'react';
import Layout from "../../Layout/Layout";
import {StyledTitle} from '../../../components/_styledComponents'
import {withTranslation} from "react-i18next";
import PromotionsTable from "../../../components/PromotionsTable";
import {Query} from "react-apollo";
import GET_PROMOTIONS from "../../../gql/queries/GET_PROMOTIONS";

const PromotionsView = ({t}) => {
    return <Layout>
            <StyledTitle>{t('promotions')}</StyledTitle>
              <Query query={GET_PROMOTIONS}>
                    {({loading, error, data}) => {
                        if (!data.general) return null;

                        return (
                             <PromotionsTable data={data} loading={loading}/>
                            )
                    }}

            </Query>
        </Layout>
};

const Promotions = withTranslation('promotions')(PromotionsView);
export default Promotions;