import gql from 'graphql-tag';

const EMPLOYEES_TABLE_QUERY = gql`query EmployeesTable
      (
        $role: String,
        $pageSize: Int,
        $offset: Int,
        $sortColumn: [String],
        $search: String,
        $isActive: Boolean,
      ) {
      management {
        users(role_In: $role, first: $pageSize, offset: $offset, orderBy: $sortColumn, displayName_Icontains: $search, isActive: $isActive) {
          totalCount
          edges {
            node {
              id
              displayName
              firstName
              lastName
              vaillant
              saunierDuval
              email
              taxId
              phoneNumber
              companyName
              role
              roleDisplay
              lastActivity
              isActive
            }
          }
        }
      }
    }
    `;

export default EMPLOYEES_TABLE_QUERY;