export default {
    'dataExport':'Data export',
    'exportFile':'Export file',
    'yourTemplates':'Your templates',
    'createNewPrivateTemplate':'Create new private template',
    'publicTemplates':'Public templates',
    'createNewPublicTemplate':'Create new public template',
    'creatingTemplateTitle':'Create new private template',
    'duplicateTemplateTitle':'Duplicate export template',
    'editTemplateTitle':'Edit export template',
    'publicTemplateFooterMessage':
        'Public template can only have public parameters.',
    'exportTemplateSuccessMessage': 'Export template saved.',
    'exportTemplateErrorMessage': 'Error occurred.',
    'exportDescription1': 'Every file contains product name and catalogue number.\n' +
        '                        If you select parameter, that is not available for\n' +
        '                        products in a given category, field will be marked as\n' +
        '                        "Not applicable".',
    'exportDescription2': 'Remember that products parameters will appear in order of selection.',
    'cancel': 'Cancel',
    'save': 'Save template',
    'saveAndExport': 'Save and export template',
    'duplicate': 'Duplicate template',
    'duplicateAndExport': 'Duplicate and export template',
    'templateName': 'File name',
    'templateCategories': 'Product categories',
    'templateParameters': 'Export parameters',
    'export': 'Export',
    'exportAndSave': 'Export and save',
    'confirmExportPopupTitle': `Are you sure you want to export {{templateName}} template?`,
    'deleteTemplateSuccessMessage': 'Export template removed.',
    'confirmRemovePopupTitle': 'Do you want to remove {{templateName}} template?',
    'confirmExportPopupContent': 'You will receive an email with export file. It may take few minutes.',
    'confirmExportMessageContent': 'Please check your email in few minutes.',
    'confirmPrivacyChange': 'Are you sure you want to change privacy of {{templateName}} template?',
    'privacyChangeSuccess': 'Export template privacy changed',
    'privacyChangeButton': 'Change privacy',
    'changePrivacy': 'Change privacy of export template',
    'editTemplate': 'Edit template',
    'deleteTemplate': 'Remove template',
    'delete': 'Remove',
    'exportFileCardComponentText': 'Added on {{formatted_date}} by {{displayName}}.',
    'brand': 'Brand',
    'include-attachments': 'Include attachments',
    'attachment-types': 'Attachment types',
}