import React, {useState} from 'react';
import {Link} from "react-router-dom"
import {Button, Icon, Input, Select, Table, message, Popconfirm, Tooltip} from 'antd';
import {DivSpaceBetween, StyledSelect} from '../_styledComponents';
import GET_PROMOTIONS from "../../gql/queries/GET_PROMOTIONS";
import {Query, withApollo} from "react-apollo";
import DELETE_PROMOTION_MUTATION from "../../gql/mutations/DELETE_PROMOTION";

const PromotionsTable = ({client, t}) => {
    const [filters, setFilters] = useState({
        search: '',
        brand: '',
    });

  const getPromotions = (data) => {
         let name;
       if (data.general) {
         return data.general.promotions.edges.map(promotion => {
          promotion = promotion.node;
          promotion.category ?  name = promotion.category.name
              : promotion.product ? name = promotion.product.name
              : name = '-';
              return {
                  key: promotion.id,
                  title: promotion.title,
                  description: promotion.description,
                  expDateFrom: promotion.expDateFrom,
                  expDateTo: promotion.expDateTo == null ? '-' : promotion.expDateTo,
                  productCategory: name,
              }

          });
       }
    };

  const deletePromotion = (record) => {
      if (record) {
           client.mutate({
            mutation: DELETE_PROMOTION_MUTATION,
            refetchQueries: [
                {query: GET_PROMOTIONS,
                variables: {brand: '', search: ''}
                    }
                ],
            variables: {
                input: {
                    promotionIds: record,
                }
            }
        }).then((res) => {
            message.success('Promocja usunięta')


        }).catch(err => {
             message.error('coś nie tak');

        });
      }

  }


    const columns = [
        {
            title: 'Tytuł',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.length - b.title.length,
        },
        {
            title: 'Opis',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Obowiązuje od',
            dataIndex: 'expDateFrom',
            key: 'expDateFrom',
            sorter: (a, b) => new Date(a.expDateFrom) - new Date(b.expDateFrom),
        },
          {
            title: 'Obowiązuje do',
            dataIndex: 'expDateTo',
            key: 'expDateTo',
        },
          {
            title: 'Produkt/Kategoria',
            dataIndex: 'productCategory',
            key: 'productCategory',
        },

        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: '150px',
            render:(text, record, index) => (
                <>

                      <Popconfirm placement="topLeft" title={'Czy na pewno chcesz usunąć promocję?'}
                                  onConfirm={() => deletePromotion(record.key)}
                                  okText="Tak" cancelText="Nie">
                    <Tooltip title={'Usuń promocję'}>
                        <Button><Icon type={'delete'}/></Button>
                    </Tooltip>
                      </Popconfirm>
                    <Tooltip title={'Edytuj promocję'}>
                         <Link to={{
                            pathname:`/add-promotion/id=${record.key}`,
                            state: {
                            editPromotionId: record.key
                            },
                        }}
                              >
                       <Button><Icon type={'edit'}/></Button>
                    </Link>
                    </Tooltip>
                </>
            )
        },
    ];

    const changeBrandFilter = (value) => {
        setFilters((prevState => ({...prevState, brand : value})))
    }

    const  renderSearchInput = () => {
        return <Input
            placeholder={'wyszukaj promocję'}
            onChange={
                event => {
                    const value = event.target.value;
                        const searchTimeout = setTimeout(
                            () => setFilters(
                                (prevState) => ({...prevState, search : value}))
                            , 800
                        )
                    }
                }
            addonAfter={<Icon type="search" />}
            style={{width: 200}}
        />;
    };

   return (
        <>
            <DivSpaceBetween>
                <Link to={'/add-promotion'}>
                       <Button>+ dodaj promocję</Button>
                </Link>
                 <StyledSelect name='brand'
                               placeholder={'Wybierz markę'}
                               onChange={changeBrandFilter}
                               allowClear>
                    <Select.Option value='V'>Vaillant</Select.Option>
                    <Select.Option value='S'>Saunier Duval</Select.Option>
                </StyledSelect>
                {renderSearchInput()}
            </DivSpaceBetween>
             <Query query={GET_PROMOTIONS}
                variables={{
                    search: filters.search,
                    brand: filters.brand,
                }}
             >
                {({loading, error, data}) => {
                    if (!data.general) return null;
                    return (
                     <Table dataSource={getPromotions(data)}
                        loading={loading}
                        columns={columns}
                     />
                    );
                }}
            </Query>
        </>
   );
};

export default withApollo(PromotionsTable);