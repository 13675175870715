import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon, Input} from 'antd';
import {withRouter} from 'react-router-dom';
import ProductsTable from "../ProductsTable";
import {withTranslation} from 'react-i18next';
import Tabs from '../_styledComponents/Tabs';
import ProductFilterContext from "../../logic/context/productFilter/ProductFilterContext";

const {TabPane} = Tabs;

class ProductsTabs extends Component {
    static contextType = ProductFilterContext;


    constructor(props) {
        super(props);
        this.searchTimeout = null;
        this.state = {
            activeKey: this.props.navTab,
            inputValue: '',
            search: ''
        }
    }

    componentDidMount() {
        const {search, activeTab} = this.context.filtersContext;
        const state = {search, inputValue: search};
        if (activeTab) {
            state.activeKey = activeTab;
        } else {
            this.context.setFiltersContext({
                changes: [
                    {field: 'activeTab', value: this.state.activeKey}
                ]
            })
        }
        this.setState(state);
    }


    renderSearchInput = () => {
        return <Input
            placeholder={this.props.t('searchProduct')}
            allowClear={true}
            value={this.state.inputValue}
            onChange={
                event => {
                    const value = event.target.value;
                    this.setState({inputValue: value}, () => {
                        clearTimeout(this.searchTimeout);
                        this.searchTimeout = setTimeout(
                            () => {
                                this.setState({search: value});
                                this.context.setFiltersContext({
                                    changes: [{field: 'search', value}]
                                })
                            }
                            , 500
                        )
                    })
                }
            }
            addonAfter={<Icon type="search"/>}
            style={{width: 200}}
        />;
    };

    componentWillReceiveProps(newProps) {
        this.setState({activeKey: newProps.navTab});
    }

    updateTab = activeKey => {
        if (activeKey !== this.state.activeKey) {
            this.props.history.push({pathname: 'products', state: {tab: activeKey}})
        }
        this.context.setFiltersContext({clear: true});
        this.context.setFiltersContext({
            changes: [
                {field: 'activeTab', value: activeKey}
            ]
        });
        this.setState({activeKey})
    };

    renderTab = (key, status, search, name, activeKey) => {
        return <TabPane tab={name} key={key}>
            <ProductsTable status={status} search={search} activeKey={activeKey} tabKey={key}/>
        </TabPane>
    };

    render() {
        const {t} = this.props;
        const {search, activeKey} = this.state;
        return (
            <Tabs activeKey={activeKey} tabBarExtraContent={this.renderSearchInput()}
                  onTabClick={this.updateTab}>
                {this.renderTab("before",'D',search, t('before'), activeKey)}
                {this.renderTab("ready",'R',search, t('ready'), activeKey)}
                {this.renderTab("removed",'U',search, t('out'), activeKey)}
                {this.renderTab("on-market",'P',search, t('current'), activeKey)}
                {this.renderTab("hidden",'H',search, t('hide'), activeKey)}
                {this.renderTab("all",'',search, t('all'), activeKey)}
                {this.renderTab("archive",'A',search, t('archival'), activeKey)}
            </Tabs>
        );
    }
}

ProductsTabs.defaultProps = {
    navTab: 'on-market'
};

ProductsTabs.propTypes = {
    navTab: PropTypes.string,
};

export default withRouter(withTranslation('products')(ProductsTabs));