export default {
    "promotions": "Promotions",
    "chooseBrand": "Choose brand",
    "title": "Title",
    "description": "Description",
    "category": "Category",
    "product": "Product",
    "verify": "Verify",
    "clear": "Clear",
    "dateRange": "Date range",
    "banners" : "Banners",
    "bannerDesktop": "Add banner for desktop",
    "bannerTablet": "Add banner for tablet",
    "bannerMobile": "Add banner for mobile",
    "pdfFile": "PDF File",
    "addPdf": "Add PDF file",
    "prices": "Prices",
    "addPrices": "Add prices",
    "wrongSizeImg": "Invalid image size! valid dimensions: ",
    "fileSuccess": "Upload successfull",
    "fileToHeavy": "File is too heavy! make sure the file does not exceed 25MB",
    "required": "Field is required",
    "formError": "Form has errors!",
    "somethingsWrong": "Something went wrong!",
    "formSuccess": "Promotion added!",
    "addPromotion": "Add promotion",
    "fillForm": "Fill in the form to add promotion",
    "catalogNumber": "Add catalog number",
    "cantFindProduct": "Product doesn't exist",
    "maxSizeD": "Max dimensions of image: 920x540",
    "maxSizeT": "Max dimensions of image: 790x540",
    "maxSizeM": "Max dimensions of image: 660x540",
    "maxWeight": "Max weight of file is 25MB",
    "promotedSuccess": "Promoted products added!",
    "addPromoted": "add promoted products",
    "addProducts": "Save",
    "addPromotionTitle": "add promotion",
    "clickToFind": "Click to find product!",
    "productName": "Product name",
     "noProduct": "Product doesn't exist",
    "startDate": "Start date",
    "endDate" : "End date",
}