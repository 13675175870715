import gql from 'graphql-tag';

const DUPLICATE_PRODUCT = gql`
   mutation DuplicateProduct($input: DuplicateProductMutationInput!) {
     duplicateProductMutation(input:$input){
        id
        product {
          name
          mainCategory {
            name
          }
          id
          categoryFullPath
          catalogueNumber
          priceValue
          updatedAt
          status
          statusName
          brandDisplay
          attachments(type: "P", first: 1 ) {
              edges {
                node {
                  file
                }
              }
          }
          updatedBy {
            displayName
          }
       }
       errors {
         field
         messages
       }
     }
   }
`;

export default DUPLICATE_PRODUCT;