export default {
  "log-in": "Zaloguj się",
  "mail": "Email",
  "password": "Hasło",
  "pass-err": "Proszę wprowadzić hasło!",
  "mail-err": "Proszę wprowadzić email!",
  "log-in-err": "Proszę podać właściwe dane logowania!",
  "forgot": "Zapomniałem hasła",
  "smth": "Coś poszło nie tak...",
  'no-permissions': 'Niewystarczające uprawnienia.',
}