import React, {useEffect, useState} from 'react';
import PriceImportComponent from "../../../components/PriceImportComponent";


const PriceImport = ({location}) => {
    const [showImportModal,setShowImportModal] = useState(false);
    const [importType, setImportType] = useState('');

    useEffect(()=> {
        if(location.action && location.action === 'open-modal') {
            setShowImportModal(true);
            setImportType(location.state)
        } else {
            const importTypeFromUrl = location.pathname.substring(1);
            setImportType(importTypeFromUrl)
        }
    }, [location]);

    return <PriceImportComponent
            showImportModal={showImportModal}
            setShowImportModal={setShowImportModal}
            importType={importType}
        />
};

export default PriceImport;