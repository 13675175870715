import React, {Component} from 'react';
import PropTypes from 'prop-types';
import EXPORT_TEMPLATES_QUERY from "../../gql/queries/EXPORT_TEMPLATES_QUERY";
import {Button, Row, Menu,  Modal, message, Spin} from 'antd';
import client from "../../config/apollo/client";
import {Query} from "react-apollo";
import moment from 'moment';
import DELETE_EXPORT_TEMPLATE_MUTATION from "../../gql/mutations/EXPORT_TEMPLATES/DELETE_EXPORT_TEMPLATE_MUTATION";
import EXPORT_TEMPLATE_MUTATION
    from "../../gql/mutations/EXPORT_TEMPLATES/EXPORT_TEMPLATE_MUTATION";
import FileCardComponent from "../FileCardComponent/FileCardComponent";
import {withTranslation} from "react-i18next";
import styled from 'styled-components';
import Cookies from 'universal-cookie';

const StyledButton = styled(Button)`
  background: ${({theme}) => theme.color.green} !important;
  border-color: ${({theme}) => theme.color.green} !important;
  color: #fff !important;
  width: 7em;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12) !important;
`;

const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background: white;
  }
`;

message.config({maxCount: 1});

const cookies = new Cookies();

class DataExportCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            confirmVisible: false,
            template: undefined,
        };
    }

    sendFileRequest = (template) => {
        const {t} = this.props;
        this.setState({loading: true});
        fetch(process.env.REACT_APP_URL_EXPORT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${cookies.get('jwttoken')}`,
            },
            body: JSON.stringify({
                export_type: 'normal',
                export_template_id: template.id,
            })
        }).then((response) => {
            this.setState({
                loading: false,
                confirmVisible: false,
                template: undefined
            });
            if (!response.ok) throw Error(response.status);
            return true;
        }).then(res => {
          message.success(t('confirmExportMessageContent'))
        }).catch(err => {
            message.error('Error');
            return console.log(err);
        });
    };

    confirmModal = (template) => {
        const {t} = this.props;
        return (
            <Modal title={t('confirmExportPopupTitle', {templateName: template.name})}
                   visible={this.state.confirmVisible}
                   closable={false}
                   destroyOnClose={true}
                   footer={[
                       <Button key='cancel'
                               onClick={() => this.setState({confirmVisible: false})}
                       >
                           {t('cancel')}
                       </Button>,
                       this.state.loading ?
                           <StyledButton key='disabled-export'
                                         disabled
                           >
                               <StyledSpin size='small'/>
                           </StyledButton>
                               :
                           <StyledButton key='export'
                                         onClick={() => this.sendFileRequest(template)}
                           >
                               {t('export')}
                           </StyledButton>
                   ]}
            >
                {t('confirmExportPopupContent')}
            </Modal>
        );
    };

    execDeleteMutation = (template) => {
        client.mutate({
            mutation: DELETE_EXPORT_TEMPLATE_MUTATION,
            variables: {
                input: {
                    id: template.id
                }
            },
            update: (store) => {
                const data = store.readQuery({
                    query: EXPORT_TEMPLATES_QUERY,
                    variables: {
                        private: this.props.private_templates,
                    }
                });
                let templates = data.me.exportTemplates.edges;
                templates = templates.filter(edge => edge.node.id !== template.id);
                data.me.exportTemplates.edges = templates;
                store.writeQuery({
                    query: EXPORT_TEMPLATES_QUERY,
                    variables: {
                        private: this.props.private_templates,
                    },
                    data
                });
            }
        }).then(res => {
            if (res.data.deleteExportTemplateMutation.ok) {
                message.success(this.props.t('deleteTemplateSuccessMessage'));
                this.props.setModalAction('');
                this.props.setPrivateTemplate(template.private);
                this.props.setPrivateTemplate(!template.private);
            } else message.error('Error');
        });
    };

    confirmDeletePopup = (template) => {
        const {t} = this.props;
        let that = this;
        Modal.confirm({
            title: t('confirmRemovePopupTitle', {templateName: template.name}),
            okText: t('delete'),
            okType: 'danger',
            cancelText: t('cancel'),
            width: 500,
            onOk() {
                that.execDeleteMutation(template);
            },
        });
    };

    showEditModal = (template, action) => {
        this.props.setHandledTemplate(template);
        this.props.setPrivateTemplate(template.private);
        this.props.setModalAction(action);
        this.props.setShowModal(true);
    };

    execExportTemplateMutation = (template) => {
        const {t} = this.props;
        client.mutate({
            mutation: EXPORT_TEMPLATE_MUTATION,
            variables: {
                input: {
                    id: template.id,
                    name: template.name,
                    brand: template.brand,
                    private: !template.private,
                    categories: template.categories.edges.map(edge => edge.node.id),
                    parameters: template.parameters.edges.map(edge => edge.node.id),
                    attachments: template.attachmentTypes,
                }
            },
            update: (store, {data: {exportTemplateMutation}}) => {
                const privateData = store.readQuery({
                    query: EXPORT_TEMPLATES_QUERY,
                    variables: {
                        private: true,
                    }
                });
                const publicData = store.readQuery({
                    query: EXPORT_TEMPLATES_QUERY,
                    variables: {
                        private: false,
                    }
                });

                if (template.private) {
                    privateData.me.exportTemplates.totalCount -= 1;
                    publicData.me.exportTemplates.totalCount += 1;
                    privateData.me.exportTemplates.edges = privateData.me.exportTemplates.edges.filter(edge => edge.node.id !== template.id);
                    publicData.me.exportTemplates.edges.push({
                        node: exportTemplateMutation.template,
                        __typename: 'ExportTemplateModelTypeEdge',
                    });
                }
                else {
                    privateData.me.exportTemplates.totalCount += 1;
                    publicData.me.exportTemplates.totalCount -= 1;
                    publicData.me.exportTemplates.edges = publicData.me.exportTemplates.edges.filter(edge => edge.node.id !== template.id);
                    privateData.me.exportTemplates.edges.push({
                        node: exportTemplateMutation.template,
                        __typename: 'ExportTemplateModelTypeEdge',
                    });
                }

                store.writeQuery({
                    query: EXPORT_TEMPLATES_QUERY,
                    variables: {
                        private: true,
                    }, data: privateData
                });
                store.writeQuery({
                    query: EXPORT_TEMPLATES_QUERY,
                    variables: {
                        private: false,
                    }, data: publicData
                });
            }
        }).then(res => {
            if (res.data.exportTemplateMutation.ok) {
                message.success(t('privacyChangeSuccess'));
                this.props.setModalAction('');
                this.props.setPrivateTemplate(template.private);
                this.props.setPrivateTemplate(!template.private);
            } else message.error(t('exportTemplateErrorMessage'));
        });
    };

    showChangePrivacyModal = (template) => {
        let that = this;
        const {t} = this.props;
        Modal.confirm({
            title: t('confirmPrivacyChange', {templateName: template.name}),
            okText: t('privacyChangeButton'),
            cancelText: t('cancel'),
            width: 500,
            onOk() {
                that.execExportTemplateMutation(template);
            },
        });
    };

    menu = (template) => {
        const {t} = this.props;
        return <Menu>
            <Menu.Item key='0'>
                <div onClick={() => this.showEditModal(template, 'edit')}
                      className="export-link">{t('editTemplate')}</div>
            </Menu.Item>
            <Menu.Item key='1'>
                <div onClick={() => this.showChangePrivacyModal(template)}
                      className="export-link">{t('changePrivacy')}</div>
            </Menu.Item>
            <Menu.Item key='2'>
                <div onClick={() => {
                    let duplicatedTemplate = Object.assign({}, template);
                    duplicatedTemplate.id = undefined;
                    this.showEditModal(duplicatedTemplate, 'duplicate');
                }}
                      className="export-link">{t('duplicate')}</div>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key='3'>
                <div onClick={() => this.confirmDeletePopup(template)}
                      className="export-link">{t('deleteTemplate')}</div>
            </Menu.Item>
        </Menu>
    };

    render() {
        const {t} = this.props;
        return (
            <Query query={EXPORT_TEMPLATES_QUERY}
                   variables={{private: this.props.private_templates}}>
                {({loading, data, error}) => {
                    if (!loading && data) {
                        const templates = data.me.exportTemplates.edges;
                        return templates.map((edge,index) => {
                            const template = edge.node;
                            const formatted_date = moment(template.createdAt).format('DD-MM-YYYY');
                            return (
                                <>
                                    {(this.state.template === template) && this.confirmModal(template)}
                                    <FileCardComponent key={index}
                                                       name={template.name}
                                                       menu={() => this.menu(template)}
                                                       linkAction={() => this.setState({
                                                           confirmVisible: true,
                                                           template,
                                                       })}
                                                       text={t('exportFileCardComponentText', {
                                                           formatted_date,
                                                           displayName:template.owner.displayName
                                                       })}
                                    />
                                </>
                            );
                        });
                    }
                    else return(
                        <Row type='flex' justify='center'>
                            <Spin />
                        </Row>
                        );
                }}
            </Query>
        )
    }
}

DataExportCards.propTypes = {
    private_templates: PropTypes.bool.isRequired,
    setHandledTemplate: PropTypes.func.isRequired,
    setShowModal: PropTypes.func.isRequired,
    setModalAction: PropTypes.func.isRequired,
    setPrivateTemplate: PropTypes.func.isRequired,
};

export default withTranslation('dataExport')(DataExportCards);