import gql from 'graphql-tag';

const PRODUCT_IMPORT_HISTORY = gql`query productImportHistory {
  general {
    productImports (orderBy: "-created_at") {
      totalCount
      edges {
        node {
          id
          createdAt
          updatedAt
          file
          uploadedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
}
`;

export default PRODUCT_IMPORT_HISTORY;