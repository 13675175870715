import gql from "graphql-tag";

const SUBCATEGORY_QUERY = gql`
query getSubcategories($brand: String!, $parent: ID!) {
  general {
    categories(brand: $brand, parent: $parent) {
      edges {
        node {
          parent {
            name
            id
            pathToRoot {
              displayName
              name
              id
            }
         
          }
          id
          childrenWithProducts {
            totalCount
          }
          name
          image
          displayName
          pathToRoot {
            displayName
            name
            id
          }
          children {
            totalCount
            edges {
              node {
                name
                image
                id
              }
            }
          }
        }
      }
    }
  }
}


`;

export default SUBCATEGORY_QUERY;
