import styled from "styled-components";

const StyledHeader = styled.header`
  border-bottom: 1px solid ${({theme}) => theme.color.green};
  background-color: #fff;
  padding: 0 2vw;
  min-height: ${({theme}) => theme.layout.headerHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
`;

export default StyledHeader;