import styled from "styled-components";

const DivFlexEnd = styled.div`
    display: flex;
    justify-content: flex-end;
    
    button {
        margin-right: 20px;
    }
`;

export default DivFlexEnd;