import React from 'react';
import { StyledTitle } from "../_styledComponents";
import BrandPricesDate from "../BrandPricesDate";
import Query from "react-apollo/Query";
import GET_VALIDITY_DATE_BRAND from "../../gql/queries/GET_VALIDITY_DATE_BRAND";
import {Spin} from "antd";
import {withTranslation} from "react-i18next";


const GlobalPricesForm = ({t}) => {
    return (
        <>
            <StyledTitle>
                {t('dateValidToTitle')}
            </StyledTitle>
            <div style={{minHeight: 'calc(100vh - 250px)'}}>
                 <Query query={GET_VALIDITY_DATE_BRAND}>
                     {({data, loading}) => {
                        if (loading) return <div style={{height: '500px'}}><Spin/></div>
                        if (!data.general) return null;
                        const dates = data.general.priceValidFrom;
                        if (!dates.edges.length) {
                            return (
                                <>
                                    <BrandPricesDate brand={'V'} t={t} date={null}/>
                                    <BrandPricesDate brand={'S'} t={t} date={null}/>
                                </>
                            )
                        } else  return dates.edges.map((date, index) => {
                            return <BrandPricesDate
                                        t={t}
                                        brand={date.node.brand}
                                        key={index}
                                        date={date.node.validFrom}/>;
                        });
                    }}
                </Query>
            </div>
        </>
    )
}

export default withTranslation('prices')(GlobalPricesForm);