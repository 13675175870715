import { css } from 'styled-components';

const sizes = {
  laptop: 1419,
};

const mq = Object.keys(sizes).reduce((acc, label) => {
  acc[ label ] = (...args) => css`
    @media (max-width: ${sizes[ label ] / 16}em) {
      ${css(...args)}
    }
  `

  return acc
}, {});


export default {
    mq,
    color : {
        green : '#00917e',
        greenLight: '#00c4ae',
        white : '#fff',
        greenBg: 'rgba(0, 145, 126, 0.09)',
        gray: '#c0c0c0',
        antGreen: '#52c41a',
        antRed: '#f5222d',
    },
    layout : {
        containerWidth : '1500px',
        headerHeight: '90px',
        logoHeight: '45px',
        modalFullHeight: '80vh',
    },
    font : {
        s : '0.75rem',
        m : '1rem',
        l : '1.25rem',
        normal : '400',
        bold : '600',
        thin : '300'
    }
}