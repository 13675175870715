import gql from 'graphql-tag';

const ADD_BRAND_PRICE_DATE= gql`
mutation createUpdatePriceValidFrom($input: CreateUpdatePriceValidFromMutationInput!) {
  createUpdatePriceValidFromMutation(input: $input) {
    brand
    validFrom
    errors {
      field
      messages
    }
  }
}
`;

export default ADD_BRAND_PRICE_DATE;