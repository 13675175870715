import React from 'react';
import qs from 'qs';
import { withRouter, Redirect } from 'react-router-dom';

import Layout from "../../Layout/Layout";
import AddProductSteps from "../../../components/AddProductSteps";

const AddProductForm = ({match}) => {
  const query = qs.parse(match.params.query);
  const {step, id} = query;

  if(step === undefined) {
    return <Redirect to='/' />
  }
  return (
    <Layout>
      <AddProductSteps
        currentStep={parseInt(step, 10)}
        id={id}
      />
    </Layout>
  )
};

export default withRouter(AddProductForm);