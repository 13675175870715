import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Button, message} from 'antd';
import {withApollo} from "react-apollo";
import {withTranslation} from "react-i18next";
import {ErrorMsg, Icon} from "../_styledComponents";
import SEND_PASSWORD_RESET_MAIL from '../../gql/mutations/SEND_PASSWORD_RESET_MAIL';

const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[ field ]);
};

message.config({maxCount: 1});

const ForgotPasswordForm = ({form, client, redirect, t}) => {

  const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, validateFields} = form;
  const [ generalError, setGeneralErrorMsg ] = useState('');

  useEffect(() => {
    validateFields();
  }, []);

  const handleError = () => {
    message.error(t('send-mail-err'), 10);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, value) => {
      if (!err) {
        client.mutate({
          mutation: SEND_PASSWORD_RESET_MAIL,
          variables: {
            input: {
              userEmail: value.email
          }}
        }).then((res) => {
          if (res.data.sendPasswordResetMailMutation.ok) {
            message.success(t('email-sent'), 10);
            redirect(true);
          }
        }).catch((err) => setGeneralErrorMsg(t('basic-err')));
      } else {
        handleError();
      }
    });
  };

  const resetError = () => {
    setGeneralErrorMsg('');
  };

  const emailError = isFieldTouched('email') && getFieldError('email');

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item validateStatus={emailError ? 'error' : ''}
                 help={emailError || ''}
      >
        {getFieldDecorator('email', {
          rules: [ {required: true, type: 'email', message: t('mail-err')} ],
        })(<Input onChange={resetError}
                  prefix={<Icon type='user'/>}
                  placeholder={t('mail')}
           />
        )}
      </Form.Item>
      <Form.Item>
        <Button type='primary'
                htmlType='submit'
                style={{width: '100%'}}
                disabled={hasErrors(getFieldsError()) || generalError}
        >
          {t('send-mail')}
        </Button>
      </Form.Item>
      <ErrorMsg minHeight={25}>{generalError}</ErrorMsg>
    </Form>
  )
};

ForgotPasswordForm.propTypes = {
  form: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const AntResetPasswordForm = Form.create()(ForgotPasswordForm);
const TranslatedComponent = withTranslation('passwordReset')(AntResetPasswordForm);

export default withApollo(TranslatedComponent);