import gql from 'graphql-tag';

const DELETE_PROMOTION_MUTATION = gql`
	mutation deletePromotionMutation($input: DeletePromotionMutationInput!) {
    deletePromotionMutation(input: $input) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;

export default DELETE_PROMOTION_MUTATION;