import gql from "graphql-tag";

const CATEGORY_DEFAULT_IMAGE_QUERY = gql`
  query CategoryDefaultImage {
    general {
      categoryDefaultImage
    }
  }
`;

export default CATEGORY_DEFAULT_IMAGE_QUERY;
