import React from 'react';
import { Form, Input } from "antd";
import {withTranslation} from "react-i18next";

const DescriptionFields = ({ initialValueShort, initialValueLong, form, t}) => {

    const { getFieldDecorator } = form;

    return (
        <>
            <Form.Item {...form} label={'Opis towaru krótki'}>
                {getFieldDecorator('descriptionShort', {
                    rules: [{max: 250, message: t('validationErr-length')}],
                    initialValue: initialValueShort
                })(
                    <Input type='text'/>
                )}
            </Form.Item>
            <Form.Item {...form} label={'Opis towaru długi'}>
                {getFieldDecorator('descriptionLong', {
                    initialValue: initialValueLong
                })(
                    <Input.TextArea autosize={{ minRows: 3, maxRows: 10 }}
                    />
                )}


            </Form.Item>
        </>
    )
};

export default withTranslation('products')(DescriptionFields);
