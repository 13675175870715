import gql from 'graphql-tag';

const GET_FIELDS_VALUE = gql`
    query($id: ID) {
      general {
        products(id: $id, first: 1) {
          edges {
            node {
              id
              brand
              category {
                id
                name
              }
              model
              productGroup
              categoryFullPath
              name
              catalogueNumber
              ean
            }
          }
        }
      }
    }
`;

export default GET_FIELDS_VALUE;