import styled from "styled-components";
import {Button} from "antd";

const StyledCategoryButton = styled(Button)`
  position: relative;
  text-align: left !important;
  padding-left: 11px !important;
  padding-right: 25px !important;
  color: ${props => props.isEmpty && `${props.theme.color.gray} !important`};
  min-height: 32px;
  height: auto;
  white-space: normal;
  border-color: ${props => props.error === true && `${props.theme.color.antRed} !important`};
`;

export default StyledCategoryButton;