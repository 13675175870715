import styled from 'styled-components';

const StyledTitle = styled.h2`
  margin-bottom: 30px;
  text-transform: uppercase;
   ${({theme}) => theme.mq.laptop`
     display: none
  `
  }

`;

export default StyledTitle;
