import React, {useEffect, useState} from 'react';
import {Button} from 'antd';
import Layout from "../../Layout/Layout";
import EmployeesComponent from "../../../components/EmployeesComponent";
import EmployeeModal from "../../../components/EmployeeModal";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {StyledTitle} from '../../../components/_styledComponents'

const Employees = ({location, t}) => {
    const [showModal, setShowModal] = useState(false);
    const [modalProps, setModalProps] = useState({});

    useEffect(() => {
            if(location.action === 'add'){
                setShowModal(true);
            }
        }, [location]);

    return (
        <Layout>
          <StyledTitle>{t('employees')}</StyledTitle>
            <Button onClick={() => setShowModal(true)}>{t('addEmployee')}</Button>
            <EmployeesComponent setModalProps={(props)=>{setModalProps(props);setShowModal(true)}}/>
            <EmployeeModal showModal={showModal} setShowModal={setShowModal} modalProps={modalProps}/>
        </Layout>
    )
};

Employees.defaultProps = {
    location: {
        action: '',
    }
};

Employees.propTypes = {
    location: PropTypes.shape({
        action: PropTypes.string,
    })
};

export default withTranslation('products')(Employees);