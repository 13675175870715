import gql from 'graphql-tag';

const PRODUCT_CHECK_UNIQUE_PARAMETER_QUERY = (filter) => {
  return gql`
    query ($number: String!){
      general{
        products(${filter}: $number) {
          totalCount
        }
      }
    }
  `
};

export default PRODUCT_CHECK_UNIQUE_PARAMETER_QUERY;