import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from "../../../components/Header";
import SideNavigation from "../../Layout/SideNavigation";
import Footer from "../Footer";

const StyledContainer = styled.div`
  display: flex;
  min-height: calc( 100vh - ${({theme}) => theme.layout.headerHeight} );
  padding-top: 80px;
`;

const StyledMain = styled.main`
  flex-grow: 1;
  position: relative;
  padding: 1rem 1rem 2rem;
  max-width: ${({theme}) => theme.layout.containerWidth};
  margin: 1rem auto;
 ${({theme}) => theme.mq.laptop`
    margin: 5rem auto
    max-width: 95%
  `}
`;

const Layout = ({children}) => (
  <>
    <Header/>
    <StyledContainer>
      <SideNavigation/>
      <StyledMain>
        {children}
        <Footer/>
      </StyledMain>
    </StyledContainer>
  </>
);

Layout.defaultProps = {};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;