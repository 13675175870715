import gql from 'graphql-tag';

const ADD_USER = gql`
    mutation importPricesMutation($input: ImportPricesMutationInput!) {
      importPricesMutation(input: $input) {
        ok
        workbookErrors {
          row
          column
          messages
        }
        errors {
          field
          messages
        }
        priceImport {
          createdAt
          updatedAt
          id
          file
          uploadedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
`;

export default ADD_USER;