import gql from "graphql-tag";

const generateQuery = (filter) => {
  return gql`
    query ($number: String!){
      general{
        products(${filter}: $number) {
          totalCount
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `
};

export default generateQuery;
