import React, {useState, useEffect} from 'react';
import {Form, Row, Col, Input, Button, InputNumber, Icon, Tooltip} from "antd";
import {withApollo} from "react-apollo";
import GET_PRODUCT_BY_CATALOGUE_NUMBER from "../../../../../gql/queries/GET_PRODUCT_BY_CATALOGUE_NUMBER";
import {withTranslation} from "react-i18next";

const ButtonGroup = Button.Group;

const ProductKitElement = ({data, form, client, index = 0, addKitAfter, moveKitUp, updateElement, removeElement, t}) => {
    const [searchStatus, setSearchStatus] = useState(null);

    useEffect(() => {
        form.setFields({
            quantity: {value:data.quantity, errors: null},
            product_Name: {value:data.fromProduct.name, errors: null},
            product_CatalogueNumber: {value:data.fromProduct.catalogueNumber, errors: null},
        });
    }, [data]);

    const getProduct = () => {
        const catalogueNumber = form.getFieldValue('product_CatalogueNumber');
        if (catalogueNumber) {
            setSearchStatus('validating');
            client.query({
                query: GET_PRODUCT_BY_CATALOGUE_NUMBER,
                variables: {
                    catalogueNumber
                }
            }).then(({data}) => {
                const products = data.general.products.edges;
                if (products.length) {
                    const product = products[0].node;
                    const updatedElement = {fromProduct: {catalogueNumber, ...product},};
                    if (form.getFieldValue('quantity') === null){
                        updatedElement.quantity = 1;
                        form.setFields({quantity: {value:data.quantity, errors: null}})
                    }
                    updateElement(index, updatedElement);
                    setSearchStatus('success')
                } else {
                    setSearchStatus('error')
                }
            })
        }else  if (!catalogueNumber){
            setSearchStatus('warning')
        }
    };

    const {getFieldDecorator} = form;
    return (
        <Form>
            <Row type={'flex'}>
                <Col span={4} offset={2}>
                    <Form.Item hasFeedback help={searchStatus=== 'error' ? t('unknownNumber'): searchStatus === 'warning' && t('giveNumber')} validateStatus={searchStatus}>
                        {getFieldDecorator('product_CatalogueNumber', ({}))(
                            <Input placeholder={t('catalogueNumberPlaceholder')}
                                   onBlur={
                                       (event) => updateElement(
                                           index,
                                           {
                                               fromProduct: {
                                                   ...data.fromProduct,
                                                   catalogueNumber: event.target.value
                                               }
                                           }
                                       )
                                   }

                            />
                        )
                        }
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item>
                        <Button onClick={getProduct}>
                            <Icon type='search'/>
                            {t('verify')}
                        </Button>
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item>
                        {getFieldDecorator('product_Name', ({
                            rules: [{required: true, message: t('noProduct')}]
                        }))(
                            <Input disabled/>
                        )
                        }
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item>
                        {getFieldDecorator('quantity', ({
                            rules: [{required: true, message: t('noQuantity')}],
                            initialValue: 0
                        }))(
                            <InputNumber placeholder={t('quantityPlaceholder')}
                                         min={1}
                                onBlur={
                                    (event) => updateElement(
                                        index,
                                        {
                                            quantity: event.target.value
                                        }
                                    )
                                }/>
                        )
                        }
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item>
                        <ButtonGroup style={{width: '100%'}}>
                            <Tooltip title={t('removePosition')}>
                                <Button style={{width: '33%', color: '#f5222d'}}
                                        onClick={() => {removeElement(index); setSearchStatus(null)}}>
                                    <Icon type={'delete'}/>
                                </Button>
                            </Tooltip>
                            <Tooltip title={index && t('moveUp')}>
                                <Button
                                    style={{width: '33%', display: ""}}
                                    disabled={!index}
                                    onClick={() => moveKitUp(index)}>
                                    <Icon type={!index? 'minus' : 'up'}/>
                                </Button>
                            </Tooltip>
                            <Tooltip title={t('addUnder')}>
                                <Button style={{width: '33%' }} onClick={() => addKitAfter(index)}>
                                    <Icon type={'plus-circle'}/>
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )

};

const ProductKitElementForm = Form.create()(ProductKitElement);
const ProductKitElementApollo = withApollo(ProductKitElementForm);

export default withTranslation('products')(ProductKitElementApollo);