import React from 'react';
import {Form, DatePicker, Button, Input, Upload, Icon, Card, message, Select} from 'antd'
import locale from 'antd/es/date-picker/locale/pl_PL';
import { StyledFormContainer, StyledFormItem, DivFlexEnd, DivSpaceBetween } from "../_styledComponents";
import PromotionKit from "../PromotionKit";
import CREATE_PROMOTION from "../../gql/mutations/CREATE_PROMOTION";
import GET_PROMOTIONS from "../../gql/queries/GET_PROMOTIONS";
import MAIN_CATEGORY_QUERY from "../../gql/queries/MAIN_CATEGORY_QUERY";
import {Query, withApollo} from "react-apollo";
import moment from "moment";
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import UPDATE_PROMOTION from "../../gql/mutations/UPDATE_PROMOTION";

const { RangePicker } = DatePicker;
const {Item} = Form;


const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 4},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };

const defaultFormState = {
    category: {
        id: '',
        name: ''
    },
    product: {
        id: '',
        name: '',
        catalogueNumber: '',
    },
    brand: '',
}


message.config({maxCount: 1});

class AddPromotion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: this.props.location.state,
             files: {
                    imageDesktop: null,
                    imageTablet: null,
                    imageMobile: null,
                    fileProductData: null,
                    fileProductPrice: null,
                },
            ...defaultFormState,

        }
    }

    componentDidMount() {
        if (this.state.isEdit) {
          this.props.client.query({
            query: GET_PROMOTIONS,
            variables:
                {id: this.state.isEdit.editPromotionId},
        }).then(({data, loading, error}) => {
            if (!data) return null;
             const {
                   category,
                    product,
                    brand,
                    expDateFrom,
                    title,
                    expDateTo,
                    description,
                    imageDesktop,
                    imageTablet,
                    imageMobile,
                    fileProductData,
                    fileProductPrice,
                } = data.general.promotions.edges[0].node;

            const files = [
                {
                    name: 'imageDesktop',
                    value : imageDesktop
                },
                {
                    name: 'imageTablet',
                    value: imageTablet
                },
                {
                    name: 'imageMobile',
                    value: imageMobile
                },
                {
                    name: 'fileProductData',
                  value: fileProductData
                },
                {
                    name: 'fileProductPrice',
                    value: fileProductPrice
                },
                ]
             this.createFiles(files)
            this.setState({
                  brand,
                  title,
                  description,
                  product:  {
                    id: product ? product.id : '',
                    name: product ? product.name : '',
                    catalogueNumber: product ? product.catalogueNumber : '',
                  },
                  expDateFrom,
                  expDateTo,
                  category: {
                      id: category ? category.id : '',
                      name: category ? category.name : '',
                  },
              });

              this.props.form.setFieldsValue({
                  brand: brand,

              });
        });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.location.state !== nextProps.location.state) {

            this.setState({
                isEdit: nextProps.location.state,
                title: '',
                description: '',
                expDateFrom: null,
                expDateTo: null,
                category: {
                    id: '',
                    name: ''
                },
                product: {
                    id: '',
                    name: '',
                    catalogueNumber: '',
                },
                files: {
                    imageDesktop: null,
                    imageTablet: null,
                    imageMobile: null,
                    fileProductData: null,
                    fileProductPrice: null,
                },
                brand: '',
            });
        }
    }

    createFiles = (imgs) => {
        const files = {}
        imgs.forEach(({name, value}) => {
            if (!value) {
                   files[name] = null;

            } else {
                      const id = value.split('/')[2];
            const nameCut = value ? value.substring(value.lastIndexOf("/") + 1, value.indexOf("?") > 0 ? value.indexOf("?") : value.length) : '';
            const file = {
                    key: id,
                    uid: id,
                    id: id,
                    name: nameCut,
                    status: 'done',
                    isUpload: true,
             }
                files[name] = [file]
            }
        });
        this.setState({
                ...this.state,
                files
        });

    }

    updateCategoryData = (data) => {
        this.setState({
            category:
                {id: data === undefined ? '' : data}
        });
    };

    updateProductData = (data) => {
        this.setState({product: {
            id: data
        }} )
    };

    createVariablesBlock = (value, input) => {
        const category = this.state.category;
        const product = this.state.product;
        category.id !== "" ?   input.category = category.id :  input.product = product.id;
    };

    handleImagesField = (input) => {

        const valuesToCheck = [
            'imageDesktop',
            'imageTablet',
            'imageMobile',
            'fileProductData',
            'fileProductPrice'
        ]

      valuesToCheck.map(value =>  {
          const file = this.state.files[value];
          if (file !== null) {
              if (!file[0].isUpload || !file[0].isUpload  === undefined) {
                input[value] = this.state.files[value][0]
              }
          } else  {
              if (this.state.isEdit) input[value] = null;
          }
        });
    };

    beforeUpload = (file, fileList, size) => {
        if (file.type === 'image/png' || file.type === 'image/jpg') {
            const bannersDimensions = {
                imageMobile: {width: 660, height: 540},
                imageTablet: {width: 790, height: 540},
                imageDesktop: {width: 920, height: 540},
            };

            let img = new Image();
            img.src = window.URL.createObjectURL(file);

            const correctWidth = bannersDimensions[size].width;
            const correctHeight = bannersDimensions[size].height;

            img.onload = () => {
                const imgWidth = (img.width <= correctWidth) && (img.width > correctWidth / 2);
                const imgHeight = (img.height <= correctHeight) && (img.height > correctHeight / 2);
                if (imgWidth && imgHeight) {
                    message.success(this.props.t('fileSuccess'));
                    this.setState({
                        files : {
                            ...this.state.files,
                            [size]: [file],
                        }
                    });
                    return true;
                } else {
                    message.error( this.props.t('wrongSizeImg') + `${correctWidth} x ${correctHeight}`);
                      this.setState( {

                        files: {
                            ...this.state.files,
                            [size] : null
                        },
                    });
                }
            };
        } else {
            const binaryMb = 26214400;
            if (file.size >  binaryMb) {
                message.error(this.props.t('fileToHeavy'));
              this.setState({
                  files: {
                        ...this.state.files,
                      [size]: null
                  }
              });
            } else {
                message.success(this.props.t('fileSuccess'));
                this.setState({
                    files : {
                          ...this.state.files,
                        [size]: [file]
                    }

                });
            }
        }
    };

    customRequest = () => {
        return false;
    };

    setBrand = (value) => {
         this.props.form.setFieldsValue({
                  brand: value,
          });
       this.setState({
            ...this.state,
            brand: value,
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, value) => {
            if (!err) {
                const input = {
                    title: value.title,
                    description: value.description,
                    expDateFrom: moment(value.date[0]).format('YYYY-MM-DD'),
                    expDateTo: moment(value.date[1]).format('YYYY-MM-DD'),
                    brand: this.state.brand,
                };

                this.createVariablesBlock(value, input);
                this.handleImagesField(input);
                if (this.state.isEdit) input.id = this.state.isEdit.editPromotionId;
               this.props.client.mutate({
                    mutation: this.state.isEdit ? UPDATE_PROMOTION : CREATE_PROMOTION ,
                    refetchQueries: [{
                        query: GET_PROMOTIONS,
                        variables: {brand: '', search: ''}
                    }],
                    variables: {
                        input: input
                    }
                }).then((res) => {
                    message.success(this.props.t('formSuccess'));
                    setTimeout(() => this.props.history.push('/promotions'), 500);

                }).catch(err => {
                     message.error(this.props.t('somethingsWrong'));
                });
            } else {
                message.error(this.props.t('formError'));
            }
        });
    };

    clearCategory = () => {
        this.setState({category: {id: '', name: ''}});
    };

    removeFile = (file, size) => {
      this.setState({
          files: {
              ...this.state.files,
              [size] : null
          }

      });
    };

    render() {
        const {t, form} = this.props;
        const {getFieldDecorator } = form;
        const { category, title, product,description, files, expDateFrom, expDateTo,  brand} = this.state;

        return (
            <>
                <StyledFormContainer>
                    <Form  {...formItemLayout}
                    onSubmit={this.onSubmit}
                    hideRequiredMark
                    >
                        <Card title={t('fillForm')}>
                            <Item name='brand'
                                  label={t('chooseBrand')}
                            >
                                    {getFieldDecorator('brand', {
                                        rules: [ {
                                            required: true,
                                             message: t('required'),
                                        } ],
                                        initialValue: brand ? brand : undefined,
                                })(
                                    <Select name='brand'
                                        onChange={this.setBrand}
                                    >
                                        <Select.Option value='V'>Vaillant</Select.Option>
                                        <Select.Option value='S'>Saunier Duval</Select.Option>
                                    </Select>
                                 )}
                            </Item>
                            <Item
                                name="name"
                                label={t('title')}
                            >
                                {getFieldDecorator('title', {
                                    rules: [ {required: true, message: t('required') } ],
                                    initialValue: title
                                })(
                                    <Input placeholder={t('title')}/>
                                )}
                            </Item>
                            <Item
                                name="description"
                                label={t('description')}
                            >
                                {getFieldDecorator('description', {
                                    initialValue: description,
                                })(
                                    <Input placeholder={t('description')} />
                                )}
                            </Item>
                            <StyledFormItem
                                name='productCategory'
                                label={t('category')}
                                className='flex'
                            >
                                 <Query
                                    query={MAIN_CATEGORY_QUERY}
                                    variables={{
                                            isMain: true,
                                            brand: brand,
                                    }}
                                 >
                                {({data, loading, error}) => {
                                    if (!data.general ) return null;
                                      return  <Select
                                            allowClear={true}
                                            onChange={this.updateCategoryData}
                                            disabled={ product.id !== ''}
                                            onClear={this.clearCategory}
                                            defaultValue={category.name ? category.name : undefined}
                                            value={category.id}
                                        >
                                            {!loading && !error && data.general.categories.edges.map((obj, idx) => {
                                                return <Select.Option value={obj.node.id} key={idx}>
                                                        {obj.node.name}
                                                    </Select.Option>
                                            })}
                                        </Select>
                                        }
                                    }
                            </Query>
                            </StyledFormItem>
                                <Item
                                name='productCategory'
                                label={t('product')}
                            >
                             <PromotionKit
                                 form={form}
                                 updateProductData={this.updateProductData}
                                 t={t}
                                 category={category}
                                 product={product}
                                 promoted={false}
                             />
                                </Item>
                            <Item
                                name="dateRange"
                                label={t('dateRange')}
                            >
                                {getFieldDecorator('date', {
                                    rules: [ {required: true, message: t('required') } ],
                                    initialValue: [expDateFrom && moment(expDateFrom, 'YYYY-MM-DD'), expDateTo && moment(expDateTo, 'YYYY-MM-DD')]
                                })(
                                    <RangePicker
                                        placeholder={[t('startDate'), t('endDate')]}
                                        locale={locale}/>
                                )}
                            </Item>
                            <Item label={t('banners')}>
                                    <DivSpaceBetween className='row'>
                                 <Item
                                    name="imageDesktop"
                                >
                                    <Upload
                                         defaultFileList={files.imageDesktop}
                                         fileList={files.imageDesktop}
                                        accept='.png, jpg'
                                        listType="picture"
                                        customRequest={this.customRequest}
                                        beforeUpload={(file, list) => this.beforeUpload(file, list, 'imageDesktop')}
                                        onRemove={(file) => this.removeFile(file, 'imageDesktop')}
                                    >
                                        <Button
                                            disabled={files.imageDesktop && files.imageDesktop.length}
                                        ><Icon type={'upload'}/>{t('bannerDesktop')}</Button>
                                    </Upload>
                                     <span className={'upload-info'}>{t('maxSizeD')}</span>
                                </Item>
                                 <Item
                                    name="imageTablet"
                                >
                                    <Upload
                                        accept='.png, jpg'
                                        customRequest={this.customRequest}
                                        defaultFileList={files.imageTablet}
                                        fileList={files.imageTablet}
                                        listType="picture"
                                        beforeUpload={(file, list) => this.beforeUpload(file, list, 'imageTablet')}
                                        onRemove={(file) => this.removeFile(file, 'imageTablet')}
                                    >
                                        <Button
                                           disabled={files.imageTablet && files.imageTablet.length}
                                        ><Icon type={'upload'}/>{t('bannerTablet')}</Button>
                                    </Upload>
                                     <span>{t('maxSizeT')}</span>
                                </Item>
                                 <Item
                                    name="imageMobile"
                                >
                                    <Upload
                                        accept='.png,.jpg'
                                        customRequest={this.customRequest}
                                        defaultFileList={files.imageMobile}
                                        fileList={files.imageMobile}
                                        listType="picture"
                                         beforeUpload={(file, list) => this.beforeUpload(file, list, 'imageMobile')}
                                         onRemove={(file) => this.removeFile(file, 'imageMobile')}
                                    >
                                        <Button
                                           disabled={files.imageMobile && files.imageMobile.length}
                                        ><Icon type={'upload'}/>{t('bannerMobile')}</Button>
                                    </Upload>
                                     <span className={'upload-info'}>{t('maxSizeM')}</span>
                                </Item>
                            </DivSpaceBetween>

                            </Item>
                            <Item
                                name="fileProductData"
                                label={t('pdfFile')}
                            >
                                <Upload
                                    customRequest={this.customRequest}
                                    accept='.pdf'
                                    className='pdf-upload'
                                    defaultFileList={files.fileProductData}
                                    fileList={files.fileProductData}
                                     onRemove={(file) => this.removeFile(file, 'fileProductData')}
                                    beforeUpload={(file, list) => this.beforeUpload(file, list, 'fileProductData')}
                                >
                                    <Button
                                       disabled={files.fileProductData && files.fileProductData.length}
                                    ><Icon type={'upload'}/>{t('addPdf')}</Button>
                                </Upload>
                                <span className={'upload-info'}>{t('maxWeight')}</span>
                            </Item>
                            <Item
                                name="fileProductPrice"
                                label={t('prices')}
                            >
                                <Upload
                                    customRequest={this.customRequest}
                                    accept='.pdf'
                                    className='pdf-upload'
                                    defaultFileList={files.fileProductPrice}
                                    fileList={files.fileProductPrice}
                                    onRemove={(file) => this.removeFile(file, 'fileProductPrice')}
                                    beforeUpload={(file, list) => this.beforeUpload(file, list, 'fileProductPrice')}
                                >
                                    <Button
                                     disabled={files.fileProductPrice && files.fileProductPrice.length }
                                    ><Icon type={'upload'}/>{t('addPrices')}</Button>
                                </Upload>
                               <span className={'upload-info'}>{t('maxWeight')}</span>
                            </Item>
                            <Item className='no-label'>
                                <DivFlexEnd>
                                    <Button type="primary" htmlType="submit">{t('addPromotion')}</Button>
                                </DivFlexEnd>
                            </Item>
                        </Card>
                    </Form>
                </StyledFormContainer>
            </>
        );
    }
}
const AddPromotionTranslated = withTranslation('promotions')(AddPromotion)
const AddPromotionFormComponent = Form.create()(AddPromotionTranslated);
const AddPromotionForm = withRouter(AddPromotionFormComponent);
export default withApollo(AddPromotionForm);