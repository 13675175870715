import gql from 'graphql-tag';

const EXPORT_TEMPLATES_QUERY = gql`query ExportTemplates
    (
        $private: Boolean
    )
    {
        me{
            exportTemplates(private: $private){
                totalCount
                edges{
                    node{
                        id
                        name
                        brand
                        createdAt
                        owner{
                            displayName
                        }
                        private
                        parameters{
                            edges{
                                node{
                                    id
                                }
                            }
                        }
                        categories{
                            edges{
                                node{
                                    id
                                }
                            }
                        }
                        attachmentTypes
                    }
                }
            }
        }
    }
`;

export default EXPORT_TEMPLATES_QUERY;