import gql from 'graphql-tag';

const PASSWORD_RESET_TOKEN_VALID_QUERY = gql`query TokenValid
        (
            $token: String!
        ) {
        general {
            valid(token: $token)
        }
    }
`;

export default PASSWORD_RESET_TOKEN_VALID_QUERY;