import gql from "graphql-tag";

const IMPORT_DEFAULT_CATEGORY_IMAGE_MUTATION = gql`
  mutation importDefaultCategoryImageMutation(
    $input: ImportDefaultCategoryImageMutationInput!
  ) {
    importDefaultCategoryImageMutation(input: $input) {
      ok
      clientMutationId
    }
  }
`;

export default IMPORT_DEFAULT_CATEGORY_IMAGE_MUTATION;
