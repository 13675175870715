export default {
  "add-price" : "Dodaj cenę",
  "date-range" : "Zakres dat obowiązywania cen",
  "price" : "Cena",
  "comment" : "Komentarz",
  "options" : "Opcje",
  "save": "Zapisz",
  "cancel": "Anuluj",
  "edit": "Edytuj",
  "delete": "Usuń",
  "confirm-cancel": "Czy na pewno chcesz anulować?",
  "no-price": "Produkt nie posiada ceny?",
  "price-info": "Aby rozpocząć dodawanie ceny, kliknij \"Dodaj cenę\". Data końcowa oraz komentarz nie są wymagane.",
  "start-date": "Data początkowa",
  "end-date": "Data kończąca",
  "promo-price": "Cena promocyjna",
  'no-price-comment': 'Komentarz w przypadku braku ceny',
  'warning': 'Ostrożnie!',
  'prices-removal': 'Zaznaczenie tego pola spowoduje usunięcie wszystkich cen!',
  "dateValidToTitle" : "daty ważności cen dla marek",
  "chooseDate" : "Wybierz datę rozpoczęcia ważności cen",
  'fieldWarning': "Data jest wymagana!",
  'dateSaveSuccess': 'Data ważności dodana!',
  'dateSaveError': 'Błąd! Coś poszło nie tak!'
}