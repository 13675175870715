import React from 'react';
import {Menu, Dropdown, Button} from 'antd';
import i18n  from '../../i18n';
import { withTranslation } from 'react-i18next';


  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };


const ChangeLanguageBtnTranslated = ({t}) => {
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => changeLanguage('pl')}>
        {t('polish')}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => changeLanguage('en')}>
         {t('english')}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement='topLeft'>
        <Button
          style={{
          width: '140px',
          marginLeft: '20px',
        }}>{t('chooseLanguage')}</Button>
    </Dropdown>
  )
};

const ChangeLanguageButton = withTranslation('translationButton')(ChangeLanguageBtnTranslated);
export default ChangeLanguageButton;