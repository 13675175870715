import gql from 'graphql-tag';

const UPDATE_PRICE_MUTATION = gql`
	mutation updatePriceMutation($input: UpdatePriceMutationInput!) {
    updatePriceMutation(input: $input) {
      id
      product {
        id
        priceValue
      }
      errors {
        field
        messages
      }
    }
  }
`;

export default UPDATE_PRICE_MUTATION;