import React, {useState} from 'react';
import {Button, Modal, Table} from "antd";
import {Query} from "react-apollo";
import EMPLOYEE_ACTIVITIES_QUERY from "../../gql/queries/EMPLOYEE_ACTIVITIES_QUERY";
import moment from "moment";
import styled from 'styled-components';
import {withTranslation} from "react-i18next";

const TableContainer = styled.div`
  .ant-table-header{
    overflow: auto !important
  }
  .ant-table-body {
    overflow: auto !important
  }
`;



const EmployeeActivitiesModal = ({showModal, setShowModal,userId,t}) => {
    let totalRecords = 0;
    const pageSize = 15;
    const [currentPage, updateCurrentPage] = useState(1);
   // const [sortColumn, updateSortColumn] = useState([]);
    let offset = (currentPage - 1) * pageSize;

    const columns = [
        {
            title: t('dateLabel'),
            dataIndex: 'createdAt',
            key: 'createdAt',
        }
    ];

    const getActivities = user => {
        if (!user) return null;
        const activities = user.activitylogSet.edges.map(e => e.node);
        return activities.map(a => {
            return {
                key: a.id,
                createdAt: moment(a.createdAt).format('YYYY-MM-DD HH:mm'),
            };
        });
    };

    return <Query query={EMPLOYEE_ACTIVITIES_QUERY}
                  variables={{pageSize,userId:userId, offset:offset, sortColumn:['-created_at']}}>
        {({loading, error, data}) => {
            let user = null;
            if (!loading && data.management && userId) {
                user = data.management.users.edges[0].node;
            }
            if (user){
                totalRecords = user.activitylogSet.totalCount;
            }
            return <Modal visible={showModal}
                          title={t('activityHistory')}
                          footer={<Button onClick={()=>setShowModal(false)}>{t('close')}</Button>}
                          onCancel={() => setShowModal(false)}>
                <TableContainer>
                <Table

                    columns={columns}
                    loading={loading}
                    scroll={{y:500}}
                    pagination={
                        {
                            pageSize,
                            total: totalRecords,
                            current: currentPage,
                            onChange: page => updateCurrentPage(page)
                        }
                    }
                    dataSource={getActivities(user)}/>
                </TableContainer>
            </Modal>
        }}
    </Query>
};


export default withTranslation('employees')(EmployeeActivitiesModal);