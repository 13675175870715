import styled from "styled-components";

import { Link } from "react-router-dom";

export const StyledBreadcrumbWrapper = styled.div`
  min-height: 40px;

  a {
    color: rgba(0, 0, 0, 0.65);
  }
  a:hover {
    color: ${({ theme }) => theme.color.green};
  }
`;

export const StyledBreadcrumb = styled.div`
  display: flex;
`;

export const StyledPrimaryLink = styled(Link)`
  margin-right: 5px;
`;

export const StyledSecondaryLink = styled(Link)`
  margin-left: 5px;
`;
