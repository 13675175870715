import React from 'react';
import {Form, Input} from "antd";

const InputNumberUnit = ({unit, getFieldDecorator, id, initialValue, formItemVariables, setFieldsValue, decimalPlaces}) => {
    const decimalInverted = Math.pow(10, decimalPlaces);

    const normalize = (newValue) => {

        if (newValue) {
            newValue = parseFloat(newValue);
            if (decimalPlaces) {
                newValue = (Math.floor(Math.floor(newValue * decimalInverted)) / decimalInverted)
            } else {
                newValue = Math.floor(newValue)
            }
            newValue = newValue.toFixed(decimalPlaces)
        }
        setFieldsValue({[id]: newValue});
    };
    return (
        <Form.Item {...formItemVariables}>
            {getFieldDecorator(id, {
                initialValue,
                validateTrigger: 'onBlur',
                rules: [
                    {transform: normalize}
                ]
            })(
                <Input type={'number'}
                       addonAfter={unit}
                       step={1 / decimalInverted}
                />
            )}
        </Form.Item>
    );
};

export default InputNumberUnit;

