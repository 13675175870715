import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import Layout from "../../structure/Layout/Layout";
import {Button, Divider, Row, Col} from 'antd';
import {DataExportModal} from "../DataExportModal";
import DataExportCards from "../DataExportCards";
import {withTranslation} from "react-i18next";
import {StyledTitle} from "../_styledComponents";

const DataExportComponent = ({action, setAction, t}) => {

    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [privateTemplate, setPrivateTemplate] = useState(true);
    const [handledTemplate, setHandledTemplate] = useState(undefined);

    useEffect(() => {
        if (action === 'instant' || action === 'create') {
            setModalAction(action);
            setShowModal(true);
            setAction('');
        }
    });

    const toggleModal = (actionString) => {
        setModalAction(actionString);
        setShowModal(true);
    };

    return (
        <Layout>
            <DataExportModal template={handledTemplate}
                             setTemplate={setHandledTemplate}
                             action={modalAction}
                             setAction={setModalAction}
                             privateTemplate={privateTemplate}
                             showModal={showModal}
                             setShowModal={setShowModal}
            />
            <Row>
                <Col md={19}>
                   <StyledTitle>
                        {t('dataExport')}
                   </StyledTitle>
                </Col>
                <Col md={5}>
                    <Button onClick={() => {
                        setPrivateTemplate(true);
                        toggleModal('instant');
                    }} block>
                        {t('exportFile')}
                    </Button>
                </Col>
            </Row>
            <Divider/>
            <Row type='flex' align='middle' style={{'paddingTop': '15px'}}>
                <Col md={19}>
                    <span style={{'fontSize': '16px'}}>
                        {t('yourTemplates')}
                    </span>
                </Col>
                <Col md={5}>
                    <Button onClick={() => {
                        setPrivateTemplate(true);
                        toggleModal('create');
                    }} block>
                        {t('createNewPrivateTemplate')}
                    </Button>
                </Col>
            </Row>
            <Divider/>
            <Row gutter={24}>
                <DataExportCards private_templates={true}
                                 setHandledTemplate={setHandledTemplate}
                                 setShowModal={setShowModal}
                                 setModalAction={setModalAction}
                                 setPrivateTemplate={setPrivateTemplate}
                />
            </Row>
            <Row type='flex' align='middle' style={{'paddingTop': '10px'}}>
                <Col md={19}>
                    <span style={{'fontSize': '16px'}}>{t('publicTemplates')}</span>
                </Col>
                <Col md={5}>
                    <Button onClick={() => {
                        setPrivateTemplate(false);
                        toggleModal('create');
                    }} block>
                        {t('createNewPublicTemplate')}
                    </Button>
                </Col>
            </Row>
            <Divider/>
            <Row gutter={24}>
                <DataExportCards private_templates={false}
                                 setHandledTemplate={setHandledTemplate}
                                 setShowModal={setShowModal}
                                 setModalAction={setModalAction}
                                 setPrivateTemplate={setPrivateTemplate}
                />
            </Row>
        </Layout>
    )
};
DataExportComponent.defaultProps = {};
DataExportComponent.propTypes = {
    action: PropTypes.string.isRequired,
    setAction: PropTypes.func.isRequired,
};

export default withTranslation('dataExport')(DataExportComponent);