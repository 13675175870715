import React from 'react';
import {ApolloProvider, Query} from 'react-apollo';
import {ThemeProvider} from "styled-components";
import theme from './config/styles/theme';
import client from './config/apollo/client';
import Router from './structure/Router';
import "./i18n";
import './config/styles/stylesoverride.css';
import GENERAL_USER_DATA from "./gql/queries/GENERAL_USER_DATA";
import {UserProvider} from "./logic/context/UserContext";
import ProductFilterProvider from "./logic/context/productFilter/ProductFilterProvider";


const App = () => {
    return (
        <ApolloProvider client={client}>
            <Query query={GENERAL_USER_DATA} fetchPolicy={'network-only'}>
                {({data, loading, error}) => {
                    const userData = (loading || error || !data.me) ? null : data.me.user;
                    return (
                        <ThemeProvider theme={theme}>
                            <UserProvider value={userData}>
                                <ProductFilterProvider>
                                    <Router/>
                                </ProductFilterProvider>
                            </UserProvider>
                        </ThemeProvider>
                    )
                }}
            </Query>
        </ApolloProvider>
    )
};


export default App;
