import gql from 'graphql-tag';

const UPDATE_PRODUCT_KIT_MUTATION = gql`
mutation updateProductKitMutation($input: UpdateProductKitMutationInput!){
  updateProductKitMutation(input:$input){
    errors{
      field
      messages
    }
  }
}
`;

export default UPDATE_PRODUCT_KIT_MUTATION;