import React from 'react';
import {Form, Input} from 'antd';
import {withTranslation} from "react-i18next";


const RangeInputParams = ({unit, id, initialValueFrom, initialValueTo, getFieldDecorator, t, formItemVariables, decimalPlaces, setFieldsValue}) => {
    const decimalInverted = Math.pow(10, decimalPlaces);
    const idFrom = `${id}-from`;
    const idTo = `${id}-to`;

    const normalize = (newValue, valueId) => {

        if (newValue) {
            newValue = parseFloat(newValue);
            if (decimalPlaces) {
                newValue = (Math.floor(Math.floor(newValue * decimalInverted)) / decimalInverted)
            } else {
                newValue = Math.floor(newValue)
            }
            newValue = newValue.toFixed(decimalPlaces)
        }
        setFieldsValue({[valueId]: newValue});
    };

    return (
        <Form.Item {...formItemVariables} help={null} required={false} validateStatus={null}>
            <Input.Group style={{width: '100%'}} compact>
                {getFieldDecorator(idFrom, {
                    initialValue: initialValueFrom,
                    validateTrigger: 'onBlur',
                    rules: [
                        {transform: (value) => normalize(value, idFrom)}
                    ]
                })(
                    <Input
                        type='number'
                        style={{width: '50%', textAlign: 'center'}}
                        placeholder={t('from')}
                        step={1 / decimalInverted}
                        addonAfter={'-'}
                    />
                )}
                {getFieldDecorator(idTo, {
                    initialValue: initialValueTo,
                    validateTrigger: 'onBlur',
                    rules: [
                        {transform: (value)=>normalize(value, idTo)}
                    ]
                })(
                    <Input
                        style={{width: '50%', textAlign: 'center'}}
                        className={'ant-input-group-wrapper'}
                        placeholder={t('to')}
                        addonAfter={unit}
                        step={1 / decimalInverted}
                        type='number'
                    />
                )}
            </Input.Group>
        </Form.Item>
    )
};

const RangeInput = withTranslation('products')(RangeInputParams);
export default RangeInput;